import styled from 'styled-components';

import ActionButton from 'components/action-button';

export const StyledLoyaltyButton = styled(ActionButton)<{ reversed: boolean }>`
  width: 100%;
  color: ${p => (p.reversed ? p.theme.token('text-reversed') : Styles.color.contrastBackground)};
  border-color: ${p =>
    p.reversed ? p.theme.token('icon-reversed') : Styles.color.contrastBackground};
  background: ${p => p.reversed && 'transparent'};
  padding: 0.375rem 0.725rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
