import React, { forwardRef, useRef, useState } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';

import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { useAuthContext } from 'state/auth';
import { useReversedUIContext } from 'state/reversed-ui';

import ListOfLinks from '../dropdown/list-of-links';

import { IconContainer, SideNavContainer, StyledAccount } from './side-navigation.styled';
import { IActionBtn, ISideNav, ISideNavWithClickOutsideListener } from './types';

const SideNavWithClickOutsideListener = ({
  entries,
  visible,
  onDismiss,
  buttonRef,
}: ISideNavWithClickOutsideListener) => {
  const sideNavContainerRef = useRef<HTMLDivElement>(null);

  const { isAuthenticated } = useAuthContext();

  useOnClickOutside(sideNavContainerRef, onDismiss, buttonRef);

  return (
    <SideNavContainer
      data-testid="side-navigation-container"
      visible={visible}
      aria-expanded={visible}
      ref={sideNavContainerRef}
    >
      {isAuthenticated() && <StyledAccount />}
      {entries && <ListOfLinks navigate={() => undefined} title={''} entries={entries} />}
    </SideNavContainer>
  );
};

const ActionBtn = forwardRef<HTMLButtonElement, IActionBtn>(
  ({ visible, iconColor, onClick }, ref) => {
    const { formatMessage } = useIntl();

    return (
      <IconContainer
        aria-label={formatMessage({ id: 'toggleMenu' })}
        data-testid="side-nav-action-btn"
        onClick={onClick}
        ref={ref}
      >
        <Icon icon={visible ? 'close' : 'menu'} color={iconColor} width="1.5rem" aria-hidden />
      </IconContainer>
    );
  }
);

const SideNav = ({ entries }: ISideNav) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { reversedUI } = useReversedUIContext();
  const iconColor = reversedUI ? 'icon-reversed' : 'icon-header-contrast';
  const buttonRef = useRef<HTMLButtonElement>(null);

  const revealSideNav = () => {
    setVisible(!visible);
  };

  return (
    <>
      <ActionBtn visible={visible} onClick={revealSideNav} iconColor={iconColor} ref={buttonRef} />
      {ReactDOM.createPortal(
        <SideNavWithClickOutsideListener
          entries={entries}
          visible={visible}
          onDismiss={() => {
            setVisible(false);
          }}
          buttonRef={buttonRef}
        />,
        document.body
      )}
    </>
  );
};

export default SideNav;
