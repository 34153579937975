import { useCallback, useEffect, useState } from 'react';

import { useBranchContext } from 'state/branch';
import { useCdpContext } from 'state/cdp';
import * as Braze from 'utils/braze';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import useCookiebotSDK from './use-cookiebot-sdk';
import {
  ConsentGroups,
  addEventListener,
  handleBranchConsent,
  handleMparticleConsent,
  isBranchUnavailable,
  removeEventListeners,
} from './utils';

export const CookiebotBanner = () => {
  const { init } = useCdpContext();
  const { initBranch } = useBranchContext();
  const { loaded: otSdkLoaded } = useCookiebotSDK();

  const [librariesLoaded, setLibrariesLoaded] = useState(false);
  const [consentGroups, setConsentGroups] = useState({} as ConsentGroups);
  const [consentGroupsSetInLocalStorage, setConsentGroupsSetInLocalStorage] = useState(false);
  const branchUnavailable = isBranchUnavailable();

  // Function to initialize 3rd party services
  const initServicesWithCookies = useCallback(() => {
    if (!window.mParticle) {
      init();
    }
    if (!window.branch && !branchUnavailable) {
      initBranch();
    }
  }, [initBranch, init, branchUnavailable]);

  const setCookiebotConsent = () => {
    const consent = window.Cookiebot?.consent;
    setConsentGroups(consent);
    handleMparticleConsent(consent);
    handleBranchConsent(consent, branchUnavailable);
    LocalStorage.setItem(StorageKeys.COOKIEBOT_COOKIE_CONSENT_GROUPS, consent);
  };

  // loads 3rd party services once OneTrust sdk is loaded
  useEffect(() => {
    if (consentGroups.stamp !== '0' && !librariesLoaded && consentGroups.statistics) {
      initServicesWithCookies();
    }
  }, [initServicesWithCookies, consentGroups, librariesLoaded]);

  // Sets a interval to check if all 3rd party services are initialized
  useEffect(() => {
    const id = setInterval(() => {
      const branchValidation = branchUnavailable || !!window.branch;
      const areServicesInitialized =
        (Boolean(window.exponea) || Boolean(window.mParticle)) &&
        branchValidation &&
        Boolean(Braze.getSdk());
      if (areServicesInitialized) {
        setLibrariesLoaded(true);
      }
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, [librariesLoaded, branchUnavailable]);

  // Registers cookiebot consent change handler
  useEffect(() => {
    const id = setInterval(() => {
      if (window.Cookiebot) {
        if (!consentGroupsSetInLocalStorage) {
          LocalStorage.setItem(
            StorageKeys.COOKIEBOT_COOKIE_CONSENT_GROUPS,
            window.Cookiebot?.consent
          );
          setConsentGroupsSetInLocalStorage(true);
        }
        setConsentGroups(window.Cookiebot?.consent);
        addEventListener(setCookiebotConsent);
      }
    }, 1000);
    if (consentGroupsSetInLocalStorage) {
      clearInterval(id);
    }
    return () => {
      removeEventListeners(setCookiebotConsent);
      clearInterval(id);
    };
  }, [otSdkLoaded, consentGroupsSetInLocalStorage, consentGroups, setCookiebotConsent]);

  // Pull the consent groups out of local storage if it's already populated.
  // This will trigger the hook below, and disable/enable any global services based on
  // the previously chosen settings.
  useEffect(() => {
    const existingConsentGroups = LocalStorage.getItem(StorageKeys.COOKIEBOT_COOKIE_CONSENT_GROUPS);
    if (existingConsentGroups) {
      setConsentGroups(existingConsentGroups);
    }
  }, []);

  // Switches off tracking for each service if data tracking is opted out
  useEffect(() => {
    // if consent state is opted out for respective service, data tracking is opted out
    if (librariesLoaded) {
      handleMparticleConsent(consentGroups);
      handleBranchConsent(consentGroups, branchUnavailable);
    }
  }, [consentGroups, librariesLoaded, branchUnavailable]);

  return null;
};
