import { CurrencyCode, IsoCountryCode2, getCurrencyWithISO, getIso2 } from '@rbilabs/intl';

import { FALLBACK_ISO2 } from 'utils/constants';
import { getCountry } from 'utils/environment';

import COUNTRIES_WITH_FLAGS_AND_CALL_PREFIX from './countries-with-flags-and-call-prefix.json';
import {
  DEFAULT_PAYMENT_COUNTRY,
  DEFAULT_PAYMENT_CURRENCY,
  DEFAULT_PAYMENT_CURRENCY_NUMBER,
} from './default-codes/default-payment-codes';
import { ISOs } from './generated/constants';
import { CountryPhoneNumberConfig } from './types';

export * from './generated/constants';

export {
  DEFAULT_PAYMENT_COUNTRY,
  DEFAULT_PAYMENT_CURRENCY,
} from './default-codes/default-payment-codes';

export const PHONE_NUMBER_REGEX = /^\+?[0-9-()]*$/;

export const getCountryAndCurrencyCodes = (
  billingCountry: ISOs
): { countryCode: IsoCountryCode2; currencyCode: CurrencyCode; currencyNumber: number } => {
  const defaultCodes = {
    countryCode: DEFAULT_PAYMENT_COUNTRY,
    currencyCode: DEFAULT_PAYMENT_CURRENCY,
    currencyNumber: DEFAULT_PAYMENT_CURRENCY_NUMBER,
  };

  let iso2: IsoCountryCode2 = getCountry().toUpperCase();
  try {
    iso2 = getIso2({ iso3: billingCountry }) ?? FALLBACK_ISO2;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot determine country: ', e);
    return defaultCodes;
  }

  const currency = getCurrencyWithISO(iso2);
  if (!currency) {
    return defaultCodes;
  }

  return {
    countryCode: iso2,
    currencyCode: currency.code,
    currencyNumber: currency.number,
  };
};

export const defaultLocaleKeys = {
  postalCode: 'postalCode',
  region: 'province',
};

export const postalCodeStringLocaleKey = {
  [ISOs.USA]: 'zipCode',
};

export const regionStringLocaleKey = {
  [ISOs.USA]: 'state',
  [ISOs.CHE]: 'canton',
};

export const defaultInputProperties = {
  postalCode: { maxLength: '5', pattern: '[0-9]*' },
  region: { maxLength: '2' },
};

export const postalCodeInputProperties = {
  [ISOs.CAN]: { maxLength: '7' },
  [ISOs.GBR]: { maxLength: '10' },
  [ISOs.CHE]: { maxLength: '4', pattern: '[0-9]*' },
};

export const regionInputProperties = {};

export const COUNTRY_CODES: Readonly<CountryPhoneNumberConfig[]> = Object.freeze(
  COUNTRIES_WITH_FLAGS_AND_CALL_PREFIX
);

export const getOrderedCountries = (
  countries: Readonly<CountryPhoneNumberConfig[]> = []
): CountryPhoneNumberConfig[] => {
  const unorderedCountries = [...countries];
  return unorderedCountries.sort((a, b) => a.country.localeCompare(b.country));
};
