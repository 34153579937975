import { useEffect, useState } from 'react';

import { useLocale } from 'state/intl';
import { getConfigValue } from 'utils/environment';

declare global {
  interface Window {
    Cookiebot: any;
  }
}

const COOKIEBOT_URL = 'https://consent.cookiebot.com/uc.js';

/**
 * Loads Cookiebot sdk cookie consent script
 */
export default function useCookiebotSdk() {
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const { language } = useLocale();

  useEffect(() => {
    const { scriptId = '' } = getConfigValue('cookieBanners');
    if (!scriptId) {
      return;
    }

    const existingDialog = document.querySelectorAll('CybotCookiebotDialog');
    const existingWidget = document.querySelectorAll('CookiebotWidget');

    if (existingDialog.length > 0 || existingWidget.length > 0) {
      setLoading(false);
      setLoaded(true);
      return;
    }

    const handleLoad = () => {
      setLoading(false);
      setLoaded(true);
    };

    const script = document.createElement('script');
    script.src = COOKIEBOT_URL;
    script.dataset.cbid = scriptId;
    script.dataset.culture = language;
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);
    window.addEventListener('CookiebotOnLoad', handleLoad);

    return () => {
      window.removeEventListener('CookiebotOnLoad', handleLoad);
      document.body.removeChild(script);
    };
  }, []);

  return { loaded, loading };
}
