import { useCallback, useState } from 'react';

import { getIso3 } from '@rbilabs/intl';

import { IsoCountryCode, Platform, Stage, useGuestTokenMutation } from 'generated/graphql-gateway';
import { GuestToken } from 'remote/auth/get-current-guest-session';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { useThirdPartyAuthentication } from 'state/auth/hooks/use-third-party-authentication';
import { useCdpContext } from 'state/cdp';
import { SignInPhases } from 'state/cdp/constants';
import { SignInMethods } from 'state/cdp/types';
import { useLogger } from 'state/logger';
import { env, getCountry, platform } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import * as location from 'utils/location';
import { getPathMatcher } from 'utils/routing';

import { ALLOWED_PATHS_FOR_URL_TOKEN, SIGN_IN_FAIL } from '../constants';
import { GuestTokenInvalid } from '../errors';
import { IGuestCredentials, IGuestDetails, IGuestSignInParams } from '../types';

export const getGuestCredentialsFromUrlQuery = (basename = ''): IGuestCredentials | null => {
  // Parent path
  const pathMatches = getPathMatcher(basename);
  const isPathAllowed = pathMatches(ALLOWED_PATHS_FOR_URL_TOKEN);
  const params = location.getSearchParams();
  const jwt = params.get('token') ?? '';
  let isTokenValid = false;
  if (jwt?.length) {
    try {
      const guestToken = new GuestToken({ token: jwt });
      isTokenValid = !guestToken?.isExpired() && guestToken?.decodedPayload?.type === 'guest';
    } catch (e) {
      return null;
    }
  }

  if (isPathAllowed && isTokenValid) {
    return { token: jwt };
  }
  return null;
};

const clearGuestCredentials = () => LocalStorage.removeItem(StorageKeys.GUEST);
const storeGuestCredentials = (data: IGuestCredentials) =>
  LocalStorage.setItem(StorageKeys.GUEST, data);

export const getGuestCredentials = (): IGuestCredentials | null => {
  const credentials = LocalStorage.getItem(StorageKeys.GUEST);
  if (!credentials?.token) {
    return null;
  }

  const token = new GuestToken({ token: credentials.token });

  if (token.isExpired()) {
    clearGuestCredentials();
    return null;
  }

  return credentials;
};

export const useGuestAuthentication = () => {
  const logger = useLogger();
  const [guestTokenMutation] = useGuestTokenMutation();
  const [signInProgress, setSignInProgress] = useState(false);

  const { signInEvent } = useCdpContext();
  const { logUserInToThirdPartyServices, logUserOutOfThirdPartyServices } =
    useThirdPartyAuthentication();

  const requestGuestToken = useCallback(async () => {
    const { data } = await guestTokenMutation({
      variables: {
        input: {
          country: getIso3({ iso2: getCountry().toUpperCase() }) as IsoCountryCode,
          platform: platform() as any as Platform,
          stage: env() as any as Stage,
        },
      },
    });

    return data?.generateGuestToken ?? undefined;
  }, [guestTokenMutation]);

  const guestToken = useCallback(() => {
    const guestCredentials = getGuestCredentials();
    if (!guestCredentials) {
      return;
    }

    return new GuestToken({ token: guestCredentials.token });
  }, []);

  const isAllowedToPlaceOrder = useCallback(() => {
    const guestCredentials = getGuestCredentials();
    if (!guestCredentials) {
      return false;
    }

    const isAnyOrderPlacedSuccessfully = !!guestCredentials?.details?.committedOrderId;
    const hasAnyOrderCommitFailed = !!guestCredentials?.details?.failedOrderId;

    return !(isAnyOrderPlacedSuccessfully || hasAnyOrderCommitFailed);
  }, []);

  const logInAmplitude = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ email, name, promotionalOptIn, token }: IGuestCredentials): void => {
      if (!email) {
        // if email is not provided, the token is anonymous
        return;
      }
      const newGuestToken = new GuestToken({ token });

      const user: UserDetails = {
        cognitoId: newGuestToken.getGuestId(),

        details: {
          // Currently, we can't store email and name in the user object (Guest Policy)
          // In the future, to enable promotional emails, we need to store them in the user object
          email: '',
          // name,
          promotionalEmails: promotionalOptIn,
          isoCountryCode: getCountry(),
          isGuest: true,
        },
      } as UserDetails;

      logUserInToThirdPartyServices(user);
    },
    [logUserInToThirdPartyServices]
  );

  const signOut = useCallback(() => {
    if (getGuestCredentials()) {
      clearGuestCredentials();
      logUserOutOfThirdPartyServices();
    }
  }, [logUserOutOfThirdPartyServices]);

  const signIn = useCallback(
    async ({ guest: { email, name, promotionalOptIn } = {} }: IGuestSignInParams) => {
      try {
        if (signInProgress) {
          return;
        }
        setSignInProgress(true);

        const guestTokenObject = guestToken();
        let token = guestTokenObject?.getJwtToken();

        const guestCredentials = getGuestCredentials();

        if (guestTokenObject) {
          const isTokenExpired = guestTokenObject.isExpired();
          const isNotAllowedToPlaceOrder = !isAllowedToPlaceOrder();
          if (isTokenExpired || isNotAllowedToPlaceOrder) {
            token = undefined;
            signOut();
          }
        }
        token = token ?? (await requestGuestToken());
        if (!token) {
          throw new GuestTokenInvalid();
        }

        storeGuestCredentials({
          email: guestCredentials?.email || email,
          name,
          promotionalOptIn,
          token,
        });

        logInAmplitude({
          email,
          name,
          promotionalOptIn,
          token,
        });
      } catch (error) {
        logger.error(error);
        error.code = SIGN_IN_FAIL;
        signInEvent({
          phase: SignInPhases.START,
          success: false,
          message: error.message,
          method: SignInMethods.GUEST,
        });
        throw error;
      } finally {
        setSignInProgress(false);
      }
    },
    [
      signInProgress,
      signOut,
      guestToken,
      requestGuestToken,
      isAllowedToPlaceOrder,
      logger,
      signInEvent,
      logInAmplitude,
    ]
  );

  const updateGuestDetails = useCallback((newDetails: IGuestDetails) => {
    const guestCredentials = getGuestCredentials();
    if (!guestCredentials) {
      return;
    }
    const newGuestCredentials = { ...guestCredentials, details: newDetails };
    storeGuestCredentials(newGuestCredentials);
  }, []);

  return {
    getGuestCredentials,
    signIn,
    signOut,
    updateGuestDetails,
    isAllowedToPlaceOrder,
  };
};
