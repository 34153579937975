import React, { useMemo } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library';
import styled from 'styled-components';

import { ICartEntry } from '@rbi-ctg/menu';
import LoadingAnimation from 'components/loading-animation';
import { useAddUpsellCartItem } from 'pages/cart/cart-item/use-add-upsell-cart-item';
import useMenuFeatureUpsell from 'pages/cart/upsell/use-menu-feature-upsell';
import { useOrderContext } from 'state/order';

import { UpsellDialog } from './upsell-dialog';
import { UpsellModalBottomButton } from './upsell-modal-bottom-button';
import { UpsellModalContent } from './upsell-modal-content';

export interface UpsellModalProps {
  title: string;
  isOpen: boolean;
  onClose: VoidFunction;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 50vh;
`;

export const UpsellModal: React.FC<UpsellModalProps> = ({ isOpen, title, onClose }) => {
  const { addToCart, cartPreviewEntries } = useOrderContext();
  const { items, loading } = useMenuFeatureUpsell();

  const upsellItemsFromCart: ICartEntry[] = useMemo(
    () => cartPreviewEntries.filter((entry: ICartEntry) => entry.isUpsell),
    [cartPreviewEntries]
  );

  // - the need for useMemo here is not to optimize performance or avoid re-renders
  // - I did it this way because after an upsell item is added to the cart, it is filtered out from the items array (the useMenuFeatureUpsell hook does this filtering)
  // - what I do in this useMemo is creating a duplicate of the upsell items and use this duplicate to render the upsell items in the UpsellModalContent component
  const loadedItems = useMemo(
    () => (!loading && items.length ? [...items] : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  );

  const { orderAddedAlertMessage, handleAddToCart } = useAddUpsellCartItem({ addToCart, items });
  const hasAddedUpsellItem = upsellItemsFromCart.length > 0;

  return (
    <UpsellDialog open={isOpen} title={title} onCloseModal={onClose}>
      {loading ? (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      ) : (
        <>
          {isOpen && (
            <UpsellModalContent
              upsellItems={loadedItems}
              onAddItem={handleAddToCart}
              cartEntries={cartPreviewEntries}
            />
          )}

          {orderAddedAlertMessage && (
            <VisuallyHidden role="alert">{orderAddedAlertMessage}</VisuallyHidden>
          )}

          <UpsellModalBottomButton onClose={onClose} hasAddedUpsellItem={hasAddedUpsellItem} />
        </>
      )}
    </UpsellDialog>
  );
};
