import * as location from 'utils/location';
import { removeLanguageFromPath } from 'utils/routing';

export const isURIScheme = (url: string): boolean => {
  const u = new URL(url);
  if (!(u.protocol === 'http:' || u.protocol === 'https:')) {
    const uriParts = url.split('://');
    return uriParts && uriParts.length === 2;
  }
  return false;
};

// if the protocol is `popeyes://`
export const parseURIScheme = (url: string): string | undefined => {
  const u = new URL(url);
  if (!(u.protocol === 'http:' || u.protocol === 'https:')) {
    const uriParts = url.split('://');
    if (uriParts && uriParts.length === 2) {
      // adding a "/" to prevent routing issues for routes using slug
      return `/${uriParts[1]}`;
    }
  }
  return;
};

// Get the disallowed URLs from the robots.txt file
export const getDisallowedUrls = (robotsTxt: string): string[] =>
  (robotsTxt ?? '').split('\n').reduce((acc, line) => {
    const [key, value] = line.split(':');
    const cleanValue = value?.trim() ?? '';
    const isValid = key.startsWith('Disallow') && cleanValue.startsWith('/');

    return isValid ? [...acc, cleanValue] : acc;
  }, []);

export const tryParse = (url: string) => {
  try {
    return new URL(url);
  } catch (error) {
    return undefined;
  }
};

/**
 * Given app URL (hostnames match) it returns canonical relative URI:
 * pathname (without language code) + query string parameters.
 * For external URLs it returns the full URL.
 *
 * NOTE: this is to be used with http(s) URLs only. For handling URLs for custom
 * application scheme protocols, use the `parseURI` function above.
 */
export const resolveUrl = (urlString: string) => {
  const url = tryParse(urlString);

  if (!url) {
    return '/';
  }

  // For 3rd party domains return the full URL
  if (!url.hostname.endsWith(location.get('hostname'))) {
    return url.toString();
  }

  const pathname = removeLanguageFromPath(url.pathname);

  return `${pathname}${url.search}`;
};
