import styled from 'styled-components';

import { View } from 'components/view';
import { Link } from 'state/location';
import Styles from 'styles/index';

import { PADDING_TOP_LOGO } from './constants';

// The absolute position pops it out of the flex flow
// and this allows our centered logo to be truly centered and not offset by the menu item
export const MenuButtonContainer = styled(View)`
  position: absolute;
  flex-shrink: 1;
`;

export const MobileHeaderContainer = styled(View)<{ reversed?: boolean }>`
  padding-block-start: env(safe-area-inset-top);
  padding-inline-end: env(safe-area-inset-right);
  padding-inline-start: env(safe-area-inset-left);
  width: 100%;
  background: ${p =>
    p.reversed
      ? p.theme.token('background-hero-reversed-native')
      : p.theme.token('background-hero-light-native')};
  z-index: ${Styles.zIndex.overlay};
`;

export type HeaderProps = {
  nativeStatusBarExist: boolean;
};
export const Header = styled(View)<HeaderProps>`
  align-items: center;
  color: ${p => p.theme.token('text-reversed')};
  flex-direction: row;
  height: ${Styles.layout.navHeight.mobile};
  justify-content: space-between;
  padding: 16px;
  padding-block-start: ${p => (p.nativeStatusBarExist ? PADDING_TOP_LOGO : `16px`)};
  text-align: center;
  width: 100%;
  overflow-y: hidden;
`;

export const LinkNoDecoration = styled(Link)`
  &::before {
    color: transparent;
  }
  &:active,
  &:hover,
  &:link,
  &:visited {
    color: inherit;
  }
`;
