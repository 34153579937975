import React, { FC, useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { CookieBanner } from 'components/cookie-banner';
import { useHasAcceptedCookies } from 'hooks/use-has-accepted-cookies';
import { useHasIgnoredCookies } from 'hooks/use-has-ignored-cookies';
import { useRoutes } from 'hooks/use-routes';
import { useAuthContext } from 'state/auth';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { versionIsGreaterThanOrEqualTo } from 'utils/environment';
import { getIosVersion } from 'utils/get-ios-version';
import LocalStorage, { COOKIE_VERSION, StorageKeys } from 'utils/local-storage';

import useInitializeThirdPartyServices from '../use-initialize-third-party-service';

const CookieBannerContainer: FC = () => {
  const { formatMessage } = useIntl();
  const { user } = useAuthContext();
  const { trackEvent } = useCdpContext();
  const enableCookieVersioning = useFlag(LaunchDarklyFlag.ENABLE_COOKIE_VERSIONING);
  const [showBanner, setShowBanner] = useState(false);
  const [iOSVersionGreaterThanFourteen, setIOSVersionGreaterThanFourteen] = useState(false);
  const { getLocalizedRouteForPath } = useRoutes();
  const hasAcceptedCookies = useHasAcceptedCookies();
  const hasIgnoredCookies = useHasIgnoredCookies();
  const { initServicesWithCookies } = useInitializeThirdPartyServices();

  async function versionIsGreaterThanOrEqualToFourteen() {
    const iOSVersion = await getIosVersion();
    const versionGreaterThanFourteen = versionIsGreaterThanOrEqualTo({
      versionString: iOSVersion,
      majorVersionGreaterThanOrEqualTo: 14,
    });
    setIOSVersionGreaterThanFourteen(versionGreaterThanFourteen);
  }

  useEffect(() => {
    setShowBanner(!hasAcceptedCookies && !hasIgnoredCookies);
  }, [hasAcceptedCookies, hasIgnoredCookies, user]);

  useEffect(() => {
    versionIsGreaterThanOrEqualToFourteen();
  }, []);

  const handleAccept = useCallback(() => {
    LocalStorage.setItem(StorageKeys.LAST_TIME_COOKIES_ACCEPTED, Date.now());
    if (enableCookieVersioning) {
      LocalStorage.setItem(StorageKeys.LAST_COOKIE_VERSION, COOKIE_VERSION);
    }
    initServicesWithCookies();
    trackEvent({
      name: CustomEventNames.BUTTON_CLICK_ACCEPT_COOKIES,
      type: EventTypes.UserPreference,
      attributes: {},
    });
    setShowBanner(false);
  }, [enableCookieVersioning, initServicesWithCookies, trackEvent]);

  const handleClose = useCallback(() => {
    LocalStorage.setItem(StorageKeys.LAST_TIME_COOKIES_IGNORED, Date.now());
    setShowBanner(false);
    trackEvent({
      name: CustomEventNames.BUTTON_CLICK_REJECT_COOKIES,
      type: EventTypes.UserPreference,
      attributes: {},
    });
  }, [trackEvent]);

  const link = {
    text: formatMessage({ id: 'privacyPolicy' }),
    href: getLocalizedRouteForPath('privacy-policy'),
  };

  return showBanner ? (
    <CookieBanner
      infoText={formatMessage(
        { id: iOSVersionGreaterThanFourteen ? 'cookiePolicyiOS14' : 'cookiePolicy' },
        { acceptCookies: formatMessage({ id: 'acceptAllCookies' }) }
      )}
      buttonText={formatMessage({ id: 'acceptAllCookies' })}
      handleAccept={handleAccept}
      handleClose={handleClose}
      link={link}
    />
  ) : null;
};

export { CookieBannerContainer };
