import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { getCountdownValue } from 'utils/dateTime';
import { screenReaderOnly } from 'utils/style/screen-reader-only';

const formatNumber = (n: number) => `${n}`.padStart(2, '0');

interface ICountdownProps {
  endTime: number;
  className?: string;
  onFinish: VoidFunction;
  useClockStyle?: boolean;
}

const ClockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-family: ${Styles.fontFamily.base};
  color: ${Styles.color.black};
`;

const ClockMinAndSec = styled.div`
  background-color: white;
  border-radius: ${Styles.borderRadius};
  font-weight: ${Styles.fontWeight.heavy};
  width: 50px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding-block-end: 0.2rem;
`;

const ClockColon = styled.div`
  height: 55px;
  padding: 0 0.2rem;
`;

const ScreenReader = styled.div`
  ${screenReaderOnly}
`;

const Countdown = ({ endTime, className = '', onFinish, useClockStyle }: ICountdownProps) => {
  const [nowTime, setNowTime] = useState(() => Date.now());

  const timeLeft = useMemo(() => endTime - nowTime, [endTime, nowTime]);

  const { minutes, seconds, screenReaderSec, screenReaderMins } = useMemo(
    () => getCountdownValue(timeLeft),
    [timeLeft]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setNowTime(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (onFinish && timeLeft <= 0) {
    onFinish();
    return null;
  }

  const formattedMinutes = formatNumber(minutes);
  const formattedSeconds = formatNumber(seconds);
  const formattedScreenReaderMins = formatNumber(screenReaderMins);
  const formattedScreenReaderSec = formatNumber(screenReaderSec);

  // TODO: Maybe fix the formattedScreenReader days and hours??
  const screenOnlyContent = `${formattedScreenReaderMins}:${formattedScreenReaderSec}`;

  const screenOnlyElement = (
    <ScreenReader role="timer" aria-live="assertive" aria-atomic="true">
      {screenOnlyContent}
    </ScreenReader>
  );

  let clockElement;

  if (useClockStyle) {
    clockElement = (
      <ClockWrapper data-testid="countdown-clock">
        <ClockMinAndSec data-testid="minutes">{formattedMinutes}</ClockMinAndSec>
        <ClockColon>:</ClockColon>
        <ClockMinAndSec data-testid="seconds">{formattedSeconds}</ClockMinAndSec>
      </ClockWrapper>
    );
  } else {
    clockElement = (
      <span className={className} data-testid="countdown-clock">
        {formattedMinutes}:{formattedSeconds}
      </span>
    );
  }

  return (
    <>
      {clockElement}
      {screenOnlyElement}
    </>
  );
};

export default Countdown;
