import styled from 'styled-components';

import { Header } from 'components/app-header/mobile-header/styled';

export const SignUpHeader = styled(Header)`
  background-color: ${({ theme }) => theme.token('background-default')};
`;

export const BackArrowContainer = styled.div`
  cursor: pointer;
  touch-action: manipulation;
`;
