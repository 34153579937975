import React, { useState } from 'react';

import { PrimaryButton, SecondaryButton } from '@rbilabs/components-library';
import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';

import { MobileHeaderContainer } from 'components/app-header/mobile-header/styled';
import Dialog from 'components/dialog';
import { ButtonGroupsCancelDialog } from 'components/store-card/styled';
import useGoBack from 'hooks/use-go-back';
import { useNativeStatusBar } from 'hooks/use-native-status-bar';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

import { BackArrowContainer, SignUpHeader } from './signup-mobile-header.styled';

const SignupMobileHeader = () => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { navigate, pathMatches } = useLocationContext();

  const { formatMessage } = useIntl();
  const { nativeStatusBarExist } = useNativeStatusBar();
  const goBack = useGoBack();

  const handleGoBack = () => {
    if (pathMatches(routes.createAccount)) {
      setShowCancelModal(true);
    } else {
      goBack();
    }
  };

  const handleCloseCancelModal = () => setShowCancelModal(false);
  const handleConfirmCancelModal = () => {
    setShowCancelModal(false);
    navigate(routes.signUp);
  };

  return (
    <>
      <MobileHeaderContainer data-mediaquery="mobile" data-testid="signup-mobile-header">
        <SignUpHeader nativeStatusBarExist={nativeStatusBarExist}>
          <BackArrowContainer onClick={handleGoBack}>
            <Icon
              icon="back"
              color="icon-default"
              width="24px"
              title={formatMessage({ id: 'back' })}
            />
          </BackArrowContainer>
        </SignUpHeader>
      </MobileHeaderContainer>
      {showCancelModal && (
        <Dialog
          data-testid="cancel-create-account"
          heading={formatMessage({ id: 'cancelCreateAccount' })}
          body={
            <ButtonGroupsCancelDialog>
              <PrimaryButton
                fullWidth
                aria-label={formatMessage({ id: 'yes' })}
                onClick={handleConfirmCancelModal}
                data-testid="yes-cancel-create-account"
              >
                {formatMessage({ id: 'yes' })}
              </PrimaryButton>
              <SecondaryButton
                fullWidth
                aria-label={formatMessage({ id: 'noCancel' })}
                onClick={handleCloseCancelModal}
                data-testid="no-cancel-create-account-dialog-button"
              >
                {formatMessage({ id: 'noCancel' })}
              </SecondaryButton>
            </ButtonGroupsCancelDialog>
          }
          modalAppearanceEventMessage="cancel-create-account"
        />
      )}
    </>
  );
};

export default SignupMobileHeader;
