import { AllowedEvent } from '@rbilabs/intl-mparticle-client';
import delv from 'dlv';
import { isNil } from 'lodash';

import { NonNullableObject } from '@rbi-ctg/frontend';
import { ICartEntry } from '@rbi-ctg/menu';
import { HttpErrorCodes } from 'remote/constants';
import { CommunicationPreferences } from 'state/auth';
import { ServiceMode } from 'state/service-mode/types';
import LocalStorage from 'utils/cognito/storage';
import { Keys } from 'utils/local-storage/constants';

import { CartPaymentType } from '../order/types';

import { FALSE, FALSE_VALS, TRUE, TRUE_VALS } from './constants';
import { HTTPCode, ICdpAttributes, IGenericEvent, UpdatedUserAttributes } from './types';

export function serializePaymentType(paymentType: CartPaymentType | null) {
  switch (paymentType) {
    case CartPaymentType.APPLE_PAY:
      return 'APPLE_PAY';
    case CartPaymentType.GOOGLE_PAY:
      return 'GOOGLE_PAY';
    case CartPaymentType.CREDIT_ANONYMOUS:
      return 'CREDIT_ANONYMOUS';
    default:
      return 'VAULTED_ACCOUNT';
  }
}

export function serializeServiceMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
    case ServiceMode.CATERING_DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
    case ServiceMode.EAT_IN:
    case ServiceMode.TAKEOUT:
    case ServiceMode.CURBSIDE:
    case ServiceMode.TABLE_SERVICE:
    case ServiceMode.CATERING_PICKUP:
      return 'Pickup';
    default:
      return 'None';
  }
}

export function serializePickupMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
      return 'Drive Thru';
    case ServiceMode.EAT_IN:
      return 'Eat In';
    case ServiceMode.TAKEOUT:
      return 'Take Out';
    case ServiceMode.CURBSIDE:
      return 'Curbside';
    case ServiceMode.TABLE_SERVICE:
      return 'Table Service';
    case ServiceMode.CATERING_PICKUP:
      return 'Catering Pickup';
    default:
      return 'None';
  }
}

export function serializeNumberOfDriveThruWindows(driveThruLaneType: string | null) {
  switch (driveThruLaneType) {
    case 'single':
      return 1;
    case 'dual':
      return 2;
    default:
      return 0;
  }
}

// Removes null, undefined and empty string values
export function sanitizeValues<M extends object = ICdpAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  return Object.entries(attributes).reduce(
    (memo, [key, value]) => {
      const attrIsEmptyString = typeof value === 'string' && value.length === 0;
      if (!isNil(value) && !attrIsEmptyString) {
        memo[key] = value;
      }
      return memo;
    },
    {} as NonNullableObject<Partial<M>>
  ) as NonNullableObject<Partial<M>>;
}

export const booleanToString = (bool: boolean): typeof TRUE | typeof FALSE => (bool ? TRUE : FALSE);

const normalizeStringBoolean = (str: string): string => {
  if (TRUE_VALS.includes(str)) {
    return TRUE;
  }
  if (FALSE_VALS.includes(str)) {
    return FALSE;
  }
  return str;
};

export function normalizeBooleans(attributes: object): any {
  const copy = {};
  Object.keys(attributes).forEach(key => {
    const value = attributes[key];
    if (typeof value === 'boolean') {
      copy[key] = booleanToString(value);
    } else if (typeof value === 'string') {
      copy[key] = normalizeStringBoolean(value);
    } else {
      copy[key] = value;
    }
  });
  return copy;
}

export function flattenCartEntryItems(cartEntry: ICartEntry): ICartEntry[] {
  const children = cartEntry.children.reduce((accum, current) => {
    return [...accum, ...flattenCartEntryItems(current)];
  }, []);
  return [cartEntry, ...children];
}

// returns window location path name
export const getSourcePage = (pathname: string) => {
  // removes menu child routes
  return pathname.replace(/(menu)\/(.*)/i, '$1/');
};

export const getUserHasLoyalty = () => (LocalStorage.getItem(Keys.USER)?.loyaltyId ? true : false);

export const getAllowedAttributes = (event: AllowedEvent | IGenericEvent) => {
  const allowedAttributes = [
    // from IPageView
    'path',
    'pathWithQuery',
    'serviceMode',
    'appBuild',
    'Pick up Mode',
    'Platform',
    'Locale',
    'referrer',
    'restaurantId',
    'restaurantAddress',
    'restaurantZip',
    'restaurantCity',
    'restaurantState',
    'restaurantCountry',
    'storeId',
    // from IClickEvent
    'component',
    'text',
    'componentId',
    // for Modal Appearence
    'Message',
    'ErrorMessage',
    'ModalHeader',
    'ModalMessage',
    'Source Page',
    'Tab',
    'component',
    'componentId',
    'redemptionMode',
    'sanityId',
    'Marketing Card ID',
    'Marketing Card URL',
    'menuType',
    'my_code_page_load_time',
    'rewards_page_load_time',
    'offers_page_load_time',
    'offer_redemption_page_load_time',
  ];
  if (!event.attributes) {
    return {};
  }
  return Object.fromEntries(
    Object.entries(event.attributes).filter(([key]) => allowedAttributes.includes(key))
  );
};

export const errorIdentityCallback = ({
  identityFn,
  callback,
  result,
  tryAgain = true,
  params,
  logger,
}: {
  identityFn: any;
  callback: any;
  result: {
    httpCode?: any;
    body?: any;
    status?: string;
  };
  tryAgain?: boolean;
  params?: any;
  logger?: any;
}) => {
  switch (result.httpCode) {
    case HTTPCode.NATIVE_IDENTITY_REQUEST:
      return;
    case HTTPCode.NO_HTTP_COVERAGE:
      if (tryAgain) {
        return identityFn(params, { callback, tryAgain });
      }
      break;
    case HTTPCode.ACTIVE_IDENTITY_REQUEST:
    case HttpErrorCodes.TooManyRequests:
      if (tryAgain) {
        return identityFn(params, { callback, tryAgain: false });
      }
      break;
    case HTTPCode.VALIDATION_ISSUE:
    case 400:
    default:
      logger.error({ error: result.body });
  }
};

export enum ProductItemType {
  Parent = 'Parent',
  Child = 'Child',
}

export const flattenCommunicationPreferences = (
  userCommPreferences?: CommunicationPreferences | null
) =>
  isNil(userCommPreferences)
    ? {}
    : userCommPreferences?.reduce?.(
        (acc, commPreference) =>
          commPreference.id !== 'email_subscribe' && commPreference.id !== 'marketingEmail'
            ? { ...acc, [commPreference.id]: commPreference.value.toLowerCase() }
            : acc,
        {}
      );

export const getPushOptions = (
  enablePushNotificiationsOnSignUp: boolean | undefined,
  updatedAttributes: UpdatedUserAttributes,
  promotionalEmails: string | boolean | undefined
) => {
  return enablePushNotificiationsOnSignUp
    ? {
        push_subscribe:
          delv(updatedAttributes, 'promotionalEmails', promotionalEmails).toLowerCase() === 'true'
            ? 'opted_in'
            : 'unsubscribed',
        marketingPush: delv(updatedAttributes, 'promotionalEmails', promotionalEmails),
        rewardsPush: delv(updatedAttributes, 'promotionalEmails', promotionalEmails),
      }
    : {};
};
