import isEmpty from 'lodash/isEmpty';
import { QRCodeRenderersOptions } from 'qrcode';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { barcodeOptions } from './constants';

type QRCodeVariation = QRCodeRenderersOptions | undefined | null;

/**
 *
 * Get the barcodeOptions based on the LD flag in use.
 *
 * @param {QRCodeRenderersOptions} DefBarcodeOpts default barcodeOptions if the LD flag is off.
 * @returns {QRCodeRenderersOptions} barcodeOptions to be used.
 */
export const GetBarcodeOptions = (
  DefBarcodeOpts: QRCodeRenderersOptions = barcodeOptions
): QRCodeRenderersOptions => {
  const BarcodeOpts: QRCodeVariation = useFlag(
    LaunchDarklyFlag.LOYALTY_QR_CODE_SIZE_COLOR_VARIATIONS
  );
  if (!BarcodeOpts || isEmpty(BarcodeOpts)) {
    return DefBarcodeOpts;
  }
  return BarcodeOpts;
};
