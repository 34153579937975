import React, { FC } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import { ISanityImage } from '@rbi-ctg/menu';
import Picture from 'components/picture';

import {
  LinkWrapper,
  RewardsLogoMemberWrapper,
  RewardsLogoNonMemberWrapper,
} from './loyalty-app-header.styled';

interface ILoyaltyAppHeaderProps {
  points: number | undefined;
  rewardsLogo: ISanityImage;
  rewardsLink: string;
}

export const LoyaltyAppHeaderView: FC<ILoyaltyAppHeaderProps> = ({
  points,
  rewardsLink,
  rewardsLogo,
}) => {
  const { formatMessage } = useIntl();

  const isLoyaltyMember = typeof points === 'number';
  const description = isLoyaltyMember
    ? formatMessage({ id: 'rewardsPointsAmount' }, { points })
    : formatMessage({ id: 'rewards' });
  const RewardsLogoWrapper = isLoyaltyMember
    ? RewardsLogoMemberWrapper
    : RewardsLogoNonMemberWrapper;

  return (
    <LinkWrapper to={rewardsLink}>
      <VisuallyHidden>{description}</VisuallyHidden>
      <RewardsLogoWrapper>
        <Picture image={rewardsLogo} alt={formatMessage({ id: 'rewards' })} objectFitContain />
      </RewardsLogoWrapper>
    </LinkWrapper>
  );
};
