import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import LaunchDarklyHelper from 'utils/launchdarkly';

export const useFlagsForUniversalAttributes = () => {
  const chefUpsellItemCount = useFlag(LaunchDarklyFlag.CHEF_CART_UPSELL_ITEM_COUNT);
  const enableFlavorFlow = useFlag(LaunchDarklyFlag.ENABLE_FLAVOR_FLOW);
  const enableCheckoutUpsellItems2 = useFlag(LaunchDarklyFlag.ENABLE_CHECKOUT_UPSELL_ITEMS_2);
  const ldClient = LaunchDarklyHelper.getInstance().launchDarkly;
  const chefRecommendationEngine2 =
    ldClient && ldClient.variation(LaunchDarklyFlag.CHEF_RECOMMENDATION_ENGINE_2);

  const enableStoreConfirmationModal = useFlag(LaunchDarklyFlag.ENABLE_STORE_CONFIRMATION_MODAL);
  const enableServiceModeCartSelection = useFlag(
    LaunchDarklyFlag.ENABLE_SERVICE_MODE_CART_SELECTION
  );
  const enableOffersRefresh = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_REFRESH);
  const enableFeatureHomePage = useFlag(LaunchDarklyFlag.ENABLE_FEATURE_HOME_PAGE);
  const enableHomePageRecentItems = useFlag(LaunchDarklyFlag.ENABLE_HOME_PAGE_RECENT_ITEMS);
  const enableRecentItemsAddToCart = useFlag(LaunchDarklyFlag.ENABLE_RECENT_ITEMS_ADD_TO_CART);
  const enableRecentlyOrderedItems = useFlag(LaunchDarklyFlag.ENABLE_RECENTLY_ORDERED_ITEMS);
  const enableUserSavedDeliveryAddressPhone = useFlag(
    LaunchDarklyFlag.ENABLE_USER_SAVED_DELIVERY_ADDRESS_PHONE
  );
  const enableRecentItemsWithModifiers = useFlag(
    LaunchDarklyFlag.ENABLE_RECENT_ITEMS_WITH_MODIFIERS
  );

  return {
    enableRecentItemsWithModifiers,
    chefUpsellItemCount,
    enableFlavorFlow,
    enableCheckoutUpsellItems2,
    chefRecommendationEngine2,
    enableStoreConfirmationModal,
    enableServiceModeCartSelection,
    enableOffersRefresh,
    enableFeatureHomePage,
    enableHomePageRecentItems,
    enableRecentItemsAddToCart,
    enableRecentlyOrderedItems,
    enableUserSavedDeliveryAddressPhone,
  };
};
