import { IsoCountryCode2, getIso2, getZipCodeRegex } from '@rbilabs/intl';
import { differenceInCalendarMonths } from 'date-fns';
import { IntlShape } from 'react-intl';

import { IAdyenBrowserInfoData } from 'components/credit-card-form-inputs/adyen-credit-card-form-inputs/types';
import { PaymentMethod } from 'generated/graphql-gateway';
import { CartPaymentCardType, PaymentMethodBrand } from 'generated/rbi-graphql';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { TipAmounts } from 'state/order';
import { PaymentFlowType } from 'state/order/types';
import { CardType, CardTypes, IPaymentMethod } from 'state/payment/types';
import { FALLBACK_ISO2, FALLBACK_ZIPCODE_REGEX } from 'utils/constants';
import { brand } from 'utils/environment';
import { ISOs } from 'utils/form/constants';
import { isApplePay, isGooglePay } from 'utils/payment/native-payment';

import { sanitizeNumber } from '../form';

import { DEFAULT_BILLING_COUNTRY } from './default-billing-country';
import { getBillingCountryError } from './get-billing-country-error';

type CCFormAddress = Pick<
  IPaymentState,
  'billingStreetAddress' | 'billingApt' | 'billingCity' | 'billingState' | 'billingZip'
>;

interface IDeliveryAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

export type BrowserInformation = {
  acceptHeader: string; // The accept header value of the customer
  colorDepth: number; // The color depth of the customers' browser in bits per pixel.
  javaEnabled: boolean; // Boolean value indicating if the customer's browser is able to execute Java. Value is returned from the navigator.javaEnabled property
  language: string; // Value represents the browser language as defined in IETF BCP47. eg en-US
  screenHeight: number; // The total height of the customers's device screen in pixels.
  screenWidth: number; // The total width of the customers's device screen in pixels.
  timeZoneOffset: number; // Time difference between UTC time and the cardholder browser local time, in minutes.
  userAgent: string; // The user agent value of the customer's browser.
};

export interface IPaymentErrors {
  nameOnCard: string;
  cardNumber: string;
  cardType: string;
  cvv: string;
  expiry: string;
  billingStreetAddress: string;
  billingApt: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingCountry?: string;
  giftCardNumber?: string;
  blik?: string;
}

export interface IPaymentState {
  nameOnCard: string;
  cardNumber: string;
  isCardNumberValid: boolean;
  isExpiryValid: boolean;
  cardType: string;
  cvv: string | null;
  expiry: string | null;
  billingStreetAddress: string | null;
  billingApt: string | null;
  billingCity: string | null;
  billingState: string | null;
  billingZip: string;
  billingCountry: ISOs | null;
  billingAddressSameAsDelivery: boolean;
  saveCard: boolean;
  paymentFlowType?: PaymentFlowType;
}

export interface IAdyenPaymentState extends IPaymentState {
  encryptedExpiryMonth: string;
  encryptedExpiryYear: string;
  browserInfo: IAdyenBrowserInfoData;
  blikCode: string | null;
  redirectDetails?: any;
}

export interface IVrPaymentState extends IPaymentState {
  merchantAccount: string;
  pspReference: string;

  paypal?: {
    billingAgreementId?: string;
    deviceToken?: string;
    email?: string;
  };

  card?: {
    expiryMonth: string;
    expiryYear: string;
    bin: string;
    last4: string;
  };
}

export interface IPayMarkState extends IPaymentState {
  merchantAccount?: string;
  pspReference?: string;

  card: {
    expiryMonth: string;
    expiryYear: string;
    bin: string;
    last4: string;
  };
}

export interface IEvertecState extends IPaymentState {
  merchantAccount?: string;
  pspReference?: string;
  iframeUrl?: string;
  isPayLink?: boolean;
  card?: {
    expiryMonth: string;
    expiryYear: string;
    bin: string;
    last4: string;
  };
  paymentMethodBrand?: PaymentMethodBrand;
  paymentToken?: string;
}

export interface ICheckoutDotComState extends IPaymentState {
  merchantAccount?: string;
  pspReference?: string;
  iframeUrl?: string;
  isPayLink?: boolean;
  card?: {
    expiryMonth: string;
    expiryYear: string;
    bin: string;
    last4: string;
  };
  deviceSessionId?: string;
  paymentMethodBrand?: PaymentMethodBrand;
  paymentToken?: string;
}

export interface ICybersourceState extends IPaymentState {
  billingCustomer: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  cybersourceInput: {
    pspReference: string;
    paymentToken: string;
  };
  browserInformation?: BrowserInformation;
  referenceId?: string;
}

export interface IPaycometState extends IPaymentState {
  merchantAccount?: string;
  pspReference?: string;
  jetId?: string;
  iframeUrl?: string;
  isPayLink?: boolean;
  card?: {
    expiryMonth: string;
    expiryYear: string;
    bin: string;
    last4: string;
  };
  paymentMethodBrand?: PaymentMethodBrand;
  paytpvToken?: string;
}

export interface IFirstpayState extends IPaymentState {
  merchantAccount: string;
  pspReference: string;

  card: {
    expiryMonth: string;
    expiryYear: string;
    bin: string;
    last4: string;
  };
}

export interface IGiftCardPaymentErrors {
  cardNumber: string;
}

export interface IGiftCardPaymentState {
  cardNumber: string;
  isCardNumberValid: boolean;
  saveCard: boolean;
}

/**
 * "Payment Processor" billing address. This applies to both First Data and Adyen.
 *
 * Note: There is no such thing as a payment processor billing
 * address. Check the FirstData and payment gateway
 * spec to ensure format is compliant.
 *
 * https://firstdatanp-ucomgateway.apigee.io/apis/ucomaccountservices/index
 *
 */
export type IPaymentProcessorBillingAddress = PaypalBillingAddress | VisaBillingAddress;

/**
 * Billing address format supported by Visa.
 *
 * https://developer.paypal.com/docs/api/reference/country-codes/#
 */
type PaypalBillingAddress = {
  unitNumber: string | null;
  locality: string | null;
  postalCode: string;
  region: string | null;
  streetAddress: string | null;
  /** IS0-3166-1 country code (2 char only) */
  country: IsoCountryCode2 | null;
};

/**
 * Billing address format supported by Visa.
 * https://developer.visa.com/capabilities/pav/reference
 **/
type VisaBillingAddress = {
  unitNumber: string | null;
  locality: string | null;
  postalCode: string;
  region: string | null;
  streetAddress: string | null;
  /** IS0-3166-1 country code */
  country: ISOs | null;
};

export interface ICCExpiry {
  month: string;
  year: string;
}

export interface IPaymentPayload {
  billingAddress: IPaymentProcessorBillingAddress;
  cardNumber: string;
  cardType: string;
  expiryDate: ICCExpiry | null;
  nameOnCard: string;
  securityCode: string | null;
  chaseProfileId?: string;
  accountToDelete?: string;
  firstpay?: IFirstpayPayload;
}

export interface IFirstpayPayload {
  bin: string;
  last4: string;
}

export interface IFraudPreventionValues {
  billingAddress: IPaymentProcessorBillingAddress;
  fullName: string;
  ccBin?: string | null;
  ccLast4?: string | null;
  expiry: string | null;
}

enum FormFields {
  CARD_NUMBER = 'cardNumber',
  EXPIRY = 'expiry',
  BILLING_ZIP = 'billingZip',
  ADDRESS_SAME_AS_DELIVERY = 'billingAddressSameAsDelivery',
}

const visaRegEx: RegExp = /^4[0-9]{12}(?:[0-9]{3})?$/;
const mastercardRegEx: RegExp = /^(5[1-5][0-9]{14}|2[2-7][0-9]{14})$/;
export const amexpRegEx: RegExp = /^3[47][0-9]{13}$/;
const discoverRegEx: RegExp = /^6(?:(011|5[0-9]{2}))[0-9]{12}|62[0-9]{14}$/;
const jcbRegex: RegExp = /^(?:2131|2100|1800|35\d{3})\d{11}$/;
const dinersclubRegex: RegExp = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;

export function validateCreditCardNumber(
  cardNumber: string,
  supportedCardTypes: CardType[]
): { isValid: boolean; cardType?: CardType } {
  let cardType: CardType | undefined;

  if (visaRegEx.test(cardNumber)) {
    cardType = 'VISA';
  } else if (mastercardRegEx.test(cardNumber)) {
    cardType = 'MASTERCARD';
  } else if (amexpRegEx.test(cardNumber)) {
    cardType = 'AMEX';
  } else if (discoverRegEx.test(cardNumber)) {
    cardType = 'DISCOVER';
  } else if (jcbRegex.test(cardNumber.replace(/\s/g, ''))) {
    cardType = 'JCB';
  } else if (dinersclubRegex.test(cardNumber.replace(/\s/g, ''))) {
    cardType = 'DINERS_CLUB';
  }

  return { isValid: Boolean(cardType && supportedCardTypes.includes(cardType)), cardType };
}

export function isGiftCardNumberValid(cardNumber: string): boolean {
  return /^[0-9]{16}$/.test(cardNumber);
}

export const splitExpiry = (expiry: string) => {
  const [expiryMonth = '', expiryYear = '']: string[] = expiry.split('/');

  return {
    expiryMonth,
    expiryYear,
  };
};

export function validateExpiry(expiryMonthAndYear: string): boolean {
  const { expiryMonth, expiryYear } = splitExpiry(expiryMonthAndYear);
  const now: Date = new Date();
  const expiry: Date = new Date(Number(`20${expiryYear}`), Number(expiryMonth) - 1);

  return differenceInCalendarMonths(expiry, now) >= 0;
}

export function excludeNumeric(string: string): string {
  return string.replace(/^\d$/g, '').trim();
}

/** Takes UK post code and returns formatted string (uppercase and with space) */
export const parseUkPostCode = (postCode: string) => {
  // UK Post Code format - https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s16.html
  const regex = /^([A-Z]{1,2}[0-9R][0-9A-Z]?)\s?([0-9][ABD-HJLNP-UW-Z]{2})$/i.exec(postCode);

  // This shouldn't occur - validation should catch this
  if (!regex) {
    throw Error('Bad post code slipped past validation');
  }

  return `${regex[1]} ${regex[2]}`.toUpperCase();
};

export const onGiftCardFormChange = (
  name: string,
  value: string,
  state: IGiftCardPaymentState,
  formErrors: IPaymentErrors
): { state: IGiftCardPaymentState; formErrors: IPaymentErrors } => {
  if (name === FormFields.CARD_NUMBER) {
    const isValid: boolean = isGiftCardNumberValid(sanitizeNumber(value));

    formErrors.cardNumber = '';

    state.cardNumber = sanitizeNumber(value);
    state.isCardNumberValid = isValid;
  }

  return { state, formErrors };
};

export const getGiftCardFormErrors = (
  state: IGiftCardPaymentState,
  formErrors: IGiftCardPaymentErrors,
  formatMessage: IntlShape['formatMessage']
): { hasErrors: boolean; formErrors: IGiftCardPaymentErrors } => {
  if (!state.isCardNumberValid) {
    formErrors.cardNumber = formatMessage({ id: 'giftCardNumberIsNotValid' });
  }
  if (!state.cardNumber.trim()) {
    formErrors.cardNumber = formatMessage({ id: 'giftCardNumberIsRequired' });
  }

  const hasErrors: boolean = Object.values(formErrors).some(err => err);

  return { hasErrors, formErrors };
};

export const onCCFormChange = (
  name: string,
  value: string,
  state: IPaymentState,
  formErrors: IPaymentErrors,
  formatMessage: IntlShape['formatMessage'],
  supportedCardTypes: CardType[]
): { state: IPaymentState; formErrors: IPaymentErrors } => {
  if (name === FormFields.CARD_NUMBER) {
    const sanitizedNumber = sanitizeNumber(value);

    const { cardType, isValid } = validateCreditCardNumber(sanitizedNumber, supportedCardTypes);

    formErrors.cardType = '';
    if (cardType && !isValid) {
      formErrors.cardType = formatMessage({ id: 'unsupportedCardType' }, { cardType });
    }

    if (isValid) {
      formErrors.cardNumber = '';
    }

    state.cardNumber = sanitizedNumber;
    state.cardType = cardType ?? '';
    state.isCardNumberValid = isValid;
  } else if (name === FormFields.EXPIRY) {
    formErrors.expiry = '';
    state.expiry = value;
    state.isExpiryValid = validateExpiry(value);
  } else if (name === FormFields.BILLING_ZIP) {
    formErrors.billingZip = '';
    state.billingZip = value.toUpperCase();
  } else if (name === FormFields.ADDRESS_SAME_AS_DELIVERY) {
    state.billingAddressSameAsDelivery = !state.billingAddressSameAsDelivery;
    if (!state.billingAddressSameAsDelivery) {
      state.billingStreetAddress = '';
      state.billingApt = '';
      state.billingCity = '';
      state.billingState = '';
      state.billingZip = '';
    }
  } else {
    if (typeof state[name] === 'boolean') {
      state[name] = !state[name];
    } else {
      formErrors[name] = '';
      state[name] = value;
    }
  }
  return { state, formErrors };
};

const validateCardZipCode = (
  zipCode: string,
  country: string,
  formatMessage: IntlShape['formatMessage'],
  countryFieldEnabled: boolean
) => {
  let cardZipCodeError;
  const iso2 = getIso2({ iso3: country });
  const regex = countryFieldEnabled
    ? getZipCodeRegex(brand().toUpperCase(), iso2 ?? FALLBACK_ISO2)
    : getZipCodeRegex(brand().toUpperCase(), FALLBACK_ISO2);

  if (zipCode && country && !(regex ?? FALLBACK_ZIPCODE_REGEX).test(zipCode)) {
    cardZipCodeError = formatMessage({
      id: country === ISOs.USA ? 'zipCodeInvalid' : 'postalCodeInvalid',
    });
  }

  return cardZipCodeError;
};

export const getCCFormErrors = (
  state: IPaymentState | IAdyenPaymentState,
  formErrors: IPaymentErrors,
  formatMessage: IntlShape['formatMessage'],
  paymentFieldVariations: PaymentFieldVariations = defaultPaymentFieldVariation,
  country: ISOs,
  skipCCFields?: boolean // Skips checks for Adyen cc fields as Adyen handles field validation
): { hasErrors: boolean; formErrors: IPaymentErrors } => {
  if (!skipCCFields) {
    if (!state.isCardNumberValid) {
      formErrors.cardNumber = formatMessage({ id: 'ccNumberIsNotValid' });
    }
    if ((!state.cvv || state.cvv.trim().length < 3) && state.cardType !== CardTypes.AMEX) {
      formErrors.cvv = formatMessage({ id: 'cvvMustBeAtLeast3Digits' });
    }
    if ((!state.cvv || state.cvv.trim().length < 4) && state.cardType === CardTypes.AMEX) {
      formErrors.cvv = formatMessage({ id: 'cvvMustBeAtLeast4Digits' });
    }
    if (!state.isExpiryValid) {
      formErrors.expiry = formatMessage({ id: 'creditCardExpired' });
    }
  }

  if (paymentFieldVariations.name && !state.nameOnCard?.trim()) {
    formErrors.nameOnCard = formatMessage({ id: 'nameOnCardIsRequired' });
  }

  const cardNumberIsRequiredMessage = formatMessage({ id: 'cardNumberIsRequired' });
  if (!state.cardNumber.trim()) {
    formErrors.cardNumber = cardNumberIsRequiredMessage;
  } else if (formErrors.cardNumber === cardNumberIsRequiredMessage) {
    formErrors.cardNumber = '';
  }

  const cvvIsRequiredMessage = formatMessage({ id: 'cvvIsRequired' });
  if (paymentFieldVariations.cvv && !state.cvv?.trim()) {
    formErrors.cvv = cvvIsRequiredMessage;
  } else if (formErrors.cvv === cvvIsRequiredMessage) {
    formErrors.cvv = '';
  }

  const expirationDateIsRequiredMessage = formatMessage({ id: 'expirationDateIsRequired' });
  if (
    !state.expiry?.trim() &&
    !(state as IAdyenPaymentState).encryptedExpiryMonth &&
    !(state as IAdyenPaymentState).encryptedExpiryYear
  ) {
    formErrors.expiry = expirationDateIsRequiredMessage;
  } else if (formErrors.expiry === expirationDateIsRequiredMessage) {
    formErrors.expiry = '';
  }

  if (paymentFieldVariations.addressLine1 && !state.billingStreetAddress?.trim()) {
    formErrors.billingStreetAddress = formatMessage({ id: 'addressRequiredError' });
  }
  if (paymentFieldVariations.city && !state.billingCity?.trim()) {
    formErrors.billingCity = formatMessage({ id: 'cityRequiredError' });
  }
  if (paymentFieldVariations.state && !state.billingState?.trim()) {
    formErrors.billingState = formatMessage({ id: 'stateIsARequiredField' });
  }
  if (paymentFieldVariations.zip) {
    const billingZip = state.billingZip.trim();
    const countryToValidateBy = paymentFieldVariations.country ? state.billingCountry : country;
    // Validate zip code based on country if country input is displayed
    if (billingZip && countryToValidateBy) {
      const billingZipError = validateCardZipCode(
        state.billingZip,
        countryToValidateBy,
        formatMessage,
        paymentFieldVariations.country
      );
      if (billingZipError) {
        formErrors.billingZip = billingZipError;
      }
    }

    if (!billingZip) {
      formErrors.billingZip = formatMessage({
        id: countryToValidateBy === ISOs.USA ? 'zipCodeRequiredError' : 'postalCodeRequiredError',
      });
    }
  }
  if (paymentFieldVariations.country && !state.billingCountry) {
    formErrors.billingCountry = getBillingCountryError(formatMessage);
  }
  const hasErrors: boolean = Object.values(formErrors).some(err => err);

  return { hasErrors, formErrors };
};

export const initialPaymentState = ({
  isDelivery = false,
  deliveryAddress = {},
  billingCountry = DEFAULT_BILLING_COUNTRY,
  userDetailsName = '',
  enableSaveCard = true,
  enableRemoveCardholderNamePreFilled = false,
}: {
  isDelivery?: boolean;
  billingCountry?: ISOs;
  deliveryAddress?: Partial<IDeliveryAddress>;
  userDetailsName?: string;
  enableSaveCard?: boolean;
  enableRemoveCardholderNamePreFilled?: boolean;
} = {}): IPaymentState | IAdyenPaymentState | IVrPaymentState | IPayMarkState => {
  const cardName = userDetailsName ?? '';
  const nameOnCard = !enableRemoveCardholderNamePreFilled ? cardName : '';

  return {
    nameOnCard,
    cardNumber: '',
    isCardNumberValid: false,
    isExpiryValid: false,
    cardType: '',
    cvv: '',
    expiry: '',
    ...mapDeliveryToCCFormAddress(isDelivery ? deliveryAddress : {}),
    billingAddressSameAsDelivery: isDelivery,
    saveCard: enableSaveCard,
    billingCountry,
    blikCode: '',
  };
};

export const initialGiftCardPaymentState = (): IGiftCardPaymentState => ({
  cardNumber: '',
  isCardNumberValid: false,
  saveCard: true,
});

export const testCardPaymentState = ({
  isDelivery = false,
  paymentFieldVariations = defaultPaymentFieldVariation,
  enableSaveCard = true,
}: {
  isDelivery?: boolean;
  paymentFieldVariations?: PaymentFieldVariations;
  paymentProcessor?: string | null;
  enableSaveCard?: boolean;
} = {}): IPaymentState => {
  return {
    nameOnCard: 'John Smith',
    cardNumber: '4111111111111111',
    isCardNumberValid: true,
    isExpiryValid: true,
    ...mapDeliveryToCCFormAddress({
      addressLine1: '100 Universal City Plaza',
      addressLine2: '',
      city: 'Hollywood',
      state: 'CA',
      zip: '11747',
    }),
    cardType: 'VISA',
    cvv: paymentFieldVariations.cvv ? '123' : '',
    expiry: paymentFieldVariations.expiration ? '12/25' : '',
    billingCountry: paymentFieldVariations.country ? ISOs.US : null,
    billingAddressSameAsDelivery: isDelivery,
    saveCard: enableSaveCard,
  };
};

export const initialErrorState = (): IPaymentErrors => ({
  nameOnCard: '',
  cardNumber: '',
  cardType: '',
  cvv: '',
  expiry: '',
  billingStreetAddress: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
  billingCountry: '',
  billingApt: '',
  giftCardNumber: '',
  blik: '',
});

export const getFraudPreventionValues = ({
  cardNumber,
  nameOnCard,
  billingStreetAddress,
  billingApt,
  billingCity,
  billingState,
  billingZip,
  billingCountry,
  expiry,
}: IPaymentState) => {
  const ccBin: string | undefined = cardNumber ? cardNumber.slice(0, 6) : undefined;
  const ccLast4: string | undefined = cardNumber
    ? cardNumber.slice(-4, cardNumber.length)
    : undefined;

  return {
    billingAddress: {
      country: billingCountry,
      unitNumber: billingApt,
      locality: billingCity,
      postalCode: billingZip,
      region: billingState,
      streetAddress: billingStreetAddress,
    },
    fullName: nameOnCard,
    ccBin,
    ccLast4,
    expiry,
  };
};

export const hiddenFieldValueByPaymentProcessor = {
  ADYEN: 'NA',
  FIRST_DATA: null,
};

export const mapDeliveryToCCFormAddress = (
  deliveryAddress: Partial<IDeliveryAddress>
): CCFormAddress => {
  const {
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    state = '',
    zip = '',
  } = deliveryAddress || {};
  return {
    billingStreetAddress: addressLine1,
    billingApt: addressLine2,
    billingCity: city,
    billingState: state,
    billingZip: zip,
  };
};

/**
 * This function check whether the selected payment method needs to be re-vaulted
 */
export const shouldReVault = ({
  accountIdentifier,
  methods,
}: {
  accountIdentifier: string;
  methods: IPaymentMethod[];
}) => {
  // Get the clicked payment method object
  const clickedPaymentMethod = methods.find(method => {
    const selectedAccountIdentifier = method.accountIdentifier ?? method.fdAccountId ?? '';
    return selectedAccountIdentifier === accountIdentifier;
  });
  // Select the method if it's prepaid, apple pay, google pay or CC vaulted with Chase
  const isAppleOrGooglePay = isApplePay(accountIdentifier) || isGooglePay(accountIdentifier);
  return !(
    isAppleOrGooglePay ||
    clickedPaymentMethod?.prepaid ||
    clickedPaymentMethod?.chaseProfileId
  );
};

/**
 * This function check if the tip enter by the user is valid
 */
export const checkIfValidTip = ({
  tipAmount,
  subTotalCents,
}: {
  tipAmount?: number;
  subTotalCents?: number;
}) => {
  const isTipLessThanSubTotal = Number(tipAmount) <= Number(subTotalCents);
  const isTipLessThanMax = Number(tipAmount) <= TipAmounts.MAX_AMOUNT;
  return !!(isTipLessThanSubTotal && isTipLessThanMax);
};
/**
 * useful for excluding certain payment methods from being used to reload prepaid cards
 * @param {IPaymentMethpd} paymentMethod
 * @returns {boolean} whether this can be used for reloading a prepaid card
 */
export const isAllowedReloadMethod = (paymentMethod: IPaymentMethod) =>
  !paymentMethod.prepaid && !paymentMethod.cash;

export const extractPaymentTypeForLD = (paymentMethod: IPaymentMethod | undefined) => {
  const isApplePayPaymentType = paymentMethod?.fdAccountId === CartPaymentCardType.APPLE_PAY;
  const isGooglePayPaymentType = paymentMethod?.fdAccountId === CartPaymentCardType.GOOGLE_PAY;
  const isCreditCardPaymentType =
    !!paymentMethod?.credit && !isApplePayPaymentType && !isGooglePayPaymentType;
  const isCashPaymentPaymentType = paymentMethod?.accountIdentifier === 'CASH';
  let ldPaymentType;
  if (isCashPaymentPaymentType) {
    ldPaymentType = CartPaymentCardType.CASH;
  } else if (isCreditCardPaymentType) {
    ldPaymentType = CartPaymentCardType.CREDIT;
  } else if (isApplePayPaymentType) {
    ldPaymentType = CartPaymentCardType.APPLE_PAY;
  } else if (isGooglePayPaymentType) {
    ldPaymentType = CartPaymentCardType.GOOGLE_PAY;
  }
  return ldPaymentType;
};

export const mapPaymentMethodToLoyaltyPaymentMethods = (
  paymentMethod?: IPaymentMethod | null
): PaymentMethod | null => {
  if (!paymentMethod) {
    return null;
  }

  let backendPaymentMethod = null;
  const paymentType = extractPaymentTypeForLD(paymentMethod);

  if (paymentType) {
    if (paymentType === CartPaymentCardType.CREDIT && paymentMethod.credit) {
      backendPaymentMethod = PaymentMethod[paymentMethod.credit.cardType];
    } else {
      backendPaymentMethod = PaymentMethod[paymentType];
    }
  }

  return backendPaymentMethod ?? null;
};
