import React from 'react';

import { getConfigValue, isWeb } from 'utils/environment';

import { CookiebotBanner } from './cookiebot-cookie-banner';
import { CookieBannerContainer as DefaultCookieBannerContainer } from './default-cookie-banner';
import { OneTrustCookieBanner } from './one-trust-cookie-banner';
import { CookieBannerType } from './types';

const banners: Record<CookieBannerType, React.FC> = {
  [CookieBannerType.RBI_COOKIE_BANNER]: DefaultCookieBannerContainer,
  [CookieBannerType.ONE_TRUST_COOKIE_BANNER]: OneTrustCookieBanner,
  [CookieBannerType.COOKIEBOT_COOKIE_BANNER]: CookiebotBanner,
};

interface ICookieBannerProps {
  hasUserDeniedTracking: boolean;
}

export const CookieBanner = (props: ICookieBannerProps) => {
  const { cookieBanner }: { cookieBanner: string } = getConfigValue('cookieBanners');
  const isConfigured = Boolean(cookieBanner);

  if (!isWeb || !isConfigured || props.hasUserDeniedTracking) {
    return null;
  }

  const ConfiguredCookieBanner =
    banners[cookieBanner as CookieBannerType] || DefaultCookieBannerContainer;

  return <ConfiguredCookieBanner />;
};
