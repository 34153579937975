import styled from 'styled-components';

const NavContainer = styled.nav<{ reversed: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  background: ${p =>
    p.reversed
      ? p.theme.token('background-hero-reversed')
      : p.theme.token('background-hero-light')};
  color: #1f1f1f;
  height: ${Styles.layout.navHeight.desktop};
  margin-block: 0;
  margin-inline: auto;
  padding: 0 30px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
`;

export default NavContainer;
