import { UAParser } from 'ua-parser-js';

import { Stage } from 'generated/rbi-graphql';

import { CONFIGURATION as EMBEDDED_CONFIGURATION } from './__generated-configuration';
import { apiKeyLogger } from './api-key-logger';
import {
  GraphQLEnv,
  RBIBrand,
  RBIEnv,
  RBIExpandedPlatform,
  RBIFullBrandName,
  RBIPlatform,
} from './types';

/**
 * To speed up the build process, we build the app with an arbitrary config,
 * and then we separately build the correct configuration and include it as the
 * first script tag. because it's outside of the webpack babel build env,
 * we can only set it on the window, as a global variable
 * From here, we pick it up and use it as our configuration
 */
let CONFIGURATION: any;
if (typeof window !== 'undefined') {
  // @ts-ignore
  CONFIGURATION = window?.__RBI_CONFIGURATION || EMBEDDED_CONFIGURATION;
} else {
  CONFIGURATION = EMBEDDED_CONFIGURATION;
}
const brandToFullBrandName = {
  [RBIBrand.PLK]: RBIFullBrandName.Popeyes,
  [RBIBrand.BK]: RBIFullBrandName.BurgerKing,
  [RBIBrand.FHS]: RBIFullBrandName.FirehouseSubs,
};

export const getCountry = () => CONFIGURATION.country;
export const brand = () => CONFIGURATION.brand as RBIBrand;
export const env = () => (CONFIGURATION.env || RBIEnv.DEV) as RBIEnv;
export const platform = () => CONFIGURATION.platform as RBIPlatform;
export const checkPlatform = (is: RBIPlatform) => is === platform();
export const checkRbiEnv = (is: RBIEnv) => is === env();
export const appVersionCode = () => CONFIGURATION.commitRef || 'dev';
export const graphqlEnv = () => (CONFIGURATION.graphqlEnv || env()) as GraphQLEnv;
export const graphqlGatewayEnv = () => (CONFIGURATION.graphqlGatewayEnv || env()) as GraphQLEnv;
export const sanityEnv = () => (CONFIGURATION.sanityEnv || env()) as RBIEnv;
export const welcomeEmailDomain = () =>
  (process.env.REACT_APP_RBI_WELCOME_EMAIL_DOMAIN || env()) as Stage;
export const fullBrandName = () => brandToFullBrandName[brand()];
export const dateFormat = () => CONFIGURATION.dateFormat || 'dd/MM/yyyy';
export const timeFormat = () => CONFIGURATION.timeFormat || 'HH:MM AM/PM';
type RBIReleaseTimestamp = string;
export const releaseTagRef = CONFIGURATION.releaseTagRef || 'latest';
export const releaseTimestamp = () => CONFIGURATION.releaseTimestamp as RBIReleaseTimestamp;
export const country = getCountry();
// Our Dataset is always of the form ENV_BRAND except in test ENV where we always use 'automation'
export const sanityDataset = CONFIGURATION.sanityDataset || `${sanityEnv()}_${brand()}`;

export { RBIBrand, RBIEnv, RBIPlatform, RBIExpandedPlatform, GraphQLEnv };

export const googleTagManagerScriptId = () =>
  `rbi-${brand()}-${country}-${env()}-google-tag-manager`;

export const isLocalDev = process.env.NODE_ENV !== 'production';
export const isStoryBook = process.env.STORYBOOK === 'true';
export const isTest = process.env.NODE_ENV === 'test';
export const isLowerEnv = checkRbiEnv(RBIEnv.DEV) || checkRbiEnv(RBIEnv.STAGING);
export const isProduction = checkRbiEnv(RBIEnv.PROD);
export const isWeb = checkPlatform(RBIPlatform.WEB);
export const isNative = checkPlatform(RBIPlatform.APP);
export const isKiosk = checkPlatform(RBIPlatform.KIOSK);
export const isMobile = () =>
  ['mobile', 'tablet'].includes(UAParser(navigator.userAgent).device.type ?? '');

export const deviceInfo = (): 'android' | 'ios' | 'web' => {
  const ua = UAParser();
  const os = ua.os;

  if (os.name?.includes('Android')) {
    return 'android';
  } else if (os.name?.includes('iOS')) {
    return 'ios';
  }
  return 'web';
};

const platformOs = deviceInfo();

export const isIOS = () => {
  if (platformOs === 'ios') {
    return true;
  }
  if (platformOs === 'android') {
    return false;
  }

  return (
    new Set(['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']).has(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
export const isNativeIOS = () => isNative && isIOS();
export const isNativeAndroid = () => isNative && platformOs === 'android';

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const versionIsGreaterThanOrEqualTo = ({
  versionString,
  majorVersionGreaterThanOrEqualTo,
}: {
  versionString: string | null;
  majorVersionGreaterThanOrEqualTo: number;
}): boolean => {
  if (!versionString) {
    return false;
  }
  const majorVersion = Number(versionString.split('.')[0]);
  return majorVersion >= majorVersionGreaterThanOrEqualTo;
};

export const getConfigValue = <K extends keyof typeof CONFIGURATION>(key: K) => {
  return CONFIGURATION[key];
};

type ApiKeys = NonNullable<(typeof CONFIGURATION)['apiKeys']>;

// Initiate an empty dictionary to prevent duplicates DD logs of missing api key
let missingApiKeys = {};

export const getApiKey = <K extends keyof ApiKeys>(key: K, fallback = '') => {
  const keys = getConfigValue('apiKeys');
  // Make sure we haven't already log the missing API keys
  if ((!keys || !keys[key]) && !missingApiKeys[String(key)]) {
    missingApiKeys[String(key)] = true;
    const error = `Missing API key in the Sanity FE configs. API Key: ${String(key)}, Fallback: ${
      fallback === '' ? 'None' : `"${fallback}"`
    }.`;
    const ctx = {
      appVersionCode: appVersionCode(),
      brand: brand(),
      platform: platform(),
      stage: env(),
      reason: 'front end configuration missing an API key',
    };

    apiKeyLogger(error, ctx);

    return fallback;
  }
  return (keys && keys[key]) ?? fallback;
};

// This is only for testing purpose.
export const resetMissingApiKeysForTestOnly = () => (missingApiKeys = {});

export const getCustomerIdForCRMStack = (cognitoId?: string, thLegacyCognitoId?: string) => {
  if ([RBIBrand.BK, RBIBrand.PLK].includes(brand())) {
    return thLegacyCognitoId ? thLegacyCognitoId : cognitoId;
  }
  return thLegacyCognitoId ? `us-east-1:${thLegacyCognitoId}` : cognitoId;
};
