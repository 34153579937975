import { useCallback, useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import { ILocation } from '@rbi-ctg/frontend';
import {
  FilterRestaurantType,
  IRestaurantsInput,
  OperationalStatus,
  useGetRestaurantsLazyQuery,
} from 'generated/rbi-graphql';
import useAuth from 'hooks/auth/use-auth';
import { useFavoriteStores } from 'hooks/favorite-stores';
import useDialogModal from 'hooks/use-dialog-modal';
import useErrorModal from 'hooks/use-error-modal';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { isValidPosition } from 'utils/geolocation';
import DEFAULT_SEARCH_RADIUS_M from 'utils/restaurant/default-search-radius-meters';

import {
  StoreAction,
  searchFavRequested,
  searchFavsError,
  searchFavsSuccess,
  searchNearbyError,
  searchNearbyRequested,
  searchNearbySuccess,
  searchRecentError,
  searchRecentRequested,
  searchRecentSuccess,
} from '../ducks/stores';

import { ISearchNearbyRestaurants } from './types';

interface ISearchStoresProps {
  storeLocatorDispatch: StoreAction;
}

const useRestaurants = (filter: FilterRestaurantType) => {
  const [fetchRestaurants, { data, loading, error }] = useGetRestaurantsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const enableListRestaurantsInParallel = useFlag(
    LaunchDarklyFlag.ENABLE_LIST_RESTAURANTS_IN_PARALLEL
  );

  const fetch = useCallback(
    (input: IRestaurantsInput = {}) => {
      fetchRestaurants({
        variables: { input: { filter, ...input, parallelFlag: !!enableListRestaurantsInParallel } },
      });
    },
    [fetchRestaurants, filter, enableListRestaurantsInParallel]
  );

  return { fetch, data, loading, error };
};

const serviceModesMap: { [key in ServiceMode]?: Array<ServiceMode> } = {
  [ServiceMode.CATERING_DELIVERY]: [ServiceMode.CATERING_DELIVERY],
  [ServiceMode.CATERING_PICKUP]: [ServiceMode.CATERING_PICKUP],
};

export default function useSearchStores({ storeLocatorDispatch }: ISearchStoresProps) {
  const { isAuthenticated } = useAuth();
  const [activeStoreId, setActiveStoreId] = useState('');
  const defaultDiagonal =
    useFlag(LaunchDarklyFlag.RADIUS_FOR_STORE_LOCATOR) || DEFAULT_SEARCH_RADIUS_M;
  const { serviceMode } = useServiceModeContext();
  const serviceModes = useMemo(() => {
    return serviceMode ? serviceModesMap[serviceMode] : undefined;
  }, [serviceMode]);

  const { validFavStores } = useFavoriteStores();
  const { formatMessage } = useIntl();
  const [Dialog, openDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Notify user to enable location services',
  });

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Fetching Restaurants Error',
  });

  // @todo: refactor use-store-locator logic reducer to handle this
  // without the need for this helper function that repeats these 3 calls/effects
  const { fetch: fetchNearbyRestaurants, ...nearbyRestaurants } = useRestaurants(
    FilterRestaurantType.NEARBY
  );
  const { fetch: fetchRecentRestaurants, ...recentRestaurants } = useRestaurants(
    FilterRestaurantType.RECENT
  );
  const { fetch: fetchFavoriteRestaurants, ...favoriteRestaurants } = useRestaurants(
    FilterRestaurantType.FAVORITE
  );

  const searchRecentRestaurants = useCallback(
    (location: ILocation | null) => {
      if (!isAuthenticated()) {
        storeLocatorDispatch(searchRecentSuccess([]));
        return;
      }

      if (location) {
        fetchRecentRestaurants({
          coordinates: {
            userLat: location.lat,
            userLng: location.lng,
          },
          serviceModes,
        });
      } else {
        fetchRecentRestaurants({
          serviceModes,
        });
      }
    },
    [fetchRecentRestaurants, isAuthenticated, serviceModes, storeLocatorDispatch]
  );

  const searchFavRestaurants = useCallback(
    (location: ILocation | null) => {
      if (!isAuthenticated()) {
        storeLocatorDispatch(searchFavsSuccess([]));
        return;
      }

      if (location) {
        fetchFavoriteRestaurants({
          coordinates: {
            userLat: location.lat,
            userLng: location.lng,
          },
          serviceModes,
        });
      } else {
        fetchFavoriteRestaurants({
          serviceModes,
        });
      }
    },
    // Using `validFavStores` to recreate this callback. When this callback
    // is recreated we trigger a search which at the time of this writing
    // looked like in `use-store-locator`:
    // useEffect(() => {
    //   searchFavRestaurants(activeCoordinates);
    // }, [activeCoordinates, searchFavRestaurants]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchFavoriteRestaurants, isAuthenticated, storeLocatorDispatch, serviceModes, validFavStores]
  );

  const searchNearbyRestaurants = useCallback(
    ({ location }: ISearchNearbyRestaurants) => {
      if (!isValidPosition(location)) {
        openDialog({
          message: formatMessage({ id: 'messageAllowLocation' }),
          title: formatMessage({ id: 'titleAllowLocation' }),
        });
        return;
      }

      fetchNearbyRestaurants({
        coordinates: {
          userLat: location.lat,
          userLng: location.lng,
          searchRadius: defaultDiagonal,
        },
        first: 20,
        status: OperationalStatus.OPEN,
        serviceModes,
      });
    },
    [defaultDiagonal, fetchNearbyRestaurants, formatMessage, openDialog, serviceModes]
  );

  useEffect(() => {
    if (nearbyRestaurants.error) {
      storeLocatorDispatch(searchNearbyError(nearbyRestaurants.error));
      openErrorDialog({
        message: formatMessage({ id: 'errorRetrievingRestaurants' }),
        error: nearbyRestaurants.error,
      });
      return;
    }

    if (nearbyRestaurants.loading) {
      storeLocatorDispatch(searchNearbyRequested());
      return;
    }

    const stores = nearbyRestaurants.data?.restaurants?.nodes || [];
    storeLocatorDispatch(searchNearbySuccess(stores));
  }, [
    formatMessage,
    nearbyRestaurants.data,
    nearbyRestaurants.error,
    nearbyRestaurants.loading,
    openErrorDialog,
    storeLocatorDispatch,
  ]);

  useEffect(() => {
    if (recentRestaurants.error) {
      storeLocatorDispatch(searchRecentError(recentRestaurants.error));
      return;
    }

    if (recentRestaurants.loading) {
      storeLocatorDispatch(searchRecentRequested());
      return;
    }

    const stores = recentRestaurants.data?.restaurants?.nodes || [];
    storeLocatorDispatch(searchRecentSuccess(stores));
  }, [
    formatMessage,
    openErrorDialog,
    recentRestaurants.data,
    recentRestaurants.error,
    recentRestaurants.loading,
    storeLocatorDispatch,
  ]);

  useEffect(() => {
    if (favoriteRestaurants.error) {
      storeLocatorDispatch(searchFavsError(favoriteRestaurants.error));
      return;
    }

    if (favoriteRestaurants.loading) {
      storeLocatorDispatch(searchFavRequested());
      return;
    }

    const stores = favoriteRestaurants.data?.restaurants?.nodes || [];
    storeLocatorDispatch(searchFavsSuccess(stores));
  }, [
    favoriteRestaurants.data,
    favoriteRestaurants.error,
    favoriteRestaurants.loading,
    formatMessage,
    openErrorDialog,
    storeLocatorDispatch,
  ]);

  return {
    activeStoreId,
    ErrorDialog,
    Dialog,
    searchNearbyRestaurants,
    setActiveStoreId,
    searchFavRestaurants,
    searchRecentRestaurants,
  };
}
