import React, { useCallback, useMemo } from 'react';

import delve from 'dlv';
import { omit } from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

const AboutMenuEntriesContainer = styled.div<IListProps>`
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  & > div > div + div {
    border-block-start: 1px solid ${(p: IListProps) => p.borderColor || Styles.color.grey.five};
  }
  & > div > div > ul {
    list-style: none;
    padding: 1rem 0;
    margin: 0;
  }
  & > div > div > h3 + ul {
    padding: 0;
  }
  & > div > div > ul > li {
    margin: 0.5rem 0;
    padding: 0;
  }
  & > div > div > ul > li > a {
    text-decoration: none;
    color: ${Styles.color.black};
    &:hover,
    &:focus {
      border-block-end: 2px solid ${Styles.color.black};
    }
  }
  & > div > div > h3 {
    font-family: ${Styles.fontFamily.brand};
    font-weight: ${Styles.fontWeight.normal};
    text-transform: ${Styles.textTransform.headlines};
    font-size: 1rem;
    margin-block-start: 1rem;
    margin-block-end: 0;
  }
`;

export interface SingleNode {
  title: string;
  path: string;
}

export interface ArrayNode {
  name: { locale: string };
  pages: [SingleNode];
}

export interface IListProps {
  title: string;
  entries: [SingleNode | ArrayNode];
  onDismiss?: VoidFunction;
  borderColor?: string;
  sectionHeadings?: boolean;
  navigate: (to: string) => void;
}

const isAbsoluteUrl = (url: string) => url.startsWith('http') || url.startsWith('//');

const getPaths = (nodes: [SingleNode | ArrayNode]): any => {
  if (!nodes) {
    return [];
  }

  return nodes.map((node: SingleNode | ArrayNode) => {
    const title = delve(node, 'localeTitle.locale') || delve(node, 'title') || '';
    const path = delve(node, 'localePath.locale.current') || delve(node, 'path.current') || '';

    if ((node as SingleNode).path) {
      return {
        title,
        path: delve(node, 'redirectUrl.locale') || path,
      };
    }
    return {
      title: (node as ArrayNode).name?.locale,
      pages: getPaths((node as ArrayNode).pages),
    };
  });
};

const createLinkClickHandler =
  ({ path, onDismiss }: SingleNode & IListProps) =>
  () => {
    if (!isAbsoluteUrl(path)) {
      onDismiss?.();
    }
  };

const ListOfLinks = (props: IListProps) => {
  const enableNutritionExplorerLink = useFlag(LaunchDarklyFlag.ENABLE_NUTRITION_EXPLORER);

  const filterMenuPaths = useCallback(
    (entries: (SingleNode | ArrayNode)[]): (SingleNode | ArrayNode)[] => {
      return entries.filter((entry: SingleNode | ArrayNode) => {
        if (Array.isArray((entry as ArrayNode).pages)) {
          return filterMenuPaths((entry as ArrayNode).pages);
        }
        return ((entry as SingleNode).path || '').includes(routes.nutritionExplorer.slice(1))
          ? enableNutritionExplorerLink
          : true;
      });
    },
    [enableNutritionExplorerLink]
  );

  const paths = useMemo(
    () => filterMenuPaths(getPaths(props.entries)),
    [props.entries, filterMenuPaths]
  );

  const renderArray = (arr: [SingleNode], containerProps: IListProps) =>
    arr.map((item: SingleNode) => renderItem(item, containerProps));

  const renderItem = (item: SingleNode, containerProps: IListProps) => {
    // Fix relative paths not having a leading slash
    const node =
      isAbsoluteUrl(item.path) || item.path.startsWith('/')
        ? item
        : { ...item, path: `/${item.path}` };

    return (
      <li key={`${node.title}: ${node.path}`}>
        <Link to={node.path} onClick={createLinkClickHandler({ ...containerProps, ...node })}>
          {node.title}
        </Link>
      </li>
    );
  };

  return (
    <AboutMenuEntriesContainer {...omit(props, 'onDismiss')}>
      <div>
        {paths.map((entry: any, i: number) => {
          const isSection = Array.isArray(entry.pages);
          return (
            <div key={`path-group-${i}`}>
              {isSection && props.sectionHeadings && <h3>{entry.title}</h3>}
              <ul>{isSection ? renderArray(entry.pages, props) : renderItem(entry, props)}</ul>
            </div>
          );
        })}
      </div>
    </AboutMenuEntriesContainer>
  );
};

export default ListOfLinks;
