export enum POSVendor {
  CONNECTOR = 'CONNECTOR',
  NCR = 'NCR',
  QST = 'QST',
  SICOM = 'SICOM',
  PRODUCT_NUMBER = 'PRODUCT_NUMBER',
  QDI = 'QDI',
  RPOS = 'RPOS',
  TABLET = 'tablet',
}
