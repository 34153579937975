// This mParticleAdapter is used as the adapter layer to talk to the mParticle web SDK
// Usage of window.mParticle should be strongly discouraged outside of this file

import { ProductActionType } from '@mparticle/web-sdk';
import { isArray, isEqual, isPlainObject } from 'lodash';

import { EventTypes, ProductActionTypes, SKIP_EVENTS } from '../constants';
import {
  ICdpAdapter,
  ICdpCallback,
  ICdpIdentityAdapter,
  ICdpIdentityApiData,
  ICdpProduct,
} from '../types';

class MParticleIdentityAdapterWeb implements ICdpIdentityAdapter {
  static modify(identityApiData: ICdpIdentityApiData, callback: ICdpCallback) {
    return window.mParticle.Identity.modify(identityApiData, callback);
  }
}

class MParticleAdapterWeb implements ICdpAdapter {
  prevEventName: string;
  prevEventAttr: object | undefined;
  prevCustomFlags: object | undefined;
  prevEventTimeLimit: number;

  createProduct(name: string, sku: string, price: number, quantity: number) {
    return window.mParticle.eCommerce.createProduct(name, sku, price, quantity);
  }

  logEvent(name: string, eventTypes: EventTypes, attributes?: object, customFlags?: object) {
    if (this.skipEvent(name, attributes, customFlags)) {
      return;
    }
    this.updatePrevEvent(name, attributes, customFlags);

    if (customFlags) {
      window.mParticle.logEvent(name, eventTypes, attributes, customFlags);
    } else {
      window.mParticle.logEvent(name, eventTypes, attributes);
    }
  }

  logProductAction(
    type: ProductActionTypes | ProductActionType,
    products: ICdpProduct[],
    attrs?: object | null,
    flags?: object | null,
    transactionalAttrs?: object
  ) {
    window.mParticle.eCommerce.logProductAction(type, products, attrs, flags, transactionalAttrs);
  }

  getDeviceId() {
    return window.mParticle?.getDeviceId?.();
  }

  getSession(): Promise<{ sessionId: string }> {
    const sessionId = window.mParticle.sessionManager.getSession();
    return Promise.resolve({ sessionId });
  }

  /**
   * Compares the current event with the previous one.
   * @param name
   * @param attributes
   * @param customFlags
   * @returns If it is the same event or not.
   */
  sameEvent(name: string, attributes?: object, customFlags?: object) {
    if (this.prevEventName !== name) {
      return false;
    } else if (
      this.sameObjects(attributes, this.prevEventAttr) &&
      this.sameObjects(customFlags, this.prevCustomFlags)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Compares the current object with the previous one. It skips the attribute validation for time related ones.
   * @param currObject
   * @param prevObject
   * @returns If it is the same object or not.
   */
  sameObjects(currObject?: object, prevObject?: object) {
    if (typeof prevObject !== typeof currObject) {
      return false;
    } else if (!prevObject || !currObject) {
      return true;
    }
    const keys: Array<string> = Object.keys(prevObject);
    for (const key of keys) {
      if (
        (typeof prevObject[key] === 'string' && /^\d{2}:\d{2}:\d{2}$/.test(prevObject[key])) ||
        isArray(prevObject[key]) ||
        isPlainObject(prevObject[key])
      ) {
        continue;
      } else if (!currObject.hasOwnProperty(key) || !isEqual(prevObject[key], currObject[key])) {
        return false;
      }
    }
    return true;
  }

  setOptOut(optOut: boolean) {
    window.mParticle.setOptOut(optOut);
  }

  /**
   * Validates if the current event needs to be skipped.
   * @param name
   * @param attributes
   * @param customFlags
   * @returns If the event should be skipped or not.
   */
  skipEvent(name: string, attributes?: object, customFlags?: object) {
    if (SKIP_EVENTS.indexOf(name) === -1) {
      return false;
    }
    const isSameEvent: boolean = this.sameEvent(name, attributes, customFlags);
    if (isSameEvent) {
      if (new Date().getTime() > this.prevEventTimeLimit) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Updates the previous event data.
   * @param name
   * @param attributes
   */
  updatePrevEvent(name: string, attributes?: object, customFlags?: object) {
    this.prevEventName = name;
    this.prevEventAttr = attributes;
    this.prevCustomFlags = customFlags;
    this.prevEventTimeLimit = new Date().getTime() + 4000;
  }

  Identity = MParticleIdentityAdapterWeb;
}

const MParticleAdapter = new MParticleAdapterWeb();

export { MParticleAdapter };
