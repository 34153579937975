import React from 'react';

import {
  BottomDrawerBody,
  BottomDrawerHeader,
} from '@rbilabs/components-library/build/components/bottom-drawer/bottom-drawer.styled';
import { Box } from '@rbilabs/components-library/build/components/layout/Box';
import styled from 'styled-components';

import Modal from 'components/modal';

interface UpsellDialogProps {
  title: string;
  open: boolean;
  onCloseModal: VoidFunction;
  children: NonNullable<React.ReactNode>;
}

const StyledModal = styled(Modal)`
  &[data-reach-dialog-content] {
    background-color: ${Styles.color.dialogBackground};

    > button {
      background-color: transparent;
    }

    ${Styles.desktop} {
      min-width: 35vw;
      width: 35vw;
    }

    ${Styles.mobile} {
      height: 90vh;
      inset: unset;
      inset-block-end: 0;

      > button {
        position: absolute;
        inset-block-start: 0.75rem;
        inset-inline-start: 0.5rem;
      }
    }
  }
`;

const StyledBottomDrawerHeader = styled(BottomDrawerHeader)`
  background-color: ${Styles.color.dialogBackground};
`;

export const UpsellDialog: React.FC<UpsellDialogProps> = ({
  open,
  title,
  children,
  onCloseModal,
}) => {
  return open ? (
    <StyledModal onDismiss={onCloseModal}>
      <Box width="100%" flexDirection="column">
        <StyledBottomDrawerHeader>{title}</StyledBottomDrawerHeader>
        <BottomDrawerBody>{children}</BottomDrawerBody>
      </Box>
    </StyledModal>
  ) : null;
};
