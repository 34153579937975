import React, { FC, FocusEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import Currency from 'components/currency';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import Offer from 'pages/cart/your-cart/offer';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isNative } from 'utils/environment';
import { routes } from 'utils/routing';

import CartPreviewEntry from './cart-preview-entry';
import defaultConfig from './config';
import { CHECKOUT_BUTTON_PADDING } from './constants';
import LimitMessage from './limit-message';
import MinimumMessage from './minimum-message';
import { ButtonContainer, ButtonContainerShadow } from './styled/button-container';
import { CartHoverContainer, CartScrollContainer, Row } from './styled/cart-preview-components';
import { CartPreviewContainer } from './styled/cart-preview-container';
import { EmptyCartMessage } from './styled/empty-cart-message';
import { MessageContainer } from './styled/message-container';
import { TotalsContainer } from './styled/totals-container';
import { ICartPreviewProps } from './types';

export const CartPreview: FC<ICartPreviewProps> = ({
  id,
  calculateCartTotal,
  checkoutPriceLimit,
  discountOffers,
  isStoreOpenAndAvailable,
  openOfferCheckoutConfirmationModal,
  canUserCheckout,
  isCartTotalGreaterThanDiscountOfferValue,
  isCartEmpty,
  cartPreviewEntries,
  cartEntries,
  navigate,
  loyaltyEnabled,
  appliedLoyaltyRewards,
  appliedLoyaltyRewardWithDiscountOffer,
  loyaltyRewardsMap,
  hideCartPreview,
  cartButton,
}) => {
  const { formatMessage } = useIntl();
  const hideCheckoutLimitMessage = useFlag(LaunchDarklyFlag.HIDE_CHECKOUT_LIMIT_MESSAGE);

  const [boxShadow, setBoxShadow] = useState(false);
  const scrollEl = useRef<HTMLDivElement | null>(null);
  const cartPreviewRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(cartPreviewRef, hideCartPreview, cartButton);

  const shouldShowBoxShadow = useCallback(() => {
    if (scrollEl.current) {
      const scrollElTop = scrollEl.current.scrollTop;
      const scrollElHeight = scrollEl.current.offsetHeight;
      const firstChild = scrollEl.current.childNodes[0] as HTMLElement;
      const childHeight = firstChild ? firstChild.offsetHeight : 0;
      return childHeight && childHeight > scrollElTop + scrollElHeight;
    }
    return false;
  }, []);

  //TODO ICFE-518 - replace js for showing / hiding box shadow with plain css
  const handleScroll = useCallback(() => {
    const showShadow = !!shouldShowBoxShadow();
    setBoxShadow(showShadow);
  }, [shouldShowBoxShadow]);

  useEffect(() => {
    if (scrollEl.current && cartEntries.length) {
      handleScroll();
    }
  }, [cartEntries, handleScroll]);

  const handleCheckout = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();

    if (!isStoreOpenAndAvailable) {
      navigate(routes.storeLocator);
      return;
    }

    // Open the checkout confirmation modal in a native environment
    // and when the cart total is greater than a certain value
    if (isNative && isCartTotalGreaterThanDiscountOfferValue()) {
      openOfferCheckoutConfirmationModal();
      return;
    }

    navigate(routes.cart);
  };

  const handleOnBlur = (event: FocusEvent<HTMLDivElement>) => {
    const childElementIsFocused = event.currentTarget.contains(event.relatedTarget);
    if (!childElementIsFocused) {
      hideCartPreview();
    }
  };

  const total = calculateCartTotal();

  return createPortal(
    <CartHoverContainer
      id={id}
      data-testid="cart-preview"
      onBlur={handleOnBlur}
      ref={cartPreviewRef}
    >
      <CartScrollContainer ref={scrollEl} onScroll={handleScroll}>
        <CartPreviewContainer>
          {discountOffers?.map(discountOffer => (
            <Offer
              bgColor="#f4f5f5"
              fill={Styles.color.grey.five}
              key={discountOffer._id}
              borderColor="#b6b6b6"
              selectedOffer={discountOffer}
              isValid
            />
          ))}
          {appliedLoyaltyRewardWithDiscountOffer && loyaltyRewardsMap && (
            <CartPreviewEntry
              item={loyaltyRewardsMap[appliedLoyaltyRewardWithDiscountOffer.rewardBenefitId] as any}
              rewardApplied={true}
            />
          )}
          {cartPreviewEntries?.map(item => {
            return (
              <CartPreviewEntry
                key={item.cartId}
                item={item}
                rewardApplied={
                  loyaltyEnabled && appliedLoyaltyRewards[item.cartId]?.timesApplied > 0
                }
              />
            );
          })}
        </CartPreviewContainer>
      </CartScrollContainer>
      <ButtonContainer>
        <ButtonContainerShadow $boxShadow={boxShadow} />
        <TotalsContainer data-testid="cart-preview-total">
          {isCartEmpty && (
            <EmptyCartMessage>{formatMessage({ id: 'emptyCartMessage' })}</EmptyCartMessage>
          )}
          <Row>
            <p>
              <b>{formatMessage({ id: 'total' })}</b>
            </p>
            <p>
              <b>
                <Currency amount={total} />
              </b>
            </p>
          </Row>
        </TotalsContainer>
        <MessageContainer>
          {!hideCheckoutLimitMessage && (
            <LimitMessage limit={checkoutPriceLimit} totalAmount={total} />
          )}
          <MinimumMessage />
        </MessageContainer>
        <Box width="100%" padding={defaultConfig.isSquare ? '0' : CHECKOUT_BUTTON_PADDING}>
          <ActionButton
            fullWidth
            data-testid="checkout-action-button"
            onClick={handleCheckout}
            disabled={!canUserCheckout}
            isSquare={defaultConfig.isSquare}
          >
            {formatMessage({ id: 'checkout' })}
          </ActionButton>
        </Box>
      </ButtonContainer>
    </CartHoverContainer>,
    document.body
  );
};
