import styled from 'styled-components';

const NavMenuItem = styled.button<{ reversed: boolean }>`
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 1rem;
  color: ${p => (p.reversed ? p.theme.token('text-reversed') : p.theme.token('text-default'))};
  display: inline-flex;
  font-family: ${Styles.fontFamily.base};
  font-size: 1.125rem;
  height: 100%;
  justify-content: center;
  margin-inline-start: 1.35rem;
  padding: 0;
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: ${p => (p.reversed ? p.theme.token('text-default') : Styles.color.navLinkColor.active)};
  }
  &.active,
  &:active {
    color: ${Styles.color.navLinkColor.active};
  }
`;

export default NavMenuItem;
