import { primitive } from 'styles/constants/primitives';

const QR_CODE_SCALE = 7;

export const barcodeOptions = {
  margin: 1,
  scale: QR_CODE_SCALE,
  color: {
    dark: Styles.color.black,
    light: primitive.$white,
  },
};

export const QR_CODE_TEST_ID = 'loyalty-qr-code';
export const QR_CODE_SELECTOR = `[data-testid="${QR_CODE_TEST_ID}"]`;
