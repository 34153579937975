import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Currency from 'components/currency';
import Picture from 'components/picture';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useComposeDescription } from 'hooks/use-compose-description';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { selectors, useAppSelector } from 'state/global-state';
import { rewardIsCartDiscount } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { isDiscountReward } from 'state/loyalty/types';
import { isAppliedAtCheckout } from 'state/loyalty/utils';
import { priceForCartEntry } from 'utils/menu/price';

import { PriceAndQuantity } from '../cart-item/price-and-quantity';
import { RedeemReward } from '../cart-item/redeem-reward';

import CartPreviewActions, {
  CartPreviewEditButton,
  CartPreviewIncrementor,
  CartPreviewRemoveButton,
  CartPreviewTitle,
  CartPreviewVisuallyHidden,
} from './cart-preview-actions';
import { CartEntryQuantityText } from './styled/cart-entry-quantity-text';
import { CartPreviewEditContainer } from './styled/cart-preview-edit-container';
import { CartPreviewEntryContainer } from './styled/cart-preview-entry-container';
import { CartPreviewItem } from './styled/cart-preview-item';
import { ICartPreviewEntry } from './types';

const PictureWrap = styled.div`
  align-items: center;
  border: 1px solid ${Styles.color.grey.four};
  border-radius: ${Styles.borderRadius};
  display: flex;
  overflow: hidden;
  padding: 0 !important;
  width: 85px;
`;

const PriceWrapper = styled.div`
  font-family: ${Styles.fontFamily.base};
  font-size: 1rem;
  font-weight: ${Styles.fontWeight.heavy};
  margin: 0;
  text-align: end;
  grid-column: 3;

  & span {
    margin: 0;
  }
`;

const DetailsContainer = styled.div`
  & span {
    align-self: flex-start;
  }
`;

const Description = styled.div`
  color: ${Styles.color.black};
  font-size: 0.75rem;
  font-weight: ${Styles.fontWeight.normal};
  line-height: 16px;
  margin-block-start: 0;
  margin-block-end: 0.5rem;
  margin-inline: 0;
`;

const RedeemRewardWrapper = styled.div`
  grid-column-end: span 3;

  > div {
    margin-block-start: 1.25rem;
    margin-block-end: 0;
    margin-inline: 0;
  }
`;

const CartPreviewEntry = ({ item, rewardApplied = false }: ICartPreviewEntry) => {
  const { formatMessage } = useIntl();
  const description = useComposeDescription(item, {
    returnTypeArray: true,
  });
  const isExtra = item?.isExtra;
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { sanityRewardsMap } = useLoyaltyRewardsList();
  const { getAvailableRewardFromCartEntry } = useLoyaltyContext();
  const loyaltyRewardAvailable = getAvailableRewardFromCartEntry(item);
  const { vendor } = usePosVendor();
  const displayLoyaltyComponents = loyaltyEnabled;
  const isLoyaltyOffer = appliedOffers.some(lylOffer => lylOffer.cartId === item.cartId);
  const enableEditOfferAddedAtCheckout = useFlag(
    LaunchDarklyFlag.ENABLE_EDIT_OFFER_ADDED_AT_CHECKOUT
  );
  const isOfferEditable = isAppliedAtCheckout(appliedOffers) && enableEditOfferAddedAtCheckout;
  const isPreviewEditButtonEnabled = !isLoyaltyOffer || isOfferEditable;
  const price = priceForCartEntry(item);

  const appliedReward = appliedLoyaltyRewards?.[item.cartId || 'discount-offer'];
  const sanityReward = appliedReward?.sanityId ? sanityRewardsMap?.[appliedReward.sanityId] : null;
  const isRewardDiscount = isDiscountReward(sanityReward);
  const isRewardCartDiscount = isRewardDiscount
    ? rewardIsCartDiscount(sanityReward, vendor)
    : false;

  return (
    <CartPreviewEntryContainer>
      <CartPreviewItem>
        {item.image && (
          <PictureWrap>
            <Picture image={item.image} alt="" />
          </PictureWrap>
        )}
        <DetailsContainer>
          <CartPreviewTitle item={item} />
          <Description>
            {description.map((descriptionItem, index) => (
              <span key={`descriptionItem-${index}`}>
                {descriptionItem}
                <br />
              </span>
            ))}
          </Description>
        </DetailsContainer>
        <PriceWrapper>
          {displayLoyaltyComponents ? (
            <PriceAndQuantity item={item} rewardApplied={rewardApplied} />
          ) : (
            <Currency amount={price} />
          )}
        </PriceWrapper>
        {displayLoyaltyComponents && loyaltyRewardAvailable && !isExtra && !item.isDonation && (
          <RedeemRewardWrapper>
            <RedeemReward
              isRewardApplied={rewardApplied}
              item={item}
              reward={loyaltyRewardAvailable}
            />
          </RedeemRewardWrapper>
        )}
        {!item.isDonation && (
          <CartPreviewActions isReward={rewardApplied} item={item}>
            <CartPreviewEditContainer>
              {isPreviewEditButtonEnabled && <CartPreviewEditButton />}
              <CartPreviewRemoveButton isRewardCartDiscount={isRewardCartDiscount} />
            </CartPreviewEditContainer>
            {isLoyaltyOffer ? null : isExtra ? (
              <CartEntryQuantityText>{`${item.quantity}x`}</CartEntryQuantityText>
            ) : (
              <CartPreviewIncrementor />
            )}
            <CartPreviewVisuallyHidden />
          </CartPreviewActions>
        )}
      </CartPreviewItem>
      <VisuallyHidden role="alert">
        {formatMessage(
          { id: 'quantityUpdatedAlert' },
          {
            quantity: item.quantity,
            itemName: item.name,
          }
        )}
      </VisuallyHidden>
    </CartPreviewEntryContainer>
  );
};

export default CartPreviewEntry;
