import styled, { createGlobalStyle } from 'styled-components';

import ActionButton from 'components/action-button';
import Countdown from 'components/countdown';
import QRCode from 'components/qrcode';
import BlockRenderer from 'components/sanity-block-renderer';
import UnstyledButton from 'components/unstyled-button';
import { primitive } from 'styles/constants/primitives';

import { CONFIRM_MODAL_SELECTOR } from './constants/index';
import theme from './theme';

export const StyledCountdown = styled(Countdown)`
  font-weight: ${Styles.fontWeight.heavy};
`;

export const ModalInner = styled.div`
  background-color: ${Styles.color.background};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-block-start: env(safe-area-inset-top);
  padding-block-end: env(safe-area-inset-bottom);
  padding-inline-start: env(safe-area-inset-right);
  padding-inline-end: env(safe-area-inset-left);
`;

export const PictureHeading = styled.div`
  flex-grow: 0;
  height: auto;
`;

export const BodyContent = styled.div`
  flex-grow: 1;
  position: relative;
  color: ${theme.bodyContentColor};
  padding: 1rem;
  text-align: center;
`;

export const ShortCodeWrapper = styled.div`
  text-align: center;
  background-color: ${Styles.color.black};
  border-radius: 10px;
  box-shadow: ${Styles.boxShadow};
  color: white;
  display: inline-grid;
  font-family: ${Styles.fontFamily.base};
  font-size: 1rem;
  letter-spacing: 3px;
  padding: 0.2rem;
  line-height: 1.2;
`;

export const ShortCode = styled.div<{ qrcode: boolean }>`
  padding: 0.3rem;
  font-size: ${props => (props.qrcode ? '1rem' : '2rem')};
`;
export const CouponTitle = styled.div`
  color: ${Styles.color.black};
  margin-block-start: 0.5rem;
  margin-block-end: 0.3rem;
  margin-inline: 0;
`;

export const Name = styled(BlockRenderer)<{ isPriceVisible?: boolean }>`
  text-align: center;
  text-transform: uppercase;
  line-height: 1.1;
  font-size: 2.25rem;
  ${Styles.desktop} {
    font-size: 2rem;
  }
  & p {
    font-family: ${Styles.fontFamily.base};
  }
`;

export const Description = styled(BlockRenderer)`
  text-align: center;
  color: ${Styles.color.black};
`;

export const DescriptionWrapper = styled.div`
  text-align: center;
`;

export const Directions = styled.p`
  margin-block-start: 0;
  text-align: center;
`;

export const Img = styled.img`
  display: block;
  margin: 1rem auto;
`;

export const RedeemInNext = styled.p`
  font-weight: ${Styles.fontWeight.heavy};
  color: ${Styles.color.black};
  margin-block-end: 0.2rem;
`;

export const UseCodeAtCashier = styled.p`
  margin-block-start: 1rem;
  margin-block-end: 0.4rem;
  margin-inline: 0;
  color: ${Styles.color.black};
  font-weight: ${Styles.fontWeight.heavy};
  font-size: 0.9rem;
`;

export const QRCodeContainer = styled.div`
  border-radius: 10px;
  background: white;
  padding: 2px;
  width: fit-content;
  margin: 0 auto;
`;

export const ModalConfirmStyle = createGlobalStyle`
  ${CONFIRM_MODAL_SELECTOR} > div > * {
    text-align: start;
  }
`;

export const ModalHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  box-shadow: 0 1px 1px 0 #0000001a;
`;

export const BackButton = styled(UnstyledButton)`
  position: absolute;
  inset-inline-start: 1rem;
  cursor: pointer;
`;

export const ModalHeadingTitle = styled.p`
  font-size: 1rem;
  font-weight: 700;
`;

export const StaticContainer = styled.div`
  margin-block-start: 0.5rem;

  ${Styles.desktop} {
    margin: 0.5rem auto;
    max-width: 36rem;
  }
`;

export const Header = styled.div`
  background: ${Styles.color.contrastBackground};
  padding: 0.75rem 1rem;
  border-start-start-radius: 1rem;
  border-start-end-radius: 1rem;
  border-end-end-radius: 0;
  border-end-start-radius: 0;

  .countdown {
    display: block;
    color: white;
    font-family: ${Styles.fontFamily.base};
    font-weight: normal;
    font-size: 1.5rem;
    height: 1.75rem;
  }
`;

export const StaticRedeem = styled.div`
  font-weight: ${Styles.fontWeight.normal};
  color: ${primitive.$white};
  margin-block-end: 0.2rem;
`;

export const Content = styled.div`
  background-color: ${primitive.$white};
`;

export const QrCodeHeader = styled.div``;

export const Title = styled.div`
  font-family: ${Styles.fontFamily.base};
  color: ${Styles.color.contrastBackground};
  font-weight: normal;
  font-size: 1.125rem;
  padding-block-start: 1rem;
`;

export const BarCode = styled(QRCode)`
  margin-block-start: ${primitive.$spacing6};
  margin-block-end: 1rem;
`;

export const Footer = styled.div<{ $isOnlyCode?: boolean }>`
  padding: 1rem;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 1rem;
  border-end-start-radius: 1rem;
  background-color: ${props => (props.$isOnlyCode ? primitive.$white : primitive.$whiteOpacity30)};
  font-family: ${props => (props.$isOnlyCode ? Styles.fontFamily.base : Styles.fontFamily.body)};
  font-size: ${props => (props.$isOnlyCode ? '1.125rem' : '1rem')};
  color: ${Styles.color.contrastBackground};
`;

export const Code = styled.div<{ $isOnlyCode?: boolean }>`
  font-family: ${Styles.fontFamily.base};
  color: ${Styles.color.contrastBackground};
  font-size: ${props => (props.$isOnlyCode ? '1.875rem' : '1.5rem')};
  padding: ${props => (props.$isOnlyCode ? '1rem 1.5rem' : '0')};
  background-color: ${props => (props.$isOnlyCode ? '#F5EBDC' : primitive.$whiteOpacity30)};
  border-radius: 0.625rem;
  width: fit-content;
  margin: ${props => (props.$isOnlyCode ? '1.5rem auto 0.5rem' : '0.25rem auto 0')};
  direction: ltr;
`;

export const DetailsContainer = styled.div`
  display: flex;
  margin-block-start: 1.5rem;
  border-block-end: solid 1px ${Styles.color.grey.five};
  min-height: 7.5rem;

  ${Styles.desktop} {
    margin: 1.5rem auto;
    max-width: 36rem;
  }
`;

export const ItemPicture = styled.div`
  width: 8rem;
  min-width: 8rem;
`;

export const RewardDetails = styled.div`
  margin-inline-start: 1rem;
  padding-inline-end: 0.75rem;
`;

export const ItemName = styled(BlockRenderer)`
  font-size: 1rem;
  font-family: ${Styles.fontFamily.base};
  color: ${Styles.color.contrastBackground};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  p {
    text-align: start;
  }
`;

export const ViewDetails = styled(ActionButton)`
  color: ${Styles.color.black};
  font-family: ${Styles.fontFamily.body};
  text-align: start;
  font-size: 0.75rem;
  font-weight: 300;
  padding-block-start: 0.25rem;
`;

export const OfferBadge = styled.div`
  background-color: ${Styles.color.contrastBackground};
  font-size: 0.75rem;
  line-height: 1.2rem;
  text-align: center;
  font-family: ${Styles.fontFamily.brand};
  color: ${Styles.color.white};
  width: fit-content;
  min-width: 4rem;
  border-radius: 0.25rem;
  padding-block-start: 0.375rem;
  padding-block-end: 0.25rem;
  padding-inline: 0;
  margin-block-start: 0.5rem;
`;
