import { useEffect, useState } from 'react';

import type { ConnectionStatus } from '@capacitor/network';

import { IStore } from '@rbi-ctg/store';
import {
  DeliveryStatus,
  DeliveryStoreStatus,
  OperationalStatus,
  ServiceMode,
  useGetRestaurantsLazyQuery,
} from 'generated/rbi-graphql';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useServiceModeContext } from 'state/service-mode';
import { convertMilesToMeters } from 'utils/distance';
import { IPlaceAddress } from 'utils/geolocation';
import { useGetAvailableRestaurantWithDetails } from 'utils/restaurant';

import { GroqArgs, groqQuery } from './groq';

const projection = `
  _id,
  chaseMerchantId,
  deliveryHours,
  diningRoomHours,
  curbsideHours,
  drinkStationType,
  driveThruHours,
  driveThruLaneType,
  email,
  cybersourceTransactingId,
  franchiseGroupId,
  franchiseGroupName,
  frontCounterClosed,
  hasBreakfast,
  hasBurgersForBreakfast,
  hasCurbside,
  hasDineIn,
  deliveryOrderAmountLimit,
  hasCatering,
  hideClickAndCollectOrdering,
  hasDelivery,
  hasDriveThru,
  hasTableService,
  hasMobileOrdering,
  hasParking,
  hasPlayground,
  hasTakeOut,
  hasWifi,
  hasLoyalty,
  isDarkKitchen,
  isHalal,
  latitude,
  longitude,
  mobileOrderingStatus,
  name,
  number,
  parkingType,
  phoneNumber,
  physicalAddress,
  playgroundType,
  pos,
  posRestaurantId,
  restaurantPosData->{_id},
  showStoreLocatorOffersButton,
  status,
  restaurantImage{..., asset->},
  vatNumber,
  customerFacingAddress,
`;

const storeQuery = `
  *[ _type == 'restaurant' && _id == $id ]{
    ${projection}
  }
`;

const storesByNumberQuery = `
  *[ _type == 'restaurant' && number == $storeNumber ] {
    ${projection}
  }
`;

interface IRestaurantQueryArgs {
  limit?: number;
  maxLat: number;
  maxLng: number;
  minLat: number;
  minLng: number;
  offset?: number;
  status: 'Open';
  userLat: number;
  userLng: number;
  hasDelivery?: boolean;
}

type ClosestAvailableRestaurantArgs = Pick<IRestaurantQueryArgs, 'userLat' | 'userLng'> & {
  connection: ConnectionStatus;
};

export enum StoreStatus {
  OPEN = 'deliveryStoreOpen',
  CLOSED = 'deliveryStoresClosed',
  NO_DELIVERY = 'noDeliveryStores',
  PAUSED = 'deliveryStoresPaused',
}

export declare interface IGetClosestAvailableDeliveryRestaurantResult {
  deliveryQuote: DeliveryStatus | null;
  storeStatus: StoreStatus;
  store: IStore | null;
  nextEarliestOpen?: Date;
  unavailabilityReason: string | null;
}

export const DeliveryStoreStatusToStoreStatusMap: Record<DeliveryStoreStatus, StoreStatus> = {
  [DeliveryStoreStatus.CLOSED]: StoreStatus.CLOSED,
  [DeliveryStoreStatus.NO_DELIVERY]: StoreStatus.NO_DELIVERY,
  [DeliveryStoreStatus.OPEN]: StoreStatus.OPEN,
  [DeliveryStoreStatus.PAUSED]: StoreStatus.PAUSED,
};

export type QueryClosestAvailableRestaurantTriple = [
  (
    phoneNumber: string,
    deliveryRadiusInMiles: number,
    { userLat, userLng }: ClosestAvailableRestaurantArgs,
    deliveryAddress?: IPlaceAddress | null
  ) => void,
  IGetClosestAvailableDeliveryRestaurantResult | undefined,
  string | undefined,
];

export const useQueryClosestAvailableDeliveryRestaurant =
  (): QueryClosestAvailableRestaurantTriple => {
    const [query, { data }] = useGetRestaurantsLazyQuery();
    const { setDeliverySurchargeFee } = useServiceModeContext();
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    const [storeData, setStoreData] = useState<
      IGetClosestAvailableDeliveryRestaurantResult | undefined
    >();
    const [address, setDeliveryAddress] = useState<IPlaceAddress | null | undefined>();
    const getAvailableRestaurantWithDetails = useGetAvailableRestaurantWithDetails();
    const { trackEvent } = useCdpContext();

    const getRestaurants = (
      phoneNumber: string,
      deliveryRadiusInMiles: number,
      { userLat, userLng }: ClosestAvailableRestaurantArgs,
      deliveryAddress?: IPlaceAddress | null
    ) => {
      setPhone(phoneNumber);
      setDeliveryAddress(deliveryAddress);
      query({
        variables: {
          input: {
            coordinates: {
              searchRadius: convertMilesToMeters(deliveryRadiusInMiles),
              userLat,
              userLng,
            },
            radiusStrictMode: true,
            serviceModes: [ServiceMode.DELIVERY],
            status: OperationalStatus.OPEN,
          },
        },
      });
    };

    const restaurantNodes = data?.restaurants?.nodes;
    useEffect(() => {
      if (!restaurantNodes) {
        return;
      }

      const handleResults = async () => {
        const {
          deliveryQuoteError,
          storesWithInvalidHOO,
          deliveryQuoteSurchargeFeeCents,
          ...restaurantDetails
        } = await getAvailableRestaurantWithDetails(
          (restaurantNodes ?? []) as unknown as IStore[],
          phone,
          address
        );

        // Log stores which don't have delivery hours set
        storesWithInvalidHOO.forEach((store: IStore) => {
          trackEvent({
            name: CustomEventNames.STORE_DELIVERY_INVALID_HOO,
            type: EventTypes.Other,
            attributes: {
              StoreId: store._id,
              DeliveryHours: store.deliveryHours,
            },
          });
        });

        if (deliveryQuoteError) {
          setError(deliveryQuoteError);
        }
        if (deliveryQuoteSurchargeFeeCents) {
          setDeliverySurchargeFee(deliveryQuoteSurchargeFeeCents);
        }

        setStoreData(restaurantDetails as IGetClosestAvailableDeliveryRestaurantResult);
      };

      handleResults();
    }, [
      address,
      getAvailableRestaurantWithDetails,
      trackEvent,
      phone,
      restaurantNodes,
      setDeliverySurchargeFee,
    ]);

    const result: QueryClosestAvailableRestaurantTriple = [getRestaurants, storeData, error];
    return result;
  };

type GetRestaurantArgs = GroqArgs<{ id: string }>;

export const getRestaurant = (endpoint: string, { connection, id }: GetRestaurantArgs) =>
  groqQuery<[IStore], GetRestaurantArgs>(endpoint, storeQuery, { connection, id });

type GetRestaurantsByStoreNumberArgs = GroqArgs<{ storeNumber: string }>;

export const getRestaurantsByStoreNumber = (
  endpoint: string,
  { connection, storeNumber }: GetRestaurantsByStoreNumberArgs
) =>
  groqQuery<IStore[], GetRestaurantsByStoreNumberArgs>(endpoint, storesByNumberQuery, {
    connection,
    storeNumber,
  });
