import React from 'react';

import { HamburgerButton } from 'components/app-header/mobile-header/hamburger-button';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';

import { Wrapper } from './hamburger-button-styled';

export const DesktopHamburgerButton = () => {
  const { featureNavigationMobileHeaderButton } = useFeatureNavigation();
  const hasNavigationLink = !!featureNavigationMobileHeaderButton?.link?.locale;

  return (
    <Wrapper hasNavigationLink={hasNavigationLink}>
      <HamburgerButton />
    </Wrapper>
  );
};
