import { useEffect } from 'react';

import { useFeatureHomePage } from 'components/features/home-page/use-feature-home-page';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { setUserUtmAttributes } from 'state/cdp/mParticle/set-user-attributes';
import { useLocationContext } from 'state/location';
import { useStoreContext } from 'state/store';
import * as location from 'utils/location';
import { routes } from 'utils/routing';

export const useLogPageView = () => {
  const { featureNavigationMobile } = useFeatureNavigation();
  const {
    eventPageName,
    location: { search, pathname },
    pathMatches,
    setEventPageName,
  } = useLocationContext();
  const { logPageView, trackEvent } = useCdpContext();
  const { homePageId } = useFeatureHomePage();
  const { restaurantLogAttributesRef } = useStoreContext();

  useEffect(() => {
    if (featureNavigationMobile.length > 0) {
      let linkName: string = '';
      if (!eventPageName || eventPageName === '') {
        const [nav] = featureNavigationMobile.filter((navMobile: any) =>
          pathMatches(navMobile?.tabLink?.link?.locale || '')
        );
        linkName = nav?.tabLink?.text?.locale || '';
        setEventPageName(linkName);
      }
      // get mParticle user object with current attributes
      const user = window.mParticle?.Identity?.getCurrentUser?.(); // TODO: Check with Bloomreach after
      // add the UTM attributes conteined on URL to mParticle user object
      setUserUtmAttributes(user, new URLSearchParams(search));

      // determine attributes to send to the events below
      const referrer = document.referrer || location.get('href');
      const attrs = {
        pathname,
        pathWithQuery: pathname + search,
        sanityId: pathname === routes.base ? homePageId.featureHomePageId : '',
        referrer,
        Name: eventPageName || linkName,
        ...restaurantLogAttributesRef.current,
      };
      // Flags for google analytics integration
      const flags = {
        'Google.Page': pathname,
        'Google.DocumentReferrer': referrer,
      };
      // mParticle page view event - uses mParticle logPageView API to track page view
      logPageView(pathname, attrs, flags);
      // mParticle custom event for page view - uses mParticle logEvent API to track page view
      trackEvent({
        name: CustomEventNames.PAGE_VIEW,
        type: EventTypes.Other,
        attributes: {
          ...attrs,
          ...flags,
          path: pathname,
        },
      });

      setEventPageName(undefined);
    }
  }, [pathname, featureNavigationMobile]); // eslint-disable-line react-hooks/exhaustive-deps
};
