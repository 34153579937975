import React from 'react';

import { OnClick } from '@rbilabs/components-library/build/components/button';
import { Box } from '@rbilabs/components-library/build/components/layout';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes } from 'components/action-button';
import defaultConfig from 'components/app-header/styled';
import { LoyaltyPointsButton } from 'components/loyalty-points-button';
import { UserAccountButton } from 'components/user-account-button';
import { LoyaltyAppHeader } from 'pages/loyalty/loyalty-app-header';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { designTokens } from 'styles/constants/design-tokens.plk';
import { RBIBrand, brand } from 'utils/environment';
import { routes } from 'utils/routing';

import RecentOrdersLink from '../recent-orders-link';

interface ISecondaryNav {
  onMyAccountClick: OnClick;
}

const RightNav: React.FC<ISecondaryNav> = ({ onMyAccountClick }) => {
  const { isAuthenticated } = useAuthContext();
  const { formatMessage } = useIntl();
  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) ? false : enableSignUpFlag;

  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) ? false : enableOrderingFlag;

  const isLoyaltyEnabled = useIsLoyaltyEnabled();

  let customStyle = {};

  if (brand() === RBIBrand.PLK) {
    customStyle = {
      ...customStyle,
      backgroundColor: designTokens['background-color'] || '',
      color: designTokens['background-text-color'] || '',
      borderRadius: designTokens['background-border-radius'] || '',
      border: designTokens['background-border'] || '',
    };
  }

  if (!enableSignUp) {
    return null;
  }

  return (
    <>
      {isLoyaltyEnabled && <LoyaltyAppHeader />}
      {isAuthenticated() ? (
        <>
          {enableOrdering && (
            <RecentOrdersLink
              to={routes.orders}
              data-testid="recent-orders-link"
              title={formatMessage({ id: 'recentOrders' })}
            />
          )}
          <UserAccountButton onClick={onMyAccountClick} />
          <LoyaltyPointsButton />
        </>
      ) : (
        <>
          <Box margin="0 1rem 0 0">
            <ActionButton
              size={ActionButtonSizes.SMALL}
              variant={defaultConfig.buttonVariant}
              to={routes.signUp}
              data-testid="desktop-signup-link"
              style={customStyle}
            >
              {formatMessage({ id: 'signUp' })}
            </ActionButton>
          </Box>
        </>
      )}
    </>
  );
};

export default RightNav;
