import styled from 'styled-components';

import { isIOS, isNative } from 'utils/environment';

export const StyledLogo = styled.div`
  height: 50px;
  margin-block-start: 0;
  margin-block-end: ${isNative && !isIOS() ? -3 : 0}px;
  margin-inline: auto;
  width: 50px;
  ${Styles.headerMobile} {
    padding: 6px;
  }
`;

export const TopServiceModeContainer = styled.div`
  box-shadow: ${Styles.boxShadow};
  z-index: 1;
`;
