import {
  gql as gqlFunc /* do not change this `as` as it makes it so the babel-plugin-graphql-tag transform works properly */,
} from '@apollo/client';
import { getAllLanguages, getLanguages } from '@rbilabs/intl';

import { ISanityTextNode } from '@rbi-ctg/menu';
import { FALLBACK_LANGUAGE } from 'utils/constants';
import { brand, getCountry } from 'utils/environment';

export interface ILocaleBlockTextContent {
  localeRaw: [
    {
      children: [
        {
          [language: string]: {
            _key: string;
            _type: string;
            marks: object[];
            text: string;
          };
        },
      ];
    },
  ];
  markDefs: object[];
}

export interface IWidget {
  _key: string;
  _type: string;
  headerImage?: {
    _type: string;
  };
  headingContent?: {
    _type: string;
    [language: string]: string;
  };
  localeBlockTextContent?: ILocaleBlockTextContent;
}

export interface IStaticPageRoute {
  _id: string;
  _updatedAt?: string | null;
  requiredUserAcceptance?: boolean | null;
  widgets?: any | null;
  pageHtml?: {
    code: string;
  } | null;
  path: {
    current: string | null;
  };
  title: string | null;
  localePath: {
    [language: string]: {
      current: string | null;
    } | null;
  };
  localeTitle: ISanityTextNode | null;
  displayIndex: number | null;
  parent: {
    _id: string;
    _key: string;
  } | null;
  redirectUrl: ISanityTextNode | null;
}

export interface IGetStaticPageRoutesQuery {
  allStaticPage: IStaticPageRoute[];
}
const supportedLanguages = getAllLanguages(brand()) ?? [FALLBACK_LANGUAGE];
const regionSupportedLanguages = getLanguages(brand(), getCountry().toUpperCase()) ?? [];

// need to do this at runtime not at build time babel transform
export const GetStaticPageRoutesQuery = gqlFunc(`
  query GetStaticPageRoutes($staticPageWhere: StaticPageFilter) {
    allStaticPage (where: $staticPageWhere) {
      _id
      _updatedAt
      path {
        current
      }
      title
      localePath {
        ${(regionSupportedLanguages || supportedLanguages).map(lang => `${lang} { current }`)}
      }
      redirectUrl {
        locale: _LANG_
      }
      localeTitle {
        locale: _LANG_
      }
      displayIndex
      parent {
        _id
        _key
      }
      redirectUrl {
        locale: _LANG_
      }
    }
  }
`);
