import React, { ComponentType } from 'react';

import { isExternalLink } from 'utils/is-external-link';

import { IUniversalLinkProps } from './types';

const UniversalLink = <InternalProps extends object, ExternalProps extends object>({
  to,
  children,
  internal,
  external,
  ...rest
}: IUniversalLinkProps<InternalProps, ExternalProps>) => {
  const isExternal = isExternalLink(to);

  const propToUse = isExternal ? external : internal;

  const Link: ComponentType<any> = propToUse.component;

  const linkProps = {
    ...propToUse.props,
    ...(isExternal ? { href: to } : { to }),
    ...(isExternal ? { target: '_blank' } : ''),
  };

  return (
    <Link {...linkProps} {...rest}>
      {children}
    </Link>
  );
};

export default UniversalLink;
