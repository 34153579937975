import React from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { StaticMenuProvider, useStaticMenuContext } from 'state/static-menu';
import { useStoreContext } from 'state/store';
import { useIsBrowseMenu } from 'utils/menu/hooks/use-is-browse-menu';

import { MainMenuProvider, useMainMenuContext } from '../main-menu';
import { MenuOptionsProvider, useMenuOptionsContext } from '../menu-options';
import { MenuUIProvider } from '../menu-ui';

export type Menu = ReturnType<typeof useMainMenuContext> &
  ReturnType<typeof useMenuOptionsContext> &
  ReturnType<typeof useStaticMenuContext> & {
    showStaticMenu: boolean;
    showBurgersForBreakfast: boolean;
  };

export const useMenuContext = (currentUrl?: string): Menu => {
  const newMainMenu = useMainMenuContext();
  const menu = useMenuOptionsContext();
  const staticMenuData = useStaticMenuContext();

  const { isBrowseMenu } = useIsBrowseMenu(currentUrl ?? '');
  const enableStaticMenu = useFlag(LaunchDarklyFlag.ENABLE_STATIC_MENU);
  const { isStoreOpenAndAvailable, store } = useStoreContext();

  const showStaticMenu =
    enableStaticMenu &&
    (!isStoreOpenAndAvailable || store.hasMobileOrdering === false || isBrowseMenu);
  const showBurgersForBreakfast = isStoreOpenAndAvailable && store.hasBurgersForBreakfast === true;

  const menuCtx = newMainMenu;
  const menuOptionsCtx = menu;

  // @todo: remove me once we deprecate the old context
  // @ts-ignore
  return {
    ...menu,
    ...menuCtx,
    ...menuOptionsCtx,
    ...staticMenuData,
    showStaticMenu,
    showBurgersForBreakfast,
  };
};

export const MenuProvider = ({ children }: IBaseProps) => {
  return (
    <MainMenuProvider>
      <MenuOptionsProvider>
        <StaticMenuProvider>
          <MenuUIProvider>{children}</MenuUIProvider>
        </StaticMenuProvider>
      </MenuOptionsProvider>
    </MainMenuProvider>
  );
};
