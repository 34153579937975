import React from 'react';

import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

import FooterContent from './footer';

const Footer = () => {
  const {
    location: { pathname },
  } = useLocationContext();

  // hiding footer on sign up / login flow
  if (pathname === routes.signUp || pathname === routes.signIn || pathname === routes.confirmOtp) {
    return null;
  }

  return <FooterContent />;
};

export default Footer;
