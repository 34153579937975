import { SupportedLanguages } from '@rbi-ctg/frontend';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { normalize } from 'utils/routing';

export const findLocaleStaticPage = (
  path: string,
  selectedLanguage: SupportedLanguages,
  previousLanguage: SupportedLanguages,
  routes: IStaticPageRoute[]
): string | null => {
  const findPathStatic =
    routes
      .map(route => {
        const current = route?.path?.current ?? '';
        const localePath = route?.localePath;
        const isPathCurrent = path === `/${current}`;

        if (localePath && localePath[selectedLanguage]) {
          const findUrl =
            isPathCurrent || localePath[previousLanguage]?.current === path?.replace('/', '');

          if (findUrl) {
            return localePath[selectedLanguage]?.current;
          }
        }

        return isPathCurrent ? current : null;
      })
      .filter(item => !!item)[0] ?? undefined;

  // Clear path preventing if someone inserts a bar in Sanity
  const pathStaticPage = findPathStatic ? normalize(findPathStatic) : null;

  return pathStaticPage;
};
