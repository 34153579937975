import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import NavLink from 'components/app-header/nav-link';
import SkipToContent from 'components/skip-content';
import { CustomEventNames } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

import TrendingLink from '../trending-link';
import { ILeftNav } from '../types';

export const StaticLeftNav: FC<ILeftNav> = ({ orderHref, curriedHandleClick }) => {
  const { formatMessage } = useIntl();
  const enableOffers = useFlag(LaunchDarklyFlag.ENABLE_OFFERS);
  const enableOrderLinkNav = useFlag(LaunchDarklyFlag.ENABLE_ORDER_LINK_NAV);
  const enableStaticMenu = useFlag(LaunchDarklyFlag.ENABLE_STATIC_MENU);
  const enableCatering = useFlag(LaunchDarklyFlag.ENABLE_CATERING);

  return (
    <>
      <SkipToContent />
      {enableOrderLinkNav && !enableStaticMenu ? (
        <NavLink
          data-testid="order-desktop"
          to={orderHref}
          matchRoute={routes.menu}
          onClick={curriedHandleClick(CustomEventNames.BUTTON_CLICK_ORDER)}
        >
          {formatMessage({ id: 'order' })}
        </NavLink>
      ) : (
        <NavLink
          data-testid="order-desktop"
          to={routes.menu}
          matchRoute={routes.menu}
          onClick={curriedHandleClick(CustomEventNames.BUTTON_CLICK_MENU)}
        >
          {formatMessage({ id: 'menu' })}
        </NavLink>
      )}
      {enableCatering && (
        <NavLink
          data-qa-id="catering-desktop"
          to={routes.catering}
          onClick={curriedHandleClick(CustomEventNames.BUTTON_CLICK_CATERING)}
        >
          {formatMessage({ id: 'catering' })}
        </NavLink>
      )}
      <NavLink
        data-testid="restaurants-desktop"
        to={routes.storeLocator}
        onClick={curriedHandleClick(CustomEventNames.BUTTON_CLICK_RESTAURANTS)}
      >
        {formatMessage({ id: 'restaurants' })}
      </NavLink>
      {enableOffers && (
        <NavLink
          data-testid="offers-desktop"
          to={routes.offers}
          onClick={curriedHandleClick(CustomEventNames.BUTTON_CLICK_OFFERS)}
        >
          {formatMessage({ id: 'offers' })}
        </NavLink>
      )}
      <TrendingLink onTrendingClick={curriedHandleClick(CustomEventNames.BUTTON_CLICK_TRENDING)} />
    </>
  );
};
