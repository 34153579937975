import React, { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import Modal, { ModalContent, ModalHeading } from 'components/modal';
import useEffectOnce from 'hooks/use-effect-once';
import { useCdpContext } from 'state/cdp';
import { useLocale } from 'state/intl';
import { inferSupportedLocale } from 'state/intl/infer-supported-locale';
import { useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { NEXT_URL_QUERY_PARAM } from 'state/location/constants';
import { useStaticPageRoutes } from 'state/static-page-manager/hooks/use-static-page-routes';
import { getRedirectUrl } from 'utils/language/language-selector';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { modalHeadingStyles } from './constants';
import {
  LanguageSelectorModalContents,
  ModalAdditionalContentWrapper,
} from './language-selector-modal-contents';

const ContentContainer = styled(ModalContent)`
  padding-block-end: 2rem;
  & div.modal-inner {
    margin: 0;
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-auto-rows: auto;
    text-align: justify;
  }
`;

const StyledModalHeading = styled(ModalHeading)`
  justify-self: center;

  ${modalHeadingStyles}

  ${Styles.desktop} {
    max-width: none;
  }
`;

const PrimaryText = styled.div`
  margin: 2rem auto;
  text-align: center;
`;

const SecondaryText = styled.div`
  text-align: center;
`;

interface ILanguageModalProps {
  onModalDismiss: VoidFunction;
  heading?: string;
  redirectToCurrentLocation?: boolean;
  primaryText?: string;
  secondaryText?: string;
  disclaimer?: string;
}

const LanguageSelectorModal: React.FC<ILanguageModalProps> = ({
  onModalDismiss,
  heading,
  primaryText,
  secondaryText,
  disclaimer,
  redirectToCurrentLocation = false,
}) => {
  const { formatMessage } = useIntl();
  const { loadRoutes, routes } = useStaticPageRoutes();
  const {
    redirect,
    location: { pathname },
  } = useLocationContext();
  const { updateUserAttributes } = useCdpContext();
  const { region, locale, language, setCurrentLanguage, shouldUseLanguageCodeInUrls } = useLocale();
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const enableRedirectLocalePage = useFlag(LaunchDarklyFlag.ENABLE_REDIRECT_LOCALE_PAGE);

  const handleLanguageChange = (nextLanguage: SupportedLanguages) => () => {
    setSelectedLanguage(nextLanguage);
  };

  const isNewLocaleSelectionSameAsCurrent = useCallback(() => {
    const inferredLocaleFromArgs = inferSupportedLocale(selectedLanguage, region);

    return inferredLocaleFromArgs === locale;
  }, [selectedLanguage, region, locale]);

  const handleSaveClick = useCallback(() => {
    if (isNewLocaleSelectionSameAsCurrent()) {
      onModalDismiss();
      return;
    }

    updateUserAttributes({ language: selectedLanguage });

    const redirectUrl = getRedirectUrl({
      pathname,
      enableRedirectLocalePage,
      selectedLanguage,
      currentLanguage: language,
      routes,
      shouldUseLanguageInUrl: shouldUseLanguageCodeInUrls,
    });

    if (redirectToCurrentLocation) {
      redirectUrl.searchParams.append(NEXT_URL_QUERY_PARAM, encodeURIComponent(pathname));
    }

    setCurrentLanguage(selectedLanguage);

    redirect(redirectUrl.toString());
  }, [
    enableRedirectLocalePage,
    isNewLocaleSelectionSameAsCurrent,
    language,
    onModalDismiss,
    pathname,
    redirect,
    redirectToCurrentLocation,
    routes,
    selectedLanguage,
    setCurrentLanguage,
    shouldUseLanguageCodeInUrls,
    updateUserAttributes,
  ]);

  useEffectOnce(() => {
    loadRoutes();
  });

  const applyButtonText = formatMessage({ id: 'apply' });
  const modalHeading = heading || formatMessage({ id: 'selectLanguage' });

  return (
    <Modal
      data-testid="select-language-modal"
      onDismiss={onModalDismiss}
      eventData={{
        modalAppearanceEventMessage: 'Language and region selector',
      }}
    >
      <ContentContainer>
        <StyledModalHeading id="language-select-modal-heading">{modalHeading}</StyledModalHeading>
        {(primaryText || secondaryText) && (
          <ModalAdditionalContentWrapper>
            {primaryText && <PrimaryText>{primaryText}</PrimaryText>}
            {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
          </ModalAdditionalContentWrapper>
        )}
        <LanguageSelectorModalContents
          ariaRadioGroupLabeledBy="language-select-modal-heading"
          langKey={selectedLanguage}
          disclaimer={disclaimer}
          applyButtonText={applyButtonText}
          onLanguageChange={handleLanguageChange}
          onSaveClick={handleSaveClick}
        />
      </ContentContainer>
    </Modal>
  );
};

export default LanguageSelectorModal;
