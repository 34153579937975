// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'fr',
    brand: 'plk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: 'd7875afbd9ad27eafc5f9008f55a5216d6140c12',
    releaseTagRef: 'd1e9e49',
    releaseTimestamp: '1724134339',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '',
      branch: '',
      braze: '3406164b-59bb-45b2-8a93-9b053cfbcc7e',
      brazeCluster: 'sdk.fra-02.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyAzV2be2f9MKiFnGWTLVsv3hs20ywbmOzk',
      googleMapsAndroid: '',
      googleMapsIOS: '',
      googleMapsWeb: '',
      tomtom: '',
      launchDarkly: '6446872ee330d213132d1295',
      launchDarklyMobileKey: 'mob-2c376e49-41d8-46e4-a06f-bf7cf7aa7e6b',
      mParticle: 'eu1-5e6e1d16a690e043bb3a838cf26980a1',
      radar: '',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
    },
    apple: {
      merchantId: '',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-plk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-plk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '30hr4b7kbgbtvbv77ian7h0m9n',
      userPoolId: 'eu-central-1_z6T3UVf4Z',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: '-',
        dataCenter: '',
      },
      rum: {
        applicationId: '-',
        clientToken: '-',
        dataCenter: '',
      },
    },
    firstData: {
      corporateStoreId: '1111',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: '',
      paymentsGateway: '',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-KW4TJW6',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: 'C0003',
        consentCategoryMParticle: 'C0001',
        globalConsentState: 'Opt-In',
        scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        scriptId: '5d54c054-4b11-45fe-a1ce-d2bfdba7cac9-test',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://dashboard-02.braze.eu',
      firstData: 'https://prod.api.firstdata.com',
      firstDataTh: '',
      webBaseUrl: '',
      thDigital: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: 'dd/MM/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: 'C0003',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: '',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '5d54c054-4b11-45fe-a1ce-d2bfdba7cac9-test',
        },
      },
    }
  };