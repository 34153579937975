import { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';

import { IBaseItem, ICartEntry } from '@rbi-ctg/menu';
import { IUpsellProps } from 'pages/cart/upsell/types';
import { useCdpContext } from 'state/cdp';
import { useMenuContext } from 'state/menu';
import { createCartEntry } from 'utils/cart';
import { routes } from 'utils/routing';

type UseAddUpsellCartItemParams = Pick<
  IUpsellProps,
  'items' | 'recommendationToken' | 'recommender'
> & { addToCart: (entry: ICartEntry) => void };

export const useAddUpsellCartItem = ({
  items,
  addToCart,
  recommender,
  recommendationToken,
}: UseAddUpsellCartItemParams) => {
  const { formatMessage } = useIntl();
  const { pricingFunction } = useMenuContext();
  const { logUpsellAddedEvent } = useCdpContext();

  const [orderAddedAlertMessage, setOrderAddedAlertMessage] = useState('');

  const handleAddToCart = useCallback(
    (item: IBaseItem) => {
      const price = pricingFunction(item);
      const entry: ICartEntry = {
        ...createCartEntry({
          item,
          price,
          location: { pathname: `${routes.menu}/${item._type}-${item._id}` },
        }),
        isUpsell: true,
        recommendationToken,
        recommender,
      };

      addToCart(entry);

      const cartItemIndex = items.findIndex(cartItem => cartItem._id === item._id);
      const cartItemPosition = cartItemIndex >= 0 ? cartItemIndex + 1 : undefined;
      logUpsellAddedEvent(entry, cartItemPosition);

      setOrderAddedAlertMessage(
        formatMessage({ id: 'itemAddedToCart' }, { itemName: item.name?.locale })
      );
    },
    [
      addToCart,
      formatMessage,
      items,
      logUpsellAddedEvent,
      pricingFunction,
      recommendationToken,
      recommender,
    ]
  );

  return { orderAddedAlertMessage, handleAddToCart };
};
