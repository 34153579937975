import styled, { css } from 'styled-components';

import { filterProps } from 'utils/filter-props';
import { Link as RouterLink } from 'utils/navigation/router';

type LinkProps = {
  isSelected?: boolean;
  noBorder?: boolean;
};

const linkStyles = (props: LinkProps) => css`
  display: inline-block;
  color: ${props.isSelected ? Styles.color.tertiary : Styles.color.black};
  font-size: 0.9375rem;
  text-decoration: none;
  text-transform: ${Styles.textTransform.headlines};
  position: relative;
  margin: 0 auto;

  &.selected::before,
  &::before {
    content: '';
    background-color: ${!props.noBorder && props.isSelected ? 'currentColor' : 'transparent'};
    height: 2px;
    width: 100%;
    position: absolute;
    inset-block-end: 0;
  }

  &:hover,
  &.selected {
    color: ${Styles.color.tertiary};
    text-decoration: none;
  }

  &.selected::before {
    background-color: ${!props.noBorder ? 'currentColor' : 'transparent'};
  }
`;

const Link = styled(filterProps(RouterLink, 'noBorder'))<LinkProps>`
  ${linkStyles}
`;

export const LinkExternal = styled.a<LinkProps>`
  ${linkStyles}
`;

const secondaryHoverColor = '#000'; // deviates from theme to increase color contrast
export const SecondaryLink = styled(Link)`
  color: ${props => (props.isSelected ? secondaryHoverColor : Styles.color.white)};

  &:hover,
  &.active {
    color: ${secondaryHoverColor};
  }
`;

export default Link;
