import React from 'react';

import { NavLink as ReactRouterLink } from 'react-router-dom';

import * as location from 'utils/location';

const shouldNavigate = (e: any) =>
  !e.defaultPrevented && e.button === 0 && !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);

declare global {
  interface Window {
    serviceWorkerUpdated?: boolean;
  }
}

/*
  This is a type hole. Reach Link is defined using a forward ref,
  which has made providning an accurate type for this wrapper very
  difficult (if it's even possible).
*/
export const Link = (props: any) => {
  const { to } = props;
  return (
    <ReactRouterLink
      {...props}
      onClick={e => {
        if (props.onClick) {
          props.onClick(e);
        }
        if (shouldNavigate(e) && window.serviceWorkerUpdated && to) {
          e.preventDefault();
          location.assign(to);
        }
      }}
    />
  );
};
