import React from 'react';

import { useIntl } from 'react-intl';
import { PointsDisplay } from 'ui-system/points-display';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { primitive } from 'styles/constants/primitives';

import {
  ButtonContent,
  Lock,
  StyledActionButton,
  StyledIconCirclePlus,
  StyledIconCircleTimes,
} from './redeem-reward.styled';
import { RedeemRewardActionButtonProps, RewardActions } from './types';

const RedeemRewardActionButton = ({
  action,
  disabled,
  handleRewardClick,
  points,
  locked,
}: RedeemRewardActionButtonProps) => {
  const { formatMessage } = useIntl();
  const isAdd = action === RewardActions.ADD;
  const buttonLabel = locked
    ? points
    : formatMessage({ id: isAdd ? 'redeemApply' : 'redeemRemove' }, { points });

  return (
    <StyledActionButton
      size={ActionButtonSizes.SMALL}
      variant={ActionButtonVariants.PRIMARY}
      disabled={disabled}
      onClick={handleRewardClick(action)}
      data-testid={`redeem-${isAdd ? 'add' : 'remove'}`}
    >
      <PointsDisplay
        value={buttonLabel}
        icon={locked ? <Lock fill={primitive.$white} /> : undefined}
      />
      <ButtonContent>
        {disabled ? null : isAdd ? (
          <StyledIconCirclePlus width="18" />
        ) : (
          <StyledIconCircleTimes width="18" />
        )}
      </ButtonContent>
    </StyledActionButton>
  );
};

export default RedeemRewardActionButton;
