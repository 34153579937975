import React from 'react';

import { View } from 'components/view';
import useMediaQuery from 'hooks/use-media-query';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useOrderContext } from 'state/order';
import { routes } from 'utils/routing';

import DesktopHeader from './desktop-header';
import MobileHeader from './mobile-header';

export const routesWithoutHeader = [
  routes.cart,
  routes.signIn,
  routes.signUp,
  routes.orderConfirmation,
  routes.createAccount,
];

export const routesWithoutMobileHeader = [
  routes.storeLocator,
  routes.membershipDetails,
  routes.blueLightCard,
];

const isPathInRouteList = (routeList: string[], pathname: string) =>
  routeList.some(route => pathname.startsWith(route));

const AppHeader: React.FC = () => {
  const { serviceMode, cateringPickupDateTime, deliveryAddress } = useOrderContext();
  const isMobile = useMediaQuery('headerMobile');
  const { isAuthenticated } = useAuthContext();
  const enableLoyaltyQRCodeHeaderButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_QR_CODE_HEADER_BUTTON
  );

  const {
    location: { pathname },
  } = useLocationContext();

  // Hide header on Loyalty My Code page with flag
  if (isAuthenticated() && enableLoyaltyQRCodeHeaderButton) {
    routesWithoutMobileHeader.push(routes.redeem);
  }

  if (
    (isMobile && isPathInRouteList(routesWithoutMobileHeader, pathname)) ||
    (!isMobile && isPathInRouteList(routesWithoutHeader, pathname))
  ) {
    return null;
  }

  return (
    <View data-testid="app-header">
      {isMobile && (
        <MobileHeader
          serviceMode={serviceMode}
          userAddress={deliveryAddress?.addressLine1}
          cateringPickupDateTime={cateringPickupDateTime}
        />
      )}
      {!isMobile && (
        <DesktopHeader
          serviceMode={serviceMode}
          userAddress={deliveryAddress?.addressLine1}
          cateringPickupDateTime={cateringPickupDateTime}
        />
      )}
    </View>
  );
};

export default AppHeader;
export { default as LogoHeader } from './logo-header';
export { default as MenuHeader, HEADER_HEIGHT } from './menu-header';
