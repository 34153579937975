import styled, { css } from 'styled-components';

import ActionButton from 'components/action-button';

export const FabContainer = styled.div<{
  $badgeContent?: string | number | undefined;
  $badgeColor?: string | undefined;
  $badgeBgColor?: string | undefined;
  $badgeBgBorderRadius?: string | undefined;
}>`
  position: absolute;
  inset-inline-end: -0.625rem;
  inset-block-end: 0;
  padding-inline-end: 1.125rem;
  padding-block-end: 1.75rem;
  ${({ $badgeContent, $badgeColor, $badgeBgColor, $badgeBgBorderRadius }) =>
    $badgeContent &&
    css`
      div::after {
        background: ${$badgeBgColor || Styles.color.contrastBackground};
        color: ${$badgeColor || Styles.color.white};
        border-radius: ${$badgeBgBorderRadius || Styles.borderRadius};
        content: '${$badgeContent}';
        font-family: ${Styles.fontFamily.base};
        font-size: 0.725rem;
        height: 1.125rem;
        position: absolute;
        inset-inline-end: 1rem;
        text-align: center;
        inset-block-start: -0.5rem;
        width: 1.125rem;
      }
    `}
`;

export const StyledActionButton = styled(ActionButton)`
  background-color: ${Styles.color.primary};
  font-size: 1rem;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  box-shadow: 0 4px 10px 0 #00000040;
  &:hover {
    background-color: ${Styles.color.primaryHover};
  }
`;
