import { IsoCountryCode2, Language } from '@rbilabs/intl';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { userHasSignedIn as userHasSignedInOnCognito } from 'remote/auth';
import { initCognitoSession } from 'utils/cognito';
import { RBIEnv, brand, env, isLocalDev, platform } from 'utils/environment';
import { initGoogleTagManager } from 'utils/google-tag-manager';
import { getAppCountry, getAppLanguage } from 'utils/i18n';
import {
  LANGUAGE_SEARCH_PARAM,
  shouldUseLanguageCodeInUrls as getShouldUseLanguageCodeInUrls,
  shouldRedirectToLanguagePath,
} from 'utils/i18n/language-inference';
import { LaunchDarklyFlag, LaunchDarklyFlagsObject, initLaunchDarkly } from 'utils/launchdarkly';
import { backgroundUpdate, forceUpdate } from 'utils/live-updates';
import * as location from 'utils/location';
import { createRedirectHandler } from 'utils/routing';

interface GtmEvent {
  event: string;
  brand: string;
  timestamp: number;
  duration: number;
  platform: string;
}

declare global {
  interface Window {
    dataLayer: GtmEvent[];
    LOADING_START_TIME: number;
    /**
     * window.Cypress exists when our app is running within Cypress
     * Places we currently check for this:
     * - frontend/src/state/graphql/links/index.ts - avoid BatchHttpLink
     *
     * https://docs.cypress.io/faq/questions/using-cypress-faq.html#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
     */
    Cypress?: any;
    // When run with cypress for cypress-v2 package,
    // we instantiate the LD client with predefined flags
    _initial_cypress_feature_flags?: object;
    // Skip the interval for checking for unavailable items.
    // Avoids race condition in cart with recorded tests
    _skipUnavailableItemsIntervalCheck?: boolean;
  }
}

const emitLoadingSuccessEvent = (rbiBrand: string, rbiPlatform: string) => {
  const successTime = performance.now?.();
  const durationTillSuccess = successTime - window.LOADING_START_TIME;
  (window.dataLayer || []).push({
    event: 'APP_BUNDLE_LOADING_SUCCESS',
    brand: rbiBrand,
    timestamp: successTime,
    duration: durationTillSuccess,
    platform: rbiPlatform,
  });
};

export interface RenderAppOptions {
  appLanguage: Language;
  appCountry: IsoCountryCode2;
  flags: LaunchDarklyFlagsObject;
  userHasSignedIn: boolean;
  userSession: CognitoUserSession | null;
  shouldUseLanguageCodeInUrls: boolean;
}

export type RenderApp = (options: RenderAppOptions) => void;

export default async function bootstrap(renderApp: RenderApp): Promise<void> {
  const [ENV, BRAND, PLATFORM] = [env(), brand().toUpperCase(), platform()];

  if (!isLocalDev) {
    emitLoadingSuccessEvent(BRAND, PLATFORM);
  }
  const userSessionPromise = initCognitoSession();
  const flags = await initLaunchDarkly();
  const enableGoogleTagManagerInit = flags['enable-google-tag-manager'];

  // ** LANGUAGE INFERENCE **
  // Language inference has to be done as soon as possible.
  // This is currently kept in the function body due to the dependency on the LD
  // `disableLocalization` flag.  Should we no longer depend on it, this code
  // block can be moved to the module level.
  const isLocalizationDisabled = Boolean(flags[LaunchDarklyFlag.DISABLE_LOCALIZATION]);
  const enableLanguageCodeInUrls = Boolean(
    flags[LaunchDarklyFlag.ROLLOUT_ENABLE_LANGUAGE_CODE_IN_URLS]
  );
  const appCountry = getAppCountry();
  const appLanguage = getAppLanguage(isLocalizationDisabled, enableLanguageCodeInUrls);
  const shouldUseLanguageCodeInUrls = getShouldUseLanguageCodeInUrls(
    enableLanguageCodeInUrls,
    isLocalizationDisabled
  );

  // Handle legacy URLs
  if (shouldUseLanguageCodeInUrls) {
    const pathname = location.get('pathname');
    const searchParams = location.getSearchParams();

    if (searchParams.has(LANGUAGE_SEARCH_PARAM)) {
      searchParams.delete(LANGUAGE_SEARCH_PARAM);
    }

    if (shouldRedirectToLanguagePath(pathname)) {
      createRedirectHandler(appLanguage)(
        `${pathname}${searchParams.size > 0 ? `?${searchParams}` : ''}`
      );

      return;
    }
  }

  // Only handle live updates when enabled
  if (flags[LaunchDarklyFlag.ENABLE_APP_FLOW_UPDATE]) {
    // For blocking update immediately sync and reload the app
    // Since this happens when the splash is still visible it won't blink
    if (flags[LaunchDarklyFlag.ENABLE_FORCE_BLOCKING_UPDATE]) {
      await forceUpdate();
      // When the forced update is not enabled just sync in background
      // And reload on next foreground
    } else {
      backgroundUpdate();
    }
  }

  const userSession = await userSessionPromise;

  // this has to be after init cognito session.
  const userHasSignedIn = await userHasSignedInOnCognito();

  if (ENV === RBIEnv.TEST) {
    const defaultFlags = {};
    renderApp({
      appLanguage,
      appCountry,
      flags: defaultFlags,
      userHasSignedIn,
      userSession,
      shouldUseLanguageCodeInUrls,
    });
    return;
  }

  if (enableGoogleTagManagerInit) {
    initGoogleTagManager();
  }

  renderApp({
    appLanguage,
    appCountry,
    flags,
    userHasSignedIn,
    userSession,
    shouldUseLanguageCodeInUrls,
  });
}
