import React, { createContext, useContext, useEffect, useState } from 'react';

import { noop } from 'lodash';
import { useMatch } from 'react-router-dom';

import { IBaseProps, SetState } from '@rbi-ctg/frontend';
import { useAuthContext } from 'state/auth';
import { routes } from 'utils/routing';

export interface IReversedUIContext {
  reversedUI: boolean;
  setReversedUI: SetState<boolean>;
}

export const ReversedUIContext = createContext<IReversedUIContext>({
  reversedUI: false,
  setReversedUI: noop,
});

export const ReversedUIProvider = ({ children }: IBaseProps) => {
  const { isAuthenticated } = useAuthContext();
  const authenticated = isAuthenticated();
  const isHome = useMatch(routes.base);

  const [reversedUI, setReversedUI] = useState(false);

  useEffect(() => {
    if (!isHome || !authenticated) {
      setReversedUI(false);
    }
  }, [authenticated, isHome]);

  return (
    <ReversedUIContext.Provider
      value={{
        reversedUI,
        setReversedUI,
      }}
    >
      {children}
    </ReversedUIContext.Provider>
  );
};

export const useReversedUIContext = () => useContext(ReversedUIContext);
