import React, { FC, ReactNode } from 'react';

import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';
import { View } from 'components/view';
import useMediaQuery from 'hooks/use-media-query';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useMobileFooterComponent } from 'state/mobile-footer';
import { UUID_REGEX_STRING } from 'utils/constants';
import { routes } from 'utils/routing';

export interface LayoutBottomContainerProps extends IBaseProps {}

const Container = styled(View)`
  width: 100%;
`;

const LayoutBottomContainer: FC<{ children?: ReactNode }> = ({ children, ...props }) => {
  const {
    location: { pathname },
  } = useLocationContext();
  const isDesktop = useMediaQuery('desktopLarge');
  const { isAuthenticated } = useAuthContext();
  const FooterComponent = useMobileFooterComponent();
  const enableLoyaltyQRCodeHeaderButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_QR_CODE_HEADER_BUTTON
  );

  const mobileRoutesWithoutBottomNavbar = [
    routes.cart,
    routes.signUp,
    new RegExp(`${routes.orderConfirmation}/${UUID_REGEX_STRING}`),
    ...(isAuthenticated() && enableLoyaltyQRCodeHeaderButton ? [routes.redeem] : []),
  ];

  if (FooterComponent && !isDesktop) {
    return <FooterComponent />;
  } else if (mobileRoutesWithoutBottomNavbar.some(route => !!pathname.match(route))) {
    return null;
  }

  return (
    <Container data-testid="container-layout-bottom" {...props}>
      {children}
    </Container>
  );
};

export default LayoutBottomContainer;
