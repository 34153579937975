import React from 'react';

import { useIntl } from 'react-intl';

import { ISanityImage } from '@rbi-ctg/menu';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { LoyaltyAppHeaderView } from './loyalty-app-header.view';

export const LoyaltyAppHeader: React.FC = () => {
  const { formatMessage } = useIntl();
  const { featureLoyaltyUi, featureLoyaltyUiLoading } = useFeatureLoyaltyUi();
  const { loyaltyUser } = useLoyaltyUser();

  const rewardsLink =
    featureLoyaltyUi?.loyaltyCTAButtons?.loyaltyBannerHomepageAuthenticatedButton
      ?.loyaltyBannerHomepageAuthenticatedLink?.locale;
  const rewardsLogo = featureLoyaltyUi?.rewardsLogo?.locale;

  const shouldDisplay = !featureLoyaltyUiLoading && rewardsLogo;

  return shouldDisplay ? (
    <LoyaltyAppHeaderView
      points={loyaltyUser?.points}
      rewardsLogo={rewardsLogo as ISanityImage}
      rewardsLink={rewardsLink || formatMessage({ id: 'routes.loyaltyDashboard' })}
    />
  ) : null;
};
