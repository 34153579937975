import { useCallback, useEffect, useMemo, useState } from 'react';

import { IsoCountryCode2, Language } from '@rbilabs/intl';

import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useLoggerContext } from 'state/logger/context';
import en from 'state/translations/en.json';
import { TLocaleCode, TLocalizationMessages } from 'types/i18n';
import { FALLBACK_ISO3 } from 'utils/constants';
import * as DatadogLogger from 'utils/datadog';
import { ISOs } from 'utils/form/constants';
import {
  TFormatDateFn,
  formatDate as formatDateDefault,
  getInferredLanguage,
  shouldUseLanguageCodeInUrls as getShouldUseLanguageCodeInUrls,
  getSupportedMarketLanguage,
} from 'utils/i18n';
import { createFormatDate, getDateFnsLocale } from 'utils/i18n/date';
import {
  TFormatDistanceToNowFn,
  createFormatDistanceToNow,
  formatDistanceToNow as formatDistanceToNowOrig,
} from 'utils/i18n/distance';
import {
  TFormatDurationFn,
  createFormatDuration,
  formatDuration as formatDurationDefault,
} from 'utils/i18n/duration';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { getMessagesForLanguage } from './get-messages-for-language';
import { IUserCurrentLocaleCache } from './types';

export * from './constants';

const reloadWindow = () => document.location.reload();

export const useIntlProviderValue = (appLanguage: Language, appCountry: IsoCountryCode2) => {
  const { updateUserLanguage } = useLDContext();
  const { decorateLogger } = useLoggerContext();

  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const enableLanguageCodeInUrls = useFlag(LaunchDarklyFlag.ROLLOUT_ENABLE_LANGUAGE_CODE_IN_URLS);
  const shouldUseLanguageCodeInUrls = getShouldUseLanguageCodeInUrls(
    enableLanguageCodeInUrls,
    isLocalizationDisabled
  );

  const [messages, setMessages] = useState<TLocalizationMessages>(en);
  const locale: TLocaleCode = `${appLanguage}-${appCountry}`;
  const [userCurrentLocale, setUserCurrentLocale] = useState<IUserCurrentLocaleCache | undefined>(
    undefined
  );
  const language = appLanguage;
  const region = appCountry;

  // Formatting functions with the current locale applied.
  const [formatDate, setFormatDate] = useState<TFormatDateFn>(() => formatDateDefault);
  const [formatDistanceToNow, setFormatDistanceToNow] = useState<TFormatDistanceToNowFn>(
    () => formatDistanceToNowOrig
  );
  const [formatDuration, setFormatDuration] = useState<TFormatDurationFn>(
    () => formatDurationDefault
  );

  const setCurrentLanguage = useCallback(
    (languageRequested: Language) => {
      const newLanguage = getSupportedMarketLanguage(languageRequested, isLocalizationDisabled);

      LocalStorage.setItem(StorageKeys.LANGUAGE, newLanguage);

      updateUserLanguage(newLanguage);
    },
    [isLocalizationDisabled, updateUserLanguage]
  );

  useEffectOnce(() => {
    window.addEventListener('languagechange', () => {
      setCurrentLanguage(getInferredLanguage(shouldUseLanguageCodeInUrls));
      reloadWindow();
    });
  });

  useEffectOnce(() => {
    decorateLogger({ region });
  });

  // We track the user's locale (region + language) in logger errors
  // some errors might occur in specific langauges, so its crucial
  // to know all info on how to reproduce.
  useEffect(() => {
    DatadogLogger.addContext('page_locale', locale);
  }, [locale]);

  useEffect(() => {
    const initializeFormattingFunctions = async (localeRbi: string) => {
      const localeDateFns = await getDateFnsLocale(localeRbi);
      setFormatDate(() => createFormatDate({ locale: localeDateFns }));
      setFormatDistanceToNow(() => createFormatDistanceToNow({ locale: localeDateFns }));
      setFormatDuration(() => createFormatDuration({ locale: localeDateFns }));
    };

    initializeFormattingFunctions(locale);
  }, [locale]);

  useEffectOnce(() => {
    getMessagesForLanguage({ language, country: region }).then(setMessages);
  });

  // Make sure locale will update for logged-in users who have localization enabled
  // and also any time the isLocalization flag changes - including when it kicks in initially
  useEffectOnUpdates(() => {
    reloadWindow();
  }, [shouldUseLanguageCodeInUrls]);

  return useMemo(
    () => ({
      region,
      feCountryCode: ISOs[region] || FALLBACK_ISO3,
      language,
      locale,
      setUserCurrentLocale,
      shouldUseLanguageCodeInUrls,
      userCurrentLocale,
      messages,
      formatDate,
      formatDistanceToNow,
      formatDuration,
      setCurrentLanguage,
    }),
    [
      formatDate,
      formatDistanceToNow,
      formatDuration,
      language,
      locale,
      messages,
      region,
      setCurrentLanguage,
      shouldUseLanguageCodeInUrls,
      userCurrentLocale,
    ]
  );
};
