import { datadogRum } from '@datadog/browser-rum';

import { useBranchContext } from 'state/branch';
import { useCdpContext } from 'state/cdp';
import { initDatadogRum } from 'utils/datadog';
import LaunchDarklyHelper, { LaunchDarklyFlag, getExtraContext } from 'utils/launchdarkly';

import { isBranchUnavailable } from './cookiebot-cookie-banner/utils';

export default function useInitializeThirdPartyServices() {
  const { init } = useCdpContext();
  const { initBranch } = useBranchContext();
  const branchUnavailable = isBranchUnavailable();

  const initServicesWithCookies = () => {
    if (!datadogRum.getInitConfiguration()) {
      const allFlags = LaunchDarklyHelper.getInstance().launchDarkly.allFlags();
      const flattenedFlags = LaunchDarklyHelper.flattenFlags(allFlags);
      const sampleRate = flattenedFlags[LaunchDarklyFlag.DATADOG_RUM_SAMPLE_RATE] || 0;
      const replaySampleRate = flattenedFlags[LaunchDarklyFlag.DATADOG_RUM_REPLAY_SAMPLE_RATE] || 0;
      initDatadogRum(getExtraContext(), sampleRate, replaySampleRate);
    }
    if (!window.mParticle || !window.exponea) {
      init();
    }
    if (!window.branch && !branchUnavailable) {
      initBranch();
    }
  };

  return { initServicesWithCookies };
}
