import styled from 'styled-components';
import { PointsDisplay, PointsDisplayIcon } from 'ui-system/points-display';

import Currency from 'components/currency';
import { plopDown, plopUp } from 'utils/style/animations';

import { IIsOffer } from '../types';

export const CartItemPrice = styled.span<IIsOffer>`
  color: ${Styles.color.black};
  margin: 0.5rem 0;
  font-family: ${Styles.fontFamily.base};
  font-size: 1.25rem;
  font-weight: ${Styles.fontWeight.normal};
  letter-spacing: 'normal';
  font-stretch: condensed;
  line-height: 1.25rem;
  position: relative;
  ${p =>
    p.isOffer &&
    `
    color: ${Styles.color.pricing};
    font-weight: ${Styles.fontWeight.heavy};
    `}
`;

export const StyledDiscountPrice = styled(Currency)<{ isVisible: boolean }>`
  color: ${Styles.color.validated};
  transition: 0.25s;
  animation: ${p => (p.isVisible ? plopDown : plopUp)} 0.3s forwards;
  position: absolute;
  inset-inline-end: 0;
  width: max-content;
`;

export const StrikeThrough = styled.div`
  height: 1px;
  background-color: ${Styles.color.grey.three};
  position: relative;
  transition: 0.2s;
  inset-block-start: 0.75rem;
  inset-inline-end: 3px;
`;

export const StyledCurrency = styled(Currency)`
  transition: 0.25s;
`;

export const CurrencyContainer = styled.div<{ isRewardApplied: boolean }>`
  width: fit-content;
  justify-content: center;

  ${StrikeThrough} {
    width: ${p => (p.isRewardApplied ? '115%' : '0')};
  }

  ${StyledCurrency} {
    ${p => p.isRewardApplied && `color: ${Styles.color.grey.three};`}
  }
`;

export const StyledPointsDisplay = styled(PointsDisplay)`
  margin-block-start: 0.25rem;

  ${PointsDisplayIcon} {
    width: 1em;
    height: 1em;
  }
`;
