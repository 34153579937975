import { SupportedLanguages } from '@rbi-ctg/frontend';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { findLocaleStaticPage } from 'utils/language/static-page';
import * as location from 'utils/location';
import { pathname as getPathname } from 'utils/routing';

interface IGetRedirectUrlParams {
  currentLanguage: SupportedLanguages;
  enableRedirectLocalePage: boolean;
  pathname: string;
  routes: IStaticPageRoute[];
  selectedLanguage: SupportedLanguages;
  shouldUseLanguageInUrl: boolean;
}

// Get base path for language change redirect when ENABLE_REDIRECT_LOCALE_PAGE flag is enabled
// it tries to find the static page path otherwise returns pathname
export const getRedirectBasePath = ({
  pathname,
  selectedLanguage,
  currentLanguage,
  routes,
  enableRedirectLocalePage,
}: Omit<IGetRedirectUrlParams, 'shouldUseLanguageInUrl'>) => {
  const localeStaticPagePath = enableRedirectLocalePage
    ? findLocaleStaticPage(pathname, selectedLanguage, currentLanguage, routes)
    : null;

  return localeStaticPagePath ?? pathname;
};

// Used to get redirect path for language change when ROLLOUT_ENABLE_LANGUAGE_CODE_IN_URLS is disabled
// When the pathname is different from the basePathname it's a static page and we don't append the lang search param
export const getRedirectPathWithSearchParams = (
  pathname: string,
  basePathname: string,
  selectedLanguage: SupportedLanguages
) => {
  const searchParams = new URLSearchParams({
    lang: selectedLanguage,
  });

  return basePathname !== pathname ? basePathname : `${basePathname}?${searchParams}`;
};

// Get redirect URL for language change redirect, first we get the base redirect pathname without any language
// That results in either new pathname for a static page or remain unchanged in the other case.
// Then we enhance the pathname with the language information based on the ROLLOUT_ENABLE_LANGUAGE_CODE_IN_URLS value to return full URL
export const getRedirectUrl = ({
  pathname,
  shouldUseLanguageInUrl,
  selectedLanguage,
  ...rest
}: IGetRedirectUrlParams) => {
  const redirectBasePath = getRedirectBasePath({
    pathname,
    selectedLanguage,
    ...rest,
  });
  const redirectPath = shouldUseLanguageInUrl
    ? getPathname(selectedLanguage)(redirectBasePath)
    : getRedirectPathWithSearchParams(pathname, redirectBasePath, selectedLanguage);

  return new URL(`${location.get('origin')}${redirectPath}`);
};
