import styled from 'styled-components';

import Picture from 'components/picture';

export const CartItemPrice = styled.span`
  font-weight: bold;
`;

export const UpsellCartItemWrapper = styled.div<{ $isAdded?: boolean; $flow?: 'menu' | 'cart' }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${props =>
    props.$isAdded ? Styles.color.contrastBackground : 'rgba(0, 0, 0, 0.1)'};
  border-radius: 0.5rem;
  gap: 1rem;
  padding: 1rem;
  position: relative;

  background-color: white;
  min-width: 12rem;
  max-width: ${({ $flow }) => ($flow === 'cart' ? '12rem' : 'unset')};

  ${Styles.mobile} {
    min-width: 40vw;
  }
`;

export const UpsellCartItemName = styled.span`
  font-size: 1rem;
  text-align: center;
  font-family: ${Styles.fontFamily.brand};
  font-weight: ${Styles.fontWeight.normal};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  /*
  * Even if the -webkit-box-orient property is marked as non-standard by MDN (https://developer.mozilla.org/en-US/docs/Web/CSS/box-orient)
  * I didn't find another way of achieving vertical line clamp using CSS only.
  * The same property is used in around 15 files throughout the app.
  */
`;

export const UpsellCartItemPicture = styled(Picture)<{ $flow?: 'menu' | 'cart' }>`
  aspect-ratio: 1;
  height: 90px;

  ${Styles.desktop} {
    height: ${({ $flow }) => ($flow === 'cart' ? '90px' : '120px')};
  }
`;

export const UpsellCartItemRow = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const UpsellCartItemButtonContainer = styled.div`
  margin-block-start: auto;
  align-self: stretch;
`;

export const UpsellCartItemQuantityContainer = styled.div`
  margin-block-start: auto;
  height: 3rem;
`;

export const UpsellCartItemCol = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
