import { IOffer, ISanityBlockContent, ISanityTextNode } from '@rbi-ctg/menu';
import { ILoyaltyEngineRewardsQuery, Maybe, OfferRedemptionType } from 'generated/graphql-gateway';
import { IAppliedOffer } from 'generated/rbi-graphql';
import {
  IConfigOfferFragment,
  IExternalOffersFragment,
  ILoyaltyRewardsByEngineIdQuery,
  ISystemwideOffersFragment,
} from 'generated/sanity-graphql';
import { OffersServiceMode } from 'pages/loyalty/loyalty-offers/types';

export interface ILoyaltyCategoryOption {
  name: ISanityTextNode;
  description: ISanityBlockContent;
  points: number;
  _id: string;
}
export interface ILoyaltyCategory {
  _id: string;
  name: ISanityTextNode;
  description: ISanityBlockContent;
  options: Array<ILoyaltyCategoryOption>;
}

export interface ILoyaltyCategories {
  allLoyaltyConfigurations: {
    categories: ILoyaltyCategory;
  };
  errors?: Error[];
}

export interface ILoyaltyTier {
  name: ISanityTextNode;
  description: ISanityBlockContent;
  points: number;
  _id: string;
}

export interface ILoyaltyTiers {
  allLoyaltyConfiguration: {
    bankMyRewardTier: ILoyaltyTier;
  };
  errors?: Error[];
}

export type LoyaltyRewards = ILoyaltyRewardsByEngineIdQuery['allReward'];

export type LoyaltyReward = LoyaltyRewards[0];

export type LoyaltyEngineRewards = ILoyaltyEngineRewardsQuery['loyaltyRewards'];

export type LoyaltyEngineReward = NonNullable<LoyaltyEngineRewards>[0];

export type LoyaltyOffer = (ISystemwideOffersFragment | IConfigOfferFragment) & {
  isStackable?: Maybe<boolean>;
  metadata?: Maybe<
    ReadonlyArray<
      Maybe<{
        readonly __typename?: 'ObjectType';
        readonly key: Maybe<string>;
        readonly value: Maybe<any>;
      }>
    >
  >;
  offerServiceMode?: OffersServiceMode;
};

export enum IncentiveSource {
  SUPPORT = 'SUPPORT',
  PROMOTION_CODE = 'PROMOTION_CODE',
}

export type LoyaltyAppliedOffer = IAppliedOffer & {
  isStackable?: Maybe<boolean>;
  isSurprise?: Maybe<boolean>;
  cmsId?: Maybe<string>;
  isAppliedAtCheckout?: Maybe<boolean>;
};

export function isReward(incentive: LoyaltyReward | LoyaltyOffer): incentive is LoyaltyReward {
  return incentive.__typename === 'Reward';
}

export function isLoyaltyOffer(
  incentive: LoyaltyReward | LoyaltyOffer | IOffer
): incentive is LoyaltyOffer {
  return incentive.__typename === 'SystemwideOffer' || incentive.__typename === 'ConfigOffer';
}

export function isDiscountReward(
  incentive?: LoyaltyReward | LoyaltyOffer | null
): incentive is LoyaltyReward {
  return !!incentive?.incentives?.some((i: any) => i?.__typename === 'OfferDiscount');
}

export function isSurpriseOffer(offer: LoyaltyOffer): offer is LoyaltyOffer {
  return offer.redemptionType ? offer.redemptionType === OfferRedemptionType.SURPRISE : false;
}

// Offer Incentives must have an _id to be valid
export function isValidOfferIncentive(obj: any): obj is { _id: string } {
  return !!obj?._id;
}

export type IEngineRewardsMap = {
  [sanityId: string]: LoyaltyEngineReward;
};
export type IPersonalizedRewardMap = {
  [rewardId: string]: LoyaltyEngineReward;
};
export type ISanityRewardsMap = Record<LoyaltyReward['_id'], LoyaltyReward>;

export enum RuleType {
  RequiresAuthentication = 'RequiresAuthentication',
  LoyaltyBetweenDates = 'LoyaltyBetweenDates',
  LoyaltyPoints = 'loyaltyPoints',
  TierList = 'tier-list',
  RewardPrice = 'RewardPrice',
  UserAttributes = 'UserAttributes',
}

export enum UserAttributesItem {
  BatteryLevel = 'battery-level',
}

export type ExternalOffer = IExternalOffersFragment;

export type ExternalOfferModalDetails = {
  sanityId: ExternalOffer['_id'];
  title: string | null | undefined;
  buttonsConfiguration: ExternalOffer['buttonsConfiguration'];
  externalOfferModal: ExternalOffer['externalOfferModal'];
  image: ExternalOffer['image'];
};

export enum OfferType {
  CONFIG = 'configOffer',
  EXTERNAL = 'externalOffers',
  SYSTEMWIDE = 'systemwideOffer',
}
