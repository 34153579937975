import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IsoCountryCode2 } from '@rbilabs/intl';
import { AllowedEvent, IGlobalAttributes } from '@rbilabs/intl-mparticle-client';
import { chunk, compact, isEmpty, isEqual, merge, noop } from 'lodash';
import { centsToDollars } from 'utils';
import uuidv4 from 'uuid/v4';

import { IBackendCartEntries, ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import useEffectOnce from 'hooks/use-effect-once';
import { useHasAcceptedCookies } from 'hooks/use-has-accepted-cookies';
import useMediaQuery, { MediaQuery } from 'hooks/use-media-query';
import useReadyQueue from 'hooks/use-ready-queue';
import { IStaticPageRoute } from 'remote/queries/static-page';
import useBraze from 'state/braze/hooks/use-braze';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import {
  SignUpFieldsVariations,
  defaultSignUpFieldsVariation,
} from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { useLoggerContext } from 'state/logger/context';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import {
  determinePlatformFromNavigator,
  getBrowserType,
  getBrowserVersion,
  getIsMobileWeb,
} from 'utils/browser';
import { CartEntryType } from 'utils/cart/types';
import { StatusType, addContext as addLoggerContext, dataDogLogger } from 'utils/datadog';
import { getDeviceId } from 'utils/device-id';
import { RBIBrand, appVersionCode, brand, env, getCountry, isNative } from 'utils/environment';
import LaunchDarklyHelper, { showField } from 'utils/launchdarkly';
import { getCurrentVersion as getCurrentAppflowVersion } from 'utils/live-updates';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import * as location from 'utils/location';
import { isOTPEnabled } from 'utils/otp';
import { isHome, isLocalRoute, routes } from 'utils/routing';
import SessionStorage, { SessionStorageKeys } from 'utils/session-storage';

import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  LAYER_ATTRIBUTE_DEFAULT_VALUE,
  LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT,
  ONE_INDEX_OFFSET,
  ProductActionTypes,
  SignInPhases,
  TRACKED_PAGES,
  UTM_ATTRIBUTES,
} from '../constants';
import { createIdentity } from '../create-identity';
import { ItrackEvent, defaultCdpCtx } from '../types';
import * as I from '../types';
import { useFlagsForUniversalAttributes } from '../use-flags-for-universal-attributes';
import {
  ProductItemType,
  booleanToString,
  errorIdentityCallback,
  flattenCartEntryItems,
  getAllowedAttributes,
  getSourcePage,
  getUserHasLoyalty,
  normalizeBooleans,
  sanitizeValues,
  serializeNumberOfDriveThruWindows,
  serializePaymentType,
  serializePickupMode,
  serializeServiceMode,
} from '../utils';

import initMParticle from './init';
import { MParticleAdapter } from './mparticle-adapter';
import { updateMParticleATTStatus } from './mparticle-att-plugin';
import { getSessionId } from './session-manager';
import setUserAttributes, {
  getUtmAttributes,
  setUserUtmAttributes,
  updateLocationPermissionStatus,
} from './set-user-attributes';

declare global {
  interface Window {
    mParticle: I.ICdp;
  }
}

type ExcludesNull = <T>(x: T | null) => x is T;

declare interface ILogPageView {
  pathname: string;
  normalizedAndSanitizedAttrs: any;
  normalizedFlags: any;
}

export const MParticleContext = React.createContext<I.ICdpCtx>(defaultCdpCtx);

export const useMParticleContext = () => useContext<I.ICdpCtx>(MParticleContext);

export function MParticleProvider(props: { children: ReactNode }) {
  const logFlagsEvaluatedEvent = useRef(false);
  const { enqueueIfNotDrained, drainQueue } = useReadyQueue();
  const { logger } = useLoggerContext();
  const {
    location: { pathname },
  } = useLocationContext();
  const { locale, language } = useLocale();
  const hasAcceptedCookies = useHasAcceptedCookies();
  const enableCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_COOKIE_BANNER);
  const isUpsellSimplified = useFlag(LaunchDarklyFlag.ENABLE_PRODUCT_UPSELL_SIMPLIFIED);
  const enablePushNotificationsFlag = useFlag(
    LaunchDarklyFlag.ENABLE_PUSH_NOTIFICATIONS_ON_SIGN_UP
  );
  const deviceTime = useCallback(() => new Date().toLocaleTimeString([], { hour12: false }), []);
  const flagsForUniversalAttributes = useFlagsForUniversalAttributes();
  const [sessionId, setSessionId] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');

  const prevFlags = useRef({});
  const { flags: rawLdFlags, updateUserDeviceId } = useLDContext();
  const [isNewSignUp, setIsNewSignUp] = useState(false);
  const [signUpFlowTrackData, setSignUpFlowTrackData] = useState<I.ITrackSignUpEvent>({
    event: CustomEventNames.SIGN_UP,
    data: '',
  });
  const { initBraze } = useBraze();
  const signUpFieldsVariations =
    useFlag<SignUpFieldsVariations>(LaunchDarklyFlag.SIGN_UP_FIELDS_VARIATIONS) ||
    defaultSignUpFieldsVariation;
  let additionalSignupFields = {} as SignUpFieldsVariations;

  if (brand() === RBIBrand.PLK && getCountry()?.toUpperCase() === IsoCountryCode2.KR) {
    additionalSignupFields.gender = showField(signUpFieldsVariations.gender);
    additionalSignupFields.ageFourteen = showField(signUpFieldsVariations.ageFourteen);
    additionalSignupFields.additionalSignUpTerms = showField(
      signUpFieldsVariations.additionalSignUpTerms
    );
  }

  const isSmallScreen = useMediaQuery(MediaQuery.Mobile);

  // store static routes for logging page views
  const staticRoutes = useRef<string[]>([]);
  // Its possible to attempt to log a page view, before sanity loads the static pages
  // This ref holds these values until static pages has loaded, after which the page view can be logged
  const logPageViewParameters = useRef<ILogPageView>();

  // store universal attributes for mParticle event/page views without re-rendering for changes in values
  const universalAttributes = useRef<I.ICdpUniversalAttributes>({
    'Service Mode': '',
    'Pickup Mode': '',
    'Source Page': getSourcePage(pathname),
    browserType: getBrowserType(),
    browserVersion: getBrowserVersion(),
    isMobileWeb: getIsMobileWeb(),
    isLoyaltyUser: getUserHasLoyalty(),
    isSmallScreen,
    currentBuild: '',
    ...flagsForUniversalAttributes,
    ...additionalSignupFields,
    layer: LAYER_ATTRIBUTE_DEFAULT_VALUE,
  });

  // set up a backup unique session id in case ad blockers block mParticle
  const uniqueGuid = useRef<string>(uuidv4());

  const updateUserLocationPermissionStatus = () => {
    if (!window?.mParticle?.Identity?.getCurrentUser) {
      return;
    }
    const user = window.mParticle.Identity.getCurrentUser();
    if (!user) {
      return;
    }
    updateLocationPermissionStatus(user);
  };

  const updateStaticRoutes = (newStaticRoutes: IStaticPageRoute[]) => {
    staticRoutes.current = newStaticRoutes.reduce((acc: string[], route) => {
      const staticPath = route?.localePath?.[language]?.current || route?.path?.current;
      if (staticPath) {
        acc.push(`/${staticPath}`);
      }
      return acc;
    }, []);

    if (staticRoutes.current.length && logPageViewParameters.current) {
      maybeTrackPage({ ...logPageViewParameters.current });
      logPageViewParameters.current = undefined;
    }
  };

  const configureSessionId = enqueueIfNotDrained(() =>
    getSessionId()
      .then(({ sessionId: currentSessionUUID }) => {
        const currentSessionId = currentSessionUUID || uniqueGuid.current;

        setSessionId(currentSessionId);
        addLoggerContext('session', currentSessionId);
      })
      .catch(({ error, message }) => {
        // This happens when the web does not have a sessionId yet
        // for iOS to use. We won't bother making it an error
        // since there is nothing for us to do
        if (message === 'No session ID available') {
          logger.warn(message);

          return;
        }

        dataDogLogger({
          message: `Failed to get MParticle SessionID: ${error || message}`,
          status: StatusType.error,
        });
      })
  );

  const updateDeviceId = enqueueIfNotDrained(async () => {
    const id = await getDeviceId();
    updateUserDeviceId(id);
    setDeviceId(id);
  });

  // initializes mParticle
  const init = useCallback(() => {
    initMParticle(() => {
      const user = window.mParticle?.Identity?.getCurrentUser();
      setUserUtmAttributes(user, location.getSearchParams());
      updateUserLocationPermissionStatus();
      drainQueue();
      initBraze();
    });
  }, [drainQueue, initBraze]);

  useEffectOnce(() => {
    // Enqueue setting the sessionId and deviceId to prevent race condition
    configureSessionId();
    updateMParticleATTStatus();
    updateDeviceId();
    if (!enableCookieBanner || hasAcceptedCookies) {
      init();
    }
  });

  //update a user attribute(consent would be a good place to start)

  //Consent mgmt is it's own thing.
  //https://docs.mparticle.com/developers/sdk/web/consent-management/

  const logError = enqueueIfNotDrained(
    (
      error: { name: string; message?: string; stack?: string },
      attrs?: { [key: string]: string | number | boolean }
    ) => {
      const normalizedAttrs = attrs ? normalizeBooleans(attrs) : {};
      window.mParticle.logError(
        {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
        {
          ...normalizedAttrs,
          RBIEnv: env(),
        }
      );
    }
  );

  const logValidationError = enqueueIfNotDrained(
    (
      error: { name: string; description?: string },
      attrs?: { [key: string]: string | number | boolean }
    ) => {
      const normalizedAttrs = attrs ? normalizeBooleans(attrs) : {};
      trackEvent({
        name: CustomEventNames.ERROR,
        type: EventTypes.Transaction,
        attributes: {
          Name: error.name,
          Description: error.description,
          ...normalizedAttrs,
        },
      });
    }
  );

  const updateUniversalAttributes = useCallback(
    (newAttributes: Partial<I.ICdpUniversalAttributes>) =>
      (universalAttributes.current = { ...universalAttributes.current, ...newAttributes }),
    []
  );

  const {
    chefRecommendationEngine2,
    chefUpsellItemCount,
    enableFlavorFlow,
    enableCheckoutUpsellItems2,
    enableHomePageRecentItems,
    enableOffersRefresh,
    enableRecentItemsAddToCart,
    enableRecentlyOrderedItems,
    enableServiceModeCartSelection,
    enableStoreConfirmationModal,
    enableUserSavedDeliveryAddressPhone,
    enableFeatureHomePage,
    enableRecentItemsWithModifiers,
  } = flagsForUniversalAttributes;

  // If any of these flag values change
  // updateUniversalAttributes so they are updated
  // for the next mParticle event
  useEffect(() => {
    const getCurrentAppflowVersionAndSetAttributes = async () => {
      const isLoyaltyUser = getUserHasLoyalty();
      const currentBuild =
        (await getCurrentAppflowVersion().then(version => version?.appflowBuildId)) ||
        appVersionCode();
      updateUniversalAttributes({
        chefRecommendationEngine2,
        chefUpsellItemCount,
        currentBuild,
        enableFlavorFlow,
        enableCheckoutUpsellItems2,
        enableHomePageRecentItems,
        enableOffersRefresh,
        enableRecentItemsAddToCart,
        enableRecentlyOrderedItems,
        enableServiceModeCartSelection,
        enableStoreConfirmationModal,
        enableUserSavedDeliveryAddressPhone,
        enableFeatureHomePage,
        enableRecentItemsWithModifiers,
        isLoyaltyUser,
        isSmallScreen,
      });
    };

    getCurrentAppflowVersionAndSetAttributes().catch(error => logger.error(error));
  }, [
    chefRecommendationEngine2,
    chefUpsellItemCount,
    enableFlavorFlow,
    enableCheckoutUpsellItems2,
    enableHomePageRecentItems,
    enableOffersRefresh,
    enableRecentItemsAddToCart,
    enableRecentlyOrderedItems,
    enableServiceModeCartSelection,
    enableStoreConfirmationModal,
    enableUserSavedDeliveryAddressPhone,
    enableFeatureHomePage,
    enableRecentItemsWithModifiers,
    updateUniversalAttributes,
    logger,
    isSmallScreen,
  ]);

  const deleteUtmAttributes = (): void => {
    const user = window.mParticle?.Identity?.getCurrentUser?.();
    if (!user) {
      return;
    }

    const { removeUserAttribute } = user;

    if (!removeUserAttribute || typeof removeUserAttribute !== 'function') {
      return;
    }

    UTM_ATTRIBUTES.forEach(attr => {
      user.removeUserAttribute(attr);
    });
  };

  /**
   * Tracks an event to mParticle using the RBI events interface from @rbilabs/intl-mparticle-client.
   */
  const trackEvent: ItrackEvent = enqueueIfNotDrained((event: AllowedEvent) => {
    let universalAttrs = sanitizeValues(universalAttributes.current);
    universalAttrs = normalizeBooleans(universalAttrs);
    const currentUser = window.mParticle?.Identity?.getCurrentUser?.();
    const params = location.getSearchParams();
    const utmAttrs = getUtmAttributes(params);
    const session = window.mParticle.sessionManager.getSession();
    const mParticleSessionStorageKey = SessionStorageKeys.MPARTICLE_SESSION;
    const mParticleSessionStorageValue = SessionStorage.getItem(mParticleSessionStorageKey);

    if (session !== mParticleSessionStorageValue && Object.keys(utmAttrs).length === 0) {
      // logged out user has null session before login
      if (mParticleSessionStorageValue !== null) {
        deleteUtmAttributes();
      }
      SessionStorage.setItem(mParticleSessionStorageKey, session);
    }

    if (Object.keys(utmAttrs).length > 0) {
      SessionStorage.setItem(mParticleSessionStorageKey, session);
      setUserUtmAttributes(currentUser, params);
    }

    let customerId = '';
    const user = window.mParticle.Identity.getCurrentUser();
    const loggedInUser = !!LocalStorage.getItem(Keys.USER);
    if (user) {
      const userIdentity = user.getUserIdentities();
      if (userIdentity && Object.keys(userIdentity.userIdentities).length > 0) {
        customerId = userIdentity.userIdentities.customerid;
      }
    }

    const globalAttributes: IGlobalAttributes = {
      currentScreen: getSourcePage(pathname),
      deviceTime: deviceTime(),
      serviceMode: universalAttrs['Service Mode'],
      pickupMode: universalAttrs['Pickup Mode'],
      appBuild: universalAttrs.currentBuild,
      browserType: universalAttrs.browserType,
      browserVersion: universalAttrs.browserVersion,
      isMobileWeb: universalAttrs.isMobileWeb,
    };

    MParticleAdapter.logEvent(
      event.name,
      event.type,
      {
        ...universalAttrs,
        ...globalAttributes,
        ...event.globalAttributes,
        ...event.attributes,
        'Customer ID': customerId,
        'Device Time': deviceTime(),
        'Has User ID': loggedInUser,
        Locale: locale,
        Platform: determinePlatformFromNavigator(),
        RBIEnv: env(),
      },
      event.customFlags
    );

    dataDogLogger({
      message: 'mParticle event',
      status: StatusType.info,
      context: {
        event_type: EventTypes[event.type],
        from_mparticle: 1,
        event_name: event.name,
        event_attributes: getAllowedAttributes(event),
      },
    });
  });

  const logEvent = useCallback(
    enqueueIfNotDrained(
      (eventName: CustomEventNames, eventType: EventTypes, attrs = {}, customFlags = {}) => {
        if (!window?.mParticle) {
          return;
        }

        let customerId = '';
        const user = window.mParticle.Identity.getCurrentUser();
        const loggedInUser = !!LocalStorage.getItem(Keys.USER);
        if (user) {
          const userIdentity = user.getUserIdentities();
          if (userIdentity && Object.keys(userIdentity.userIdentities).length > 0) {
            customerId = userIdentity.userIdentities.customerid;
          }
        }

        const logEventWithAttributes = (eventAttrs = {}) => {
          const attributes = {
            ...universalAttributes.current,
            ...eventAttrs,
            'Customer ID': customerId,
            'Device Time': deviceTime(),
            'Has User ID': loggedInUser,
            Locale: locale,
            Platform: determinePlatformFromNavigator(),
            RBIEnv: env(),
          };

          const sanitizedAttributes = sanitizeValues(attributes);
          const normalizedAndSanitizedAttrs = normalizeBooleans(sanitizedAttributes);
          const normalizedFlags = normalizeBooleans(customFlags);

          const allKeys = Object.keys(normalizedAndSanitizedAttrs);
          let attributeBatches = [normalizedAndSanitizedAttrs];
          if (allKeys.length > LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT) {
            // Break the keys/values into chunks e.g.
            // [{'key': 'value'}], [{'key_2': 'value'}];
            const arrayFromObject = Object.entries(normalizedAndSanitizedAttrs).map(
              ([key, value]) => ({ [key]: value })
            );
            const arrayOfObjectArrays = chunk(
              arrayFromObject,
              LOG_EVENT_CUSTOM_ATTRIBUTES_MAX_COUNT
            );
            attributeBatches = arrayOfObjectArrays.map(arr =>
              // reduce the arrays to an object e.g.
              // [{'key': 'value', 'key_2': 'value'}];
              arr.reduce((acc, cur) => ({ ...acc, ...cur }), {})
            );
          }

          // LogEvents for each of the attributes
          attributeBatches.forEach(batch => {
            MParticleAdapter.logEvent(eventName, eventType, batch, normalizedFlags);
          });
        };
        logEventWithAttributes({ ...attrs });
      }
    ),
    []
  );

  // logs FLAGS_EVALUATED event
  useEffect(() => {
    // logs event if LD flags are updated
    LaunchDarklyHelper.getInstance()
      .evaluateFlagVariants()
      .then(ldFlags => {
        const hasNotYetLoggedFlags = !logFlagsEvaluatedEvent.current;
        const hasFlags = !isEmpty(ldFlags);
        const hasNewFlags = !isEqual(prevFlags.current, ldFlags);
        const cachedUser = LocalStorage.getItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES) || undefined;
        prevFlags.current = ldFlags;

        // Bail out early if are already logged the flags.
        // Bail out if we don't have flags.
        if (hasFlags && (hasNotYetLoggedFlags || hasNewFlags)) {
          logFlagsEvaluatedEvent.current = true;

          // Trigger Flags Evaluated events no earlier than the following conditions met:
          // 1) LaunchDarkly API has been initiated (launchDarklyAttributes != undefined)
          // 2) LaunchDarkly has evaluated the current user (so, user device_id has been written in launchDarklyAttributes)
          if (cachedUser && cachedUser?.custom?.device_id) {
            logEvent(CustomEventNames.FLAGS_EVALUATED, EventTypes.Other, ldFlags);
          }
        }
      })
      .catch(noop);
  }, [rawLdFlags, logEvent]);

  //USER EVENTS
  //https://docs.mparticle.com/developers/sdk/web/idsync/

  const login = enqueueIfNotDrained(
    ({ email, customerid, ...userAttributes } = {}, { callback = noop, tryAgain = true } = {}) => {
      window.mParticle.Identity.login({ userIdentities: { email, customerid } }, result => {
        const user = result.getUser();
        const finalUserAttributes = { language, ...userAttributes };

        if (!user.isLoggedIn() && !isNative) {
          return errorIdentityCallback({
            identityFn: login,
            callback,
            result,
            tryAgain,
            params: {
              email,
              customerid,
              ...finalUserAttributes,
            },
            logger,
          });
        }

        const normalizedAttrs = normalizeBooleans(finalUserAttributes);
        setUserAttributes(user, normalizedAttrs, trackEvent, enablePushNotificationsFlag);

        // fire log sign up flow successful after successful mparticle login
        if (isNewSignUp) {
          const { event, data } = signUpFlowTrackData;
          trackEvent({ name: event, type: EventTypes.Other, attributes: data });
        }

        configureSessionId();
        callback(result);
      });
    }
  );

  const updateUserAttributes = enqueueIfNotDrained(
    (userAttributes = {}, { callback = noop } = {}) => {
      const user = window.mParticle.Identity.getCurrentUser();
      const finalUserAttributes = { language, ...userAttributes };

      const normalizedAttrs = normalizeBooleans(finalUserAttributes);
      setUserAttributes(user, normalizedAttrs, trackEvent, enablePushNotificationsFlag);

      callback();
    }
  );

  const updateUserIdentities: any = enqueueIfNotDrained(
    ({ email, customerid, ccToken } = {}, { callback = noop, tryAgain = true } = {}) => {
      const user = window.mParticle.Identity.getCurrentUser();
      const currentUser = user.getUserIdentities().userIdentities;
      // We should not set customerid to null ever. Will use existing customer id if data
      // being passed in is null
      const newCustomerId = customerid ? customerid : currentUser.customerid;
      const updatedUserIdentities = merge(
        {},
        currentUser,
        createIdentity(email, newCustomerId, ccToken)
      );

      // values are unchanged
      if (isEqual(currentUser, updatedUserIdentities)) {
        return;
      }
      return MParticleAdapter.Identity.modify({ userIdentities: updatedUserIdentities }, result => {
        if (result.httpCode !== 200) {
          errorIdentityCallback({
            identityFn: updateUserIdentities,
            callback,
            result,
            tryAgain,
            params: {
              email,
              customerid,
              ccToken,
            },
          });
        }
      });
    }
  );

  // make call to idenitify with empty userIdentities to retrieve anonymous user by device
  // We do this instead of creating a new one on logout so that we don't overstate Monthly users
  const emptyUserIdentityRequest = { userIdentities: {} };

  const logout = enqueueIfNotDrained(({ callback = noop, tryAgain = true } = {}) => {
    window.mParticle.Identity.identify(emptyUserIdentityRequest, (result: any) => {
      if (result.httpCode !== I.HTTPCode.SUCCESS) {
        return errorIdentityCallback({
          identityFn: logout,
          callback,
          tryAgain,
          result,
        });
      }

      callback();
    });
  });

  const signInEvent = useCallback(
    ({ phase, success, message, otpMethod, method, providerType }: I.ISignInEventOptions) => {
      let event;
      const data = {
        Response: success ? 'Successful' : 'Failure',
        'Response Description': success ? 'Successful' : message,
        Method: method,
        'Social Service': method === I.SignInMethods.SOCIAL ? providerType : undefined,
        Biometrics: undefined, // To be implemented later
      };

      if (isOTPEnabled(otpMethod)) {
        data['LaunchDarkly Flag Value'] = otpMethod;
      }

      event =
        phase === SignInPhases.START
          ? CustomEventNames.SIGN_IN_SUBMITTED
          : success
            ? CustomEventNames.SIGN_IN_COMPLETE
            : CustomEventNames.ERROR;

      trackEvent({ name: event, type: EventTypes.Other, attributes: data });
    },
    [trackEvent]
  );

  const signOutEvent = (success: boolean, message?: string) => {
    setIsNewSignUp(false);
    trackEvent({
      name: CustomEventNames.SIGN_OUT,
      type: EventTypes.Other,
      attributes: {
        Response: success ? 'Successful' : 'Failure',
        'Response Description': success ? 'Successful' : message,
      },
    });
  };

  const signUpEvent = ({
    success,
    message,
    otpMethod,
    additionalAttrs = {},
  }: I.ISignUpEventOptions) => {
    let event = success ? CustomEventNames.SIGN_UP : CustomEventNames.ERROR;
    const data = {
      Name: !success ? CustomEventNames.SIGN_UP_FAILED : undefined,
      Response: success ? 'Successful' : 'Failure',
      'Response Description': success ? 'Successful' : message,
      Type: 'Backend',
      ...additionalAttrs,
    };

    if (isOTPEnabled(otpMethod)) {
      data['LaunchDarkly Flag Value'] = otpMethod;
    }

    if (success) {
      setIsNewSignUp(true);
      setSignUpFlowTrackData({ event, data });
    } else {
      trackEvent({ name: event, type: EventTypes.Other, attributes: data });
    }
  };

  const signUpTermToggleEvent = ({
    postToggleValue,
    signUpTermName,
  }: I.ISignUpTermToggleEventOptions) => {
    let event = CustomEventNames.SIGN_UP_TERM_TOGGLE;

    const data = {
      'Sign Up Term': signUpTermName,
      'Post Toggle Value': postToggleValue,
    };

    trackEvent({ name: event, type: EventTypes.Other, attributes: data });
  };

  const autoSignInEvent = useCallback(
    ({ success, message, phase }: I.IAutoSignInEventOptions) => {
      trackEvent({
        name:
          phase === SignInPhases.COMPLETE
            ? success
              ? CustomEventNames.SIGN_IN_COMPLETE
              : CustomEventNames.ERROR
            : CustomEventNames.SIGN_IN_SUBMITTED,
        type: EventTypes.Other,
        attributes: {
          Response: success ? 'Successful' : 'Failure',
          'Response Description': success ? 'Successful' : message,
          Method: CustomEventNames.AUTO_SIGN_IN,
        },
      });
    },
    [trackEvent]
  );

  const createSublevelItems = (cartEntry: ICartEntry): I.ICdpSublevelItem[] => {
    // Get cart entry sublevel items
    const subItems = flattenCartEntryItems(cartEntry).filter(
      item => item._id !== cartEntry._id && item.type === CartEntryType.item
    );

    // Merge sublevel items by item id
    const mergedItemsById = subItems.reduce<{
      [id: string]: I.ICdpSublevelItem | undefined;
    }>((acc, item) => {
      const curItem = acc[item._id];
      if (curItem) {
        curItem.quantity += item.quantity;
      } else {
        acc[item._id] = { id: item._id, quantity: item.quantity };
      }
      return acc;
    }, {});

    return compact(Object.values(mergedItemsById));
  };

  const createSublevelProducts = (cartEntry: ICartEntry): I.ICdpProduct[] => {
    const subProducts: I.ICdpProduct[] = [];
    // Get cart entry sublevel items
    const subItems = flattenCartEntryItems(cartEntry);

    // Merge sublevel items by item id
    for (const subItem of subItems) {
      if (
        subItem._id !== cartEntry._id &&
        subItem.type === CartEntryType.item &&
        subItem.productHierarchy
      ) {
        const p = createProduct(subItem);
        if (p?.Attributes) {
          p.Attributes.comboChild = booleanToString(true);
          subProducts.push(p);
        }
      }
    }
    return subProducts;
  };

  const createProduct = (cartEntry: ICartEntry | IBackendCartEntries): I.ICdpProduct | null => {
    if (!window?.mParticle?.eCommerce) {
      return null;
    }

    const cartId = 'lineId' in cartEntry ? cartEntry.lineId : cartEntry.cartId;
    const _id = '_id' in cartEntry ? cartEntry._id : cartEntry.sanityId;
    const { name = '', price, quantity, isDonation = false, isExtra = false } = cartEntry;
    const basePrice = price ? centsToDollars(price / quantity) : 0;
    const product = MParticleAdapter.createProduct(name, _id, basePrice, quantity);

    if (!product) {
      return null;
    }

    const productSublevelItems = createSublevelItems(cartEntry as ICartEntry);
    const itemLevel =
      productSublevelItems.length === 0 ? ProductItemType.Child : ProductItemType.Parent;

    product.Attributes = {
      cartId: cartId || _id,
      sublevelItems: JSON.stringify(productSublevelItems),
      isDonation: booleanToString(isDonation),
      isExtra: booleanToString(isExtra),
      'Item Level': itemLevel,
      comboChild: booleanToString(false),
    };

    if (itemLevel === ProductItemType.Child) {
      product.Attributes = {
        ...product.Attributes,
        L1: cartEntry.productHierarchy?.L1 || '',
        L2: cartEntry.productHierarchy?.L2 || '',
        L3: cartEntry.productHierarchy?.L3 || '',
        L4: cartEntry.productHierarchy?.L4 || '',
        L5: cartEntry.productHierarchy?.L5 || '',
      };
    }

    product.SubProducts =
      productSublevelItems.length > 0 ? createSublevelProducts(cartEntry as ICartEntry) : [];
    return product;
  };

  const getCartDataItems = (cartEntries: ICartEntry[]): string => {
    const cartData = JSON.stringify({
      items: cartEntries.map(entry => ({
        items: createProduct(entry),
      })),
    });
    const cartDataItemsRegex = /item_\d{3,}/gi;
    return cartData?.match(cartDataItemsRegex)?.join() || '';
  };

  const addToCart = enqueueIfNotDrained(
    (
      cartEntry: ICartEntry,
      serviceMode: ServiceMode,
      previousCartEntries: ICartEntry[],
      selectionAttrs?: I.IAddToCartSelectionAttributes
    ) => {
      if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
        return;
      }

      const product = createProduct(cartEntry);

      if (!product) {
        return;
      }

      MParticleAdapter.logProductAction(window.mParticle.ProductActionType.AddToCart, [product], {
        'Pickup Mode': serializePickupMode(serviceMode),
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(pathname),
        'Is Update': booleanToString(false),
        'Cart Data': getCartDataItems(previousCartEntries),
        'Picker Aspect Selection': booleanToString(!!selectionAttrs?.pickerAspectSelection),
        'Combo Slot Selection': booleanToString(!!selectionAttrs?.comboSlotSelection),
        'Item Modified': booleanToString(!!selectionAttrs?.itemModified),
      });
    }
  );

  const updateItemInCart = enqueueIfNotDrained(
    (newCartEntry: ICartEntry, originalCartEntry: ICartEntry, serviceMode: ServiceMode) => {
      if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
        return;
      }

      const oldProduct = createProduct(originalCartEntry);
      const newProduct = createProduct(newCartEntry);

      if (!oldProduct || !newProduct) {
        return;
      }

      MParticleAdapter.logProductAction(ProductActionTypes.RemoveFromCart, [oldProduct], {
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(pathname),
        'Is Update': booleanToString(true),
      });

      MParticleAdapter.logProductAction(ProductActionTypes.AddToCart, [newProduct], {
        'Pickup Mode': serializePickupMode(serviceMode),
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(pathname),
        'Is Update': booleanToString(true),
      });
    }
  );

  const removeFromCart = enqueueIfNotDrained((cartEntry: ICartEntry) => {
    if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
      return;
    }

    const product = createProduct(cartEntry);

    if (!product) {
      return;
    }

    MParticleAdapter.logProductAction(ProductActionTypes.RemoveFromCart, [product], {
      'Is Kiosk': booleanToString(false),
      'Device Time': deviceTime(),
      'Source Page': getSourcePage(pathname),
      'Is Update': booleanToString(false),
    });
  });

  const logCustomerInitiatedRefund = (
    event: CustomEventNames,
    items: IBackendCartEntries[] = [],
    amount: number = 0,
    reason: string = ''
  ) => {
    if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
      return;
    }

    const products = items.reduce<I.ICdpProduct[]>((acc, item) => {
      const product = createProduct(item);
      if (product) {
        acc.push(product);
      }
      return acc;
    }, []);

    // Converts refunded items ids to a string to be sent in the custom event below.
    const refundedItemsString = items.map(({ lineId }) => lineId).join(', ');

    trackEvent({
      name: event,
      type: EventTypes.Transaction,
      attributes: {
        amount,
        items: refundedItemsString,
        reason,
      },
    });

    MParticleAdapter.logProductAction(ProductActionTypes.Refund, products, {
      amount,
      event,
      reason,
      'Device Time': deviceTime(),
      ...universalAttributes.current,
    });
  };

  const logPurchase = enqueueIfNotDrained(
    (
      cartEntries: ICartEntry[],
      store: StoreProxy,
      serviceMode: ServiceMode,
      serverOrder: IServerOrder,
      attrs = {}
    ) => {
      if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
        return;
      }

      const appliedOffersCmsIds = (serverOrder.cart.appliedOffers || []).map(
        ({ sanityId }) => sanityId
      );

      // Upsells
      // - hasUpsell
      // - upsellTotal
      const upsells = cartEntries.filter(entry => entry.isUpsell);
      const hasUpsell = !!upsells.length;
      const upsellTotal = centsToDollars(
        upsells.reduce((total, entry) => total + (entry.price || 0), 0)
      );

      // Create Chef payload
      const upsellEntry = upsells.find(entry => !!entry.recommendationToken);
      const recommendationToken = upsellEntry?.recommendationToken;
      const recommender = upsellEntry?.recommender;
      const chef =
        hasUpsell && recommendationToken
          ? {
              eventType: 'purchase-complete',
              userInfo: {
                visitorId:
                  window.mParticle?.Identity?.getCurrentUser()?.getUserIdentities()?.userIdentities
                    .customerid,
              },
              eventDetail: {
                recommendationToken,
              },
              productEventDetail: {
                productDetails: cartEntries.map(entry => ({
                  id: entry._id,
                  quantity: entry.quantity,
                  displayPrice: entry.price,
                  currencyCode: attrs?.currencyCode || 'USD',
                })),
                purchaseTransaction: {
                  id: serverOrder.rbiOrderId,
                  revenue: centsToDollars(serverOrder.cart.subTotalCents),
                  currencyCode: attrs?.currencyCode || 'USD',
                },
              },
            }
          : null;

      const couponIDString = appliedOffersCmsIds.join();
      const serializedServiceMode = serializeServiceMode(serviceMode);

      const rewardAttributes = serverOrder.cart.rewardsApplied?.map(reward => ({
        'Reward ID': reward.rewardId,
        'Reward Quantity': reward.timesApplied,
      }));

      const transactionAttributes = {
        Id: serverOrder.rbiOrderId,
        Revenue: centsToDollars(serverOrder.cart.subTotalCents),
        Tax: centsToDollars(serverOrder.cart.taxCents),
      };

      // Some of these are duplicates from transactionAttributes,
      // but BI wants to have them under specific property names.
      const additionalAttrs: I.ICdpPurchaseEventAttributes = {
        'Pickup Mode': serializePickupMode(serviceMode),
        'Service Mode': serializedServiceMode,
        branch_service_mode: serializedServiceMode,
        customer_event_alias: serializedServiceMode,
        'CC Token': serverOrder?.cart?.payment?.panToken ?? null,
        'Coupon ID': couponIDString,
        'Coupon Applied': booleanToString(!!couponIDString),
        Currency: attrs.currencyCode,
        'Tax Amount': transactionAttributes.Tax,
        'Total Amount': transactionAttributes.Revenue,
        'Transaction Order Number ID': serverOrder?.posOrderId ?? '',
        'Transaction POS': serverOrder?.cart?.posVendor ?? null,
        'Transaction RBI Cloud Order ID': serverOrder?.rbiOrderId ?? null,
        'Timed Fire Minutes': attrs.fireOrderInMinutes,
        'Restaurant ID': store.number,
        'Restaurant Name': store.name,
        'Restaurant Number': store.number,
        'Restaurant Address': store.physicalAddress?.address1 ?? null,
        'Restaurant City': store.physicalAddress?.city ?? null,
        'Restaurant State/Province Name': store.physicalAddress?.stateProvince ?? null,
        'Restaurant Postal Code': store.physicalAddress?.postalCode ?? null,
        'Restaurant Country': store.physicalAddress?.country ?? null,
        'Restaurant Latitude': store.latitude,
        'Restaurant Longitude': store.longitude,
        'Restaurant Status': store.status,
        'Restaurant Drink Station Type': store.drinkStationType,
        'Restaurant Drive Thru Lane Type': store.driveThruLaneType ?? null,
        'Restaurant Franchise Group Id': store.franchiseGroupId,
        'Restaurant Franchise Group Name': store.franchiseGroupName,
        'Restaurant Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Breakfast': store.hasBreakfast,
        'Restaurant Has Burgers For Breakfast': store.hasBurgersForBreakfast,
        'Restaurant Has Curbside': store.hasCurbside,
        'Restaurant Has Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Catering': store.hasCatering,
        'Restaurant Has Dine In': store.hasDineIn,
        'Restaurant Has Drive Thru': store.hasDriveThru,
        'Restaurant Has Table Service': store.hasTableService,
        'Restaurant Has Home Delivery': store.hasDelivery,
        'Restaurant Has Mobile Ordering': store.hasMobileOrdering,
        'Restaurant Has Parking': store.hasParking,
        'Restaurant Has Playground': store.hasPlayground,
        'Restaurant Has Take Out': store.hasTakeOut,
        'Restaurant Has Wifi': store.hasWifi,
        'Restaurant Number Drive Thru Windows': serializeNumberOfDriveThruWindows(
          store.driveThruLaneType
        ),
        'Restaurant Parking Type': store.parkingType,
        'Restaurant Playground Type': store.playgroundType,
        'Restaurant POS': store.pos?.vendor ?? null,
        'Restaurant POS Version': store.pos?.version ?? null,
        'Is Kiosk': false,
        'Is Guest Order': !!serverOrder.cart.guestId,
        'Guest ID': serverOrder.cart.guestId,
        'Card Type': serverOrder.cart.payment?.cardType || '',
        'Payment Type': serializePaymentType(serverOrder.cart.payment?.paymentType),
        'Has Upsell': hasUpsell,
        'Upsell Total': upsellTotal,
        'Recommender Provider': recommender || '',
        Chef: chef ? JSON.stringify(chef) : null,
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(pathname),
        'Cart Data': getCartDataItems(cartEntries),
        Rewards: rewardAttributes ? JSON.stringify(rewardAttributes) : null,
        'Is Loyalty': !!serverOrder.loyaltyTransaction,
        'Currency Code': attrs.currencyCode || 'USD',
        'Upsell Simplified Enabled': isUpsellSimplified,
      };

      // Delivery Fees
      if (serializeServiceMode(serviceMode) === 'Delivery') {
        additionalAttrs.deliveryFeeAmount = centsToDollars(attrs.deliveryFeeCents);
        additionalAttrs.deliveryDiscountAmount = centsToDollars(attrs.deliveryFeeDiscountCents);
        additionalAttrs.deliveryGeographicalFeeAmount = centsToDollars(
          attrs.deliveryGeographicalFeeCents
        );
        additionalAttrs.deliveryServiceFeeAmount = centsToDollars(attrs.deliveryServiceFeeCents);
        additionalAttrs.deliverySmallCartFeeAmount = centsToDollars(
          attrs.deliverySmallCartFeeCents
        );
        additionalAttrs.totalDeliveryOrderFeeAmount = centsToDollars(
          attrs.totalDeliveryOrderFeesCents
        );
        additionalAttrs.deliverySurchargeFeeAmount = centsToDollars(
          attrs.deliverySurchargeFeeCents
        );
        additionalAttrs.quotedFeeAmount = centsToDollars(attrs.quotedFeeCents);
        additionalAttrs.baseDeliveryFeeAmount = centsToDollars(attrs.baseDeliveryFeeCents);
        additionalAttrs['Address Type'] = attrs.addressType;
        additionalAttrs.hasSavedDeliveryAddress = attrs.hasSavedDeliveryAddress;
        additionalAttrs.hasSelectedRecentAddress = attrs.hasSelectedRecentAddress;
        additionalAttrs.hasRecentAddress = attrs.hasRecentAddress;
      }

      if (transactionAttributes.Revenue >= 20) {
        additionalAttrs['Value Threshold 20 Met'] = true;
      }

      if (transactionAttributes.Revenue >= 15) {
        additionalAttrs['Value Threshold 15 Met'] = true;
      }

      if (transactionAttributes.Revenue >= 10) {
        additionalAttrs['Value Threshold 10 Met'] = true;
      }
      if (transactionAttributes.Revenue >= 5) {
        additionalAttrs['Value Threshold 5 Met'] = true;
      }

      const normalizedTransactionAttrs = normalizeBooleans(transactionAttributes);

      const sanitizedAdditionAttrs = sanitizeValues(additionalAttrs);
      const normalizedAdditionalAttrs = normalizeBooleans(sanitizedAdditionAttrs);

      const products = cartEntries.reduce((accumulator, cartEntry) => {
        const eCommerceProduct = createProduct(cartEntry);

        if (!eCommerceProduct) {
          return accumulator;
        }

        const rewardApplied = serverOrder.cart.rewardsApplied?.find(
          reward => reward.cartId === eCommerceProduct.Attributes?.cartId
        );

        accumulator.push({
          ...eCommerceProduct,
          Attributes: {
            ...eCommerceProduct.Attributes,
            rewardItem: booleanToString(!!rewardApplied),
          },
        });

        //now we expand the product list with the SubProducts listed on each product, for ecommerce purchases only
        if (eCommerceProduct.SubProducts && eCommerceProduct.SubProducts.length > 0) {
          accumulator = accumulator.concat(eCommerceProduct.SubProducts);
        }

        return accumulator;
      }, [] as I.ICdpProduct[]);

      try {
        // for docs, refer https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle.eCommerce.html
        MParticleAdapter.logProductAction(
          ProductActionTypes.Purchase,
          products,
          normalizedAdditionalAttrs,
          null,
          normalizedTransactionAttrs
        );
      } catch (error) {
        logger.error({ error, message: 'mParticle > logPurchase error' });
      }

      // log rbi purchase events
      if (serializedServiceMode === 'Pickup' || serializedServiceMode === 'Delivery') {
        const eventName =
          serializedServiceMode === 'Pickup' ? 'Purchase Pick Up' : 'Purchase Delivery';
        trackEvent({
          name: eventName,
          type: EventTypes.Other,
        });
      }
    }
  );

  const logPageView = enqueueIfNotDrained(
    (pathname, attrs = {}, customFlags = {}, force = false) => {
      const allAttributes = {
        ...universalAttributes.current,
        ...attrs,
        'Device Time': deviceTime(),
      };
      const sanitizedAttributes = sanitizeValues(allAttributes);
      const normalizedAndSanitizedAttrs = normalizeBooleans(sanitizedAttributes);
      const normalizedFlags = normalizeBooleans(customFlags);
      if (force) {
        return window.mParticle.logPageView(pathname, normalizedAndSanitizedAttrs, normalizedFlags);
      }
      if (pathname.startsWith(routes.menu)) {
        const onMainMenu = new RegExp(routes.menu.concat('$')).test(pathname);
        if (onMainMenu) {
          return window.mParticle.logPageView(
            pathname,
            normalizedAndSanitizedAttrs,
            normalizedFlags
          );
        }
      }
      // fixes issue with home page not being captured
      if (isHome(pathname)) {
        window.mParticle.logPageView(pathname, normalizedAndSanitizedAttrs, normalizedFlags);
      } else {
        const trackParameters = {
          pathname,
          normalizedAndSanitizedAttrs,
          normalizedFlags,
        };
        // If staticRoutes have not loaded yet
        // Store them for later when they are available
        if (!isLocalRoute(pathname) && !staticRoutes.current.length) {
          return maybeTrackWhenStaticRoutesAvailable(trackParameters);
        }
        maybeTrackPage({ ...trackParameters });
      }
    }
  );

  const logCommercePageView = (
    menuData: { id: string; name: string; menuType: string },
    attrs = {}
  ) => {
    if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
      return;
    }

    const { name, id, menuType } = menuData;
    const product = MParticleAdapter.createProduct(name, id, 0, 1) as I.ICdpProduct;

    MParticleAdapter.logProductAction(
      ProductActionTypes.ViewDetail,
      [product],
      { menuType, ...attrs },
      null,
      {}
    );
  };

  const maybeTrackPage = enqueueIfNotDrained(
    ({ pathname, normalizedAndSanitizedAttrs, normalizedFlags }: ILogPageView) => {
      const trackedPages = TRACKED_PAGES.concat(staticRoutes.current);
      const matchedPages = trackedPages.filter(page => pathname.startsWith(page));
      if (matchedPages.length) {
        // Find the longest match by setting it to be the first element
        const matchedPage = matchedPages.sort((a, b) => b.length - a.length)[0];
        window.mParticle.logPageView(matchedPage, normalizedAndSanitizedAttrs, normalizedFlags);
      }
    }
  );

  const maybeTrackWhenStaticRoutesAvailable = ({
    pathname,
    normalizedAndSanitizedAttrs,
    normalizedFlags,
  }: ILogPageView) => {
    logPageViewParameters.current = {
      pathname,
      normalizedAndSanitizedAttrs,
      normalizedFlags,
    };
  };

  const logNavigationClick = (eventName: CustomEventNames, attrs = {}, customFlags = {}) => {
    trackEvent({
      name: CustomEventNames.BUTTON_CLICK,
      type: EventTypes.Navigation,
      attributes: {
        Name: eventName,
        ...attrs,
      },
      customFlags,
    });
  };

  const logAddPaymentMethodClick = () => {
    logNavigationClick(CustomEventNames.BUTTON_CLICK_ADD_PAYMENT_METHOD);
  };

  const logCashVoucherMethodClick = () => {
    logNavigationClick(CustomEventNames.BUTTON_CLICK_CASH_OR_VOUCHER);
  };

  const selectServiceMode = enqueueIfNotDrained(() => {
    trackEvent({
      name: 'Select Service Mode',
      type: EventTypes.Other,
      attributes: null,
    });
  });

  const logOfferActivatedEvent = (sanityId: string, offerName: string, tokenId?: string | null) => {
    trackEvent({
      name: CustomEventNames.OFFER_ACTIVATED,
      type: EventTypes.Other,
      attributes: {
        'Sanity ID': sanityId,
        'Offer Name': offerName,
        external_offer_id: tokenId,
      },
    });
  };

  /**
   * Logs an event to mparticle with the duration in MS an order has taken
   * to transition from either PRICE_REQUESTED or INSERT_REQUESTED to
   * *_ERROR/SUCCESSFUL status
   */
  const logOrderLatencyEvent = enqueueIfNotDrained(
    (order: IServerOrder | undefined, actionType: 'commit' | 'price', duration: number) => {
      const orderStatus = order?.status;
      const storeId = order?.cart?.storeDetails?.storeNumber;
      const orderId = order?.rbiOrderId;
      const serviceMode = order?.cart?.serviceMode ?? null;

      const eventName =
        actionType === 'price'
          ? CustomEventNames.ORDER_LATENCY_PRICING
          : CustomEventNames.ORDER_LATENCY_COMMIT;

      const context = {
        Duration: Math.floor(duration),
        Status: orderStatus,
        'Store ID': storeId,
        'Order ID': orderId,
        'Service Mode': serializeServiceMode(serviceMode),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(pathname),
        Locale: locale,
        Platform: determinePlatformFromNavigator(),
        RBIEnv: env(),
      };

      MParticleAdapter.logEvent(eventName, EventTypes.Other, context);
      dataDogLogger({
        message: eventName,
        context,
        status: StatusType.info,
      });
    }
  );

  const logUpsellAddedEvent = enqueueIfNotDrained((item: ICartEntry, itemPosition?: number) => {
    if (!item.isUpsell) {
      return;
    }
    const { name, price } = item;

    trackEvent({
      name: 'Upsell Added',
      type: EventTypes.Other,
      attributes: {
        name,
        sanityId: item._id,
        price: price && price / 100,
        upsellItemPosition: itemPosition,
      },
    });
  });

  const logUpsellRemovedEvent = enqueueIfNotDrained((item: ICartEntry) => {
    if (!item.isUpsell) {
      return;
    }
    const { recommender, recommendationToken, price, name } = item;
    MParticleAdapter.logEvent(CustomEventNames.UPSELL_REMOVED, EventTypes.Other, {
      Id: item._id,
      Name: name,
      Price: price && price / 100,
      Recommender: recommender,
      RecommendationToken: recommendationToken,
      'Source Page': getSourcePage(pathname),
      Locale: locale,
      Platform: determinePlatformFromNavigator(),
      RBIEnv: env(),
    });
  });

  const logCheckoutEvent = (serviceMode: ServiceMode, cartEntries: ICartEntry[]) => {
    if (!window?.mParticle?.eCommerce || !window?.mParticle?.ProductActionType) {
      return;
    }

    const products = cartEntries.map(createProduct).filter(Boolean as any as ExcludesNull);
    const pickUpMode = serializePickupMode(serviceMode);
    const customAttributes = {
      'Pickup Mode': pickUpMode,
      'Cart Data': getCartDataItems(cartEntries),
    };

    MParticleAdapter.logProductAction(ProductActionTypes.Checkout, products, customAttributes);
  };

  const marketingTileClickEvent = (title: string, position: number, cardId: string) => {
    trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.MARKETING_TILE,
        text: title,
        position: `Tile ${position + ONE_INDEX_OFFSET}`,
        componentId: cardId,
      },
    });
  };

  const logNavBarClickEvent = (text: string, componentKey: string) => {
    trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Navigation,
      attributes: {
        component: ClickEventComponentNames.NAV_BAR,
        text,
        componentId: componentKey,
      },
    });
  };

  const providerValues = useMemo(
    () => ({
      // auth events
      init,
      login,
      logout,
      signInEvent,
      deviceId,
      signOutEvent,
      signUpEvent,
      signUpTermToggleEvent,
      autoSignInEvent,
      updateStaticRoutes,
      updateUserAttributes,
      updateUserIdentities,
      updateUserLocationPermissionStatus,

      // pageView data
      logPageView,
      logCommercePageView,

      // eCommerce events
      addToCart,
      updateItemInCart,
      removeFromCart,
      logOrderLatencyEvent,
      logPurchase,
      logCustomerInitiatedRefund,

      // error tracking
      logError,
      logValidationError,

      // custom events
      logEvent,
      trackEvent,
      logNavigationClick,
      logAddPaymentMethodClick,
      logCashVoucherMethodClick,
      selectServiceMode,
      logOfferActivatedEvent,
      logCheckoutEvent,
      logUpsellAddedEvent,
      logUpsellRemovedEvent,
      logNavBarClickEvent,
      marketingTileClickEvent,

      // initialized sessionId from mParticle
      sessionId: sessionId || uniqueGuid.current,

      // Allows for universal attrs
      updateUniversalAttributes,
    }),
    [
      addToCart,
      autoSignInEvent,
      deviceId,
      init,
      logAddPaymentMethodClick,
      logCashVoucherMethodClick,
      logCheckoutEvent,
      logCustomerInitiatedRefund,
      logError,
      logValidationError,
      logEvent,
      logNavBarClickEvent,
      logNavigationClick,
      logOfferActivatedEvent,
      logOrderLatencyEvent,
      logPageView,
      logPurchase,
      logUpsellAddedEvent,
      logUpsellRemovedEvent,
      login,
      logout,
      marketingTileClickEvent,
      removeFromCart,
      selectServiceMode,
      sessionId,
      signInEvent,
      signOutEvent,
      signUpEvent,
      signUpTermToggleEvent,
      trackEvent,
      updateItemInCart,
      updateStaticRoutes,
      updateUniversalAttributes,
      updateUserAttributes,
      updateUserIdentities,
    ]
  );

  return (
    <MParticleContext.Provider value={providerValues}>{props.children}</MParticleContext.Provider>
  );
}

export default MParticleContext.Consumer;
