import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { GraphQLError } from 'graphql';

export const BLOCKED_USER_ERROR_CODE = 'BLOCKED_USER_ERROR';

interface SubscriptionObserver {
  closed: boolean;
  next(value: any): void;
  error(errorValue: any): void;
  complete(): void;
}

const isBlockedUserError = (gqlError: GraphQLError): boolean =>
  gqlError?.message === BLOCKED_USER_ERROR_CODE ||
  gqlError?.extensions?.code === BLOCKED_USER_ERROR_CODE;

/**
 * A link responsible for intercepting user blocked errors from the backend
 * and handling them.
 */
export class BlockedUserLink extends ApolloLink {
  private link: ApolloLink;
  private handleBlockedUser: VoidFunction;

  constructor() {
    super();
    this.setBlockedUserHandler(() => {});
  }

  public request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    return this.link.request(operation, forward);
  }

  public setBlockedUserHandler(handler: () => void) {
    this.handleBlockedUser = handler;

    this.link = new ApolloLink((operation, forward) => {
      return new Observable(observer => {
        const sub = forward(operation).subscribe({
          next: this.handleNext(observer),
          error: this.handleError(observer),
          complete: () => observer.complete(),
        });

        return () => sub.unsubscribe();
      });
    });
  }

  private handleNext(observer: SubscriptionObserver) {
    return (result: FetchResult) => {
      const { errors = [] } = result;

      if (this.containsBlockedUserError(errors)) {
        this.handleBlockedUserError(observer);
        return;
      }

      observer.next(result);
    };
  }

  private handleError(observer: SubscriptionObserver) {
    return (networkError: Error | ServerError | ServerParseError) => {
      if (
        'result' in networkError &&
        typeof networkError.result === 'object' &&
        this.containsBlockedUserError(networkError?.result?.errors ?? [])
      ) {
        this.handleBlockedUserError(observer);
        return;
      }

      observer.error(networkError);
    };
  }

  private containsBlockedUserError(gqlErrors: readonly GraphQLError[]): boolean {
    return gqlErrors.some(isBlockedUserError);
  }

  private handleBlockedUserError(observer: SubscriptionObserver) {
    this.handleBlockedUser();
    observer.next({ data: null });
    observer.complete();
  }
}

export const blockedUserLink = new BlockedUserLink();
