import React, { RefObject, useRef, useState } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { useReversedUIContext } from 'state/reversed-ui';

import ListOfLinks, { ArrayNode, IListProps, SingleNode } from './list-of-links';
import NavMenuItem from './nav-menu-item';
import { DropdownContainer, Grad } from './styled';
import theme from './theme';

interface INavItemWithClickOutsideListenerProps {
  id: string;
  visible: boolean;
  onDismiss: VoidFunction;
  buttonRef: RefObject<HTMLButtonElement>;
  insetInlineStart: number;
}

const NavItemWithClickOutsideListener = (
  props: INavItemWithClickOutsideListenerProps & IListProps
) => {
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, props.onDismiss, props.buttonRef);

  return (
    <DropdownContainer
      id={props.id}
      $visible={props.visible}
      aria-expanded={props.visible}
      $insetInlineStart={props.insetInlineStart}
      ref={dropdownRef}
    >
      <div>
        {theme.showGradient && <Grad />}
        <ListOfLinks {...props} />
        {theme.showGradient && <Grad />}
      </div>
    </DropdownContainer>
  );
};

export const Nav = (props: { title: string; entries?: [SingleNode | ArrayNode] }) => {
  const [visible, setVisible] = useState(false);
  const [insetInlineStart, setInsetInlineStart] = useState(0);
  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const revealDropdown = () => {
    setVisible(v => !v);
    if (buttonRef.current) {
      const node = buttonRef.current.getBoundingClientRect();
      setInsetInlineStart((node.right + node.left) / 2);
    }
  };

  return (
    <>
      <NavMenuItem
        data-testid="about-menu-entries"
        onClick={revealDropdown}
        className="ignore-react-onclickoutside"
        aria-expanded={visible}
        aria-controls="aboutMenuEntries"
        ref={buttonRef}
        reversed={reversedUI}
      >
        {props.title}
        <VisuallyHidden>
          {formatMessage({
            id: 'toggleMenu',
          })}
        </VisuallyHidden>
      </NavMenuItem>
      {createPortal(
        <NavItemWithClickOutsideListener
          id="aboutMenuEntries"
          insetInlineStart={insetInlineStart}
          visible={visible}
          onDismiss={() => {
            setVisible(false);
          }}
          buttonRef={buttonRef}
          navigate={navigate}
          entries={props.entries as [SingleNode | ArrayNode]}
          {...props}
        />,
        document.body
      )}
    </>
  );
};
