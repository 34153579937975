import React from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';
import { PointsDisplay } from 'ui-system/points-display';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';
import useMediaQuery from 'hooks/use-media-query';
import { useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useReversedUIContext } from 'state/reversed-ui';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { StyledLoyaltyButton } from './loyalty-points-button.styled';

const LoyaltyPointsButton = () => {
  const enableLoyaltyPointsInHeader = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_POINTS_IN_HEADER);
  const { navigate } = useLocationContext();
  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();
  const isMobile = useMediaQuery('mobile');

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const showLoyaltyCta =
    enableLoyaltyPointsInHeader && !loyaltyLoading && loyaltyEnabled && loyaltyUser;
  const { featureLoyaltyUi } = useFeatureLoyaltyUi();

  const handleClick = () => {
    navigate(
      featureLoyaltyUi?.loyaltyCTAButtons?.loyaltyBannerHomepageAuthenticatedButton
        ?.loyaltyBannerHomepageAuthenticatedLink?.locale ||
        formatMessage({ id: 'routes.loyaltyDashboard' })
    );
  };

  return showLoyaltyCta ? (
    <Box margin={isMobile ? '0' : '0.75rem'} minWidth="4rem">
      <StyledLoyaltyButton
        variant={ActionButtonVariants.OUTLINE}
        size={ActionButtonSizes.SMALL}
        data-testid="app-header-loyalty-cta"
        onClick={handleClick}
        reversed={reversedUI}
      >
        <PointsDisplay value={loyaltyUser?.points} />
      </StyledLoyaltyButton>
    </Box>
  ) : null;
};
export default LoyaltyPointsButton;
