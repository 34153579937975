import styled from 'styled-components';

import { BackArrowHistory } from 'components/navigation/back-arrow';

export const Wrapper = styled.div<{ hasNavigationLink: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-start: 0.125rem;
  width: ${p => (p.hasNavigationLink ? '1rem' : 0)};
`;

export const StyledBackArrowHistory = styled(BackArrowHistory)`
  position: unset;
  margin: 0;
`;
