import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Icon } from '@rbilabs/components-library';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';

import { ISanityVendorConfigs } from '@rbi-ctg/menu';
import ConfirmDialog from 'components/confirm-dialog';
import Modal, { ModalSize } from 'components/modal';
import { IRedemptionModalProps } from 'components/offer-redemption-modal/types';
import { OfferDisclaimer } from 'components/offers/ui-refresh/offer-disclaimer';
import Picture from 'components/picture';
import { useAndroidBackButton } from 'hooks/use-android-back-button';
import useMediaQuery from 'hooks/use-media-query';
import { useRedeemInRestaurantConfig } from 'hooks/use-redeem-in-restaurant-config';
import { GetBarcodeOptions } from 'pages/loyalty/loyalty-widgets/loyalty-qr-and-short-code-widget/utils';
import { useAuthContext } from 'state/auth';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LoyaltyOffer } from 'state/loyalty/types';
import {
  PerformancePages,
  initializePagePerformance,
  pageLoaded,
  performanceReport,
  trackComponent,
} from 'utils/render-performance';
import SessionStorage, { SessionStorageKeys } from 'utils/session-storage';

import QRCode from '../qrcode';

import {
  CONFIRM_MODAL_TEST_ID,
  OBJECT_FIT_CONTAIN,
  QR_CODE_SELECTOR,
  QR_CODE_TEST_ID,
  REDEMPTION_TITLE_TEST_ID,
  SHORT_CODE_SELECTOR,
  SHORT_CODE_TEST_ID,
} from './constants';
import {
  BackButton,
  BarCode,
  BodyContent,
  Code,
  Content,
  CouponTitle,
  Description,
  DescriptionWrapper,
  DetailsContainer,
  Directions,
  Footer,
  Header,
  ItemName,
  ItemPicture,
  ModalConfirmStyle,
  ModalHeading,
  ModalHeadingTitle,
  ModalInner,
  Name,
  OfferBadge,
  PictureHeading,
  QRCodeContainer,
  QrCodeHeader,
  RedeemInNext,
  RewardDetails,
  ShortCode,
  ShortCodeWrapper,
  StaticContainer,
  StaticRedeem,
  StyledCountdown,
  Title,
  UseCodeAtCashier,
} from './styled';

/**
 * Get Toshiba Loyalty PLU for QR code usage.
 * @param vendorConfig vendor configuration object.
 * @returns string representing Toshiba Loyalty PLU if present; empty string otherwise.
 */
function getPlu(vendorConfig: ISanityVendorConfigs): string {
  const pluType = vendorConfig?.toshibaLoyalty?.pluType;
  if (!pluType) {
    return '';
  }
  return vendorConfig?.toshibaLoyalty?.[pluType] || '';
}

/**
 * Hook to retrieve barcode contents based on various LD flags.
 * @param selectedOffer offer for which barcode contents should be calculated.
 * @returns string to be used as barcode contents.
 */
export function useBarcodeContents(selectedOffer: LoyaltyOffer): string {
  const { user } = useAuthContext();

  const cognitoId = useMemo(() => user?.cognitoId || 0, [user]);

  const enableCognitoIDQrCode = useFlag(LaunchDarklyFlag.ENABLE_COGNITOID_QR_CODE);
  const enablePluOfferQrCode = useFlag(LaunchDarklyFlag.ENABLE_PLU_OFFER_QR_CODE);

  const shortCode = selectedOffer?.shortCode || '';
  const plu = getPlu(selectedOffer.vendorConfigs as ISanityVendorConfigs);

  if (enableCognitoIDQrCode) {
    // If no PLU is available, fall back to using the short code.
    const code = plu || shortCode;
    return `BKCP01_${cognitoId}_${code}`;
  }

  if (enablePluOfferQrCode) {
    return plu;
  }

  return shortCode;
}

const RedemptionModal: FC<IRedemptionModalProps> = ({ selectedOffer, onDismiss = noop }) => {
  // Track page loading performance
  initializePagePerformance(PerformancePages.OFFER_REDEMPTION);

  const isMobile = useMediaQuery('mobile');
  const [closeConfirmationIsOpen, setCloseConfirmationIsOpen] = useState(false);
  const handleModalDismiss = useCallback(() => {
    setCloseConfirmationIsOpen(true);
  }, []);
  const handleDialogDismiss = useCallback(() => {
    setCloseConfirmationIsOpen(false);
  }, []);

  const { formatMessage } = useIntl();
  const barcodeOptions = GetBarcodeOptions({
    margin: 1,
    scale: 8,
    color: {
      dark: '#502314',
      light: '#FFFFFF00',
    },
  });
  window.history.pushState(null, '', document.URL);
  window.addEventListener('popstate', () => {
    handleModalDismiss();
  });
  const enableImprovedSimplifiedRedeemInRestaurantStaticOffers = useFlag(
    LaunchDarklyFlag.ENABLE_IMPROVED_SIMPLIFIED_REDEEM_IN_RESTAURANT_STATIC_OFFERS
  );
  const barcodeContents = useBarcodeContents(selectedOffer);
  useAndroidBackButton(handleModalDismiss);
  const { trackEvent } = useCdpContext();
  const { redeemInRestaurantConfig } = useRedeemInRestaurantConfig();

  const trackCodeEvent = () => {
    trackEvent({
      name: CustomEventNames.LOYALTY_LOAD,
      type: EventTypes.Other,
      attributes: performanceReport(PerformancePages.OFFER_REDEMPTION),
    });
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      // Page content loaded event
      pageLoaded(PerformancePages.OFFER_REDEMPTION);

      trackEvent({
        name: CustomEventNames.LOYALTY_LOAD,
        type: EventTypes.Other,
        attributes: performanceReport(PerformancePages.OFFER_REDEMPTION),
      });
    });
  }, []);

  const renderQrCode = redeemInRestaurantConfig?.showQrCode;

  if (renderQrCode) {
    trackComponent(PerformancePages.OFFER_REDEMPTION, 'QR Code', QR_CODE_SELECTOR, trackCodeEvent);
  }

  trackComponent(
    PerformancePages.OFFER_REDEMPTION,
    'Short Code',
    SHORT_CODE_SELECTOR,
    trackCodeEvent
  );

  let previousRedemptionTime = SessionStorage.getItem(SessionStorageKeys.REDEMPTION_START_TIME);

  // If previous redemption time is longer than 1 hour ago, discard it and start fresh
  if (previousRedemptionTime + 60 * 60 * 1000 < Date.now()) {
    previousRedemptionTime = null;
  }

  const redemptionStartTime = previousRedemptionTime || Date.now();
  const endTime = redemptionStartTime + 60 * 15 * 1000;

  // Save the start time in session storage
  if (!previousRedemptionTime) {
    SessionStorage.setItem(SessionStorageKeys.REDEMPTION_START_TIME, redemptionStartTime);
  }

  return (
    <Modal
      onDismiss={handleModalDismiss}
      eventData={{
        modalAppearanceEventMessage: 'Redeem Offer',
      }}
      allowsDismiss={!enableImprovedSimplifiedRedeemInRestaurantStaticOffers}
      size={isMobile ? ModalSize.REGULAR : ModalSize.FULLSCREEN}
    >
      <ModalInner data-testid="redemption-modal">
        {enableImprovedSimplifiedRedeemInRestaurantStaticOffers ? (
          <>
            <ModalHeading>
              <BackButton onClick={handleModalDismiss}>
                <Icon icon="back" color="icon-header-contrast" width="24px" aria-hidden />
              </BackButton>
              <ModalHeadingTitle>
                {formatMessage({ id: 'redemptionOfferModalTitle' })}
              </ModalHeadingTitle>
            </ModalHeading>
            <BodyContent>
              <StaticContainer>
                <Header>
                  <StaticRedeem>{formatMessage({ id: 'redeemInNext' })}</StaticRedeem>
                  <StyledCountdown className="countdown" endTime={endTime} onFinish={onDismiss} />
                </Header>
                <Content>
                  {renderQrCode && (
                    <>
                      <QrCodeHeader>
                        <Title>{formatMessage({ id: 'redemptionOfferScanBeforePayment' })}</Title>
                      </QrCodeHeader>
                      <BarCode
                        data-testid={QR_CODE_TEST_ID}
                        barcode={barcodeContents}
                        options={barcodeOptions}
                      />
                    </>
                  )}
                </Content>
                <Footer $isOnlyCode={!renderQrCode}>
                  {formatMessage({ id: 'useCodeAtCashier' })}
                  <Code data-testid={SHORT_CODE_TEST_ID} $isOnlyCode={!renderQrCode}>
                    {selectedOffer.shortCode}
                  </Code>
                </Footer>
              </StaticContainer>

              <DetailsContainer>
                <ItemPicture>
                  <Picture
                    image={selectedOffer?.localizedImage?.locale?.app!}
                    alt={selectedOffer?.localizedImage?.locale?.imageDescription! || ''}
                    objectFitContain
                  />
                </ItemPicture>
                <RewardDetails data-testid={REDEMPTION_TITLE_TEST_ID}>
                  <ItemName content={selectedOffer?.name?.localeRaw} />
                  <OfferBadge>{formatMessage({ id: 'offer' })}</OfferBadge>
                </RewardDetails>
              </DetailsContainer>

              <OfferDisclaimer offer={selectedOffer} />
            </BodyContent>
          </>
        ) : (
          <BodyContent>
            {/* TODO: Remove this whole code block and all the styled components after LD flag deprecation */}
            <PictureHeading>
              <Picture
                image={selectedOffer?.localizedImage?.locale?.app!}
                alt={selectedOffer?.localizedImage?.locale?.imageDescription! || ''}
                placeholderAspectRatio={400 / 170}
                objectFitContain={OBJECT_FIT_CONTAIN}
              />
            </PictureHeading>
            <CouponTitle data-testid={REDEMPTION_TITLE_TEST_ID}>
              <Name content={selectedOffer?.name?.localeRaw} />
            </CouponTitle>
            <DescriptionWrapper data-testid="offer-description">
              <Description content={selectedOffer?.description?.localeRaw} />
            </DescriptionWrapper>
            <UseCodeAtCashier>{formatMessage({ id: 'useCodeAtCashier' })}</UseCodeAtCashier>
            <ShortCodeWrapper>
              {renderQrCode && (
                <QRCodeContainer>
                  <QRCode
                    data-testid={QR_CODE_TEST_ID}
                    barcode={barcodeContents}
                    options={barcodeOptions}
                  />
                </QRCodeContainer>
              )}
              <ShortCode
                qrcode={Boolean(renderQrCode)}
                data-testid={SHORT_CODE_TEST_ID}
                aria-describedby="redemption-code-description"
              >
                {selectedOffer.shortCode || ''}
              </ShortCode>
              <VisuallyHidden id="redemption-code-description">
                {formatMessage({ id: 'redemptionCode' })}
              </VisuallyHidden>
            </ShortCodeWrapper>
            <Directions>
              <RedeemInNext>{formatMessage({ id: 'redeemInNext' })}</RedeemInNext>
              <StyledCountdown
                useClockStyle
                endTime={redemptionStartTime + 60 * 15 * 1000}
                onFinish={onDismiss}
              />
            </Directions>
            <OfferDisclaimer offer={selectedOffer} />
          </BodyContent>
        )}
      </ModalInner>

      {closeConfirmationIsOpen && (
        <ConfirmDialog
          heading={formatMessage({ id: 'offerConfirmDialogHeading' })}
          body={formatMessage({ id: 'offerConfirmDialogBody' })}
          cancelLabel={formatMessage({ id: 'cancel' })}
          confirmLabel={formatMessage({ id: 'offerConfirmDialogConfirmLabel' })}
          onDismiss={handleDialogDismiss}
          onCancel={handleDialogDismiss}
          onConfirm={onDismiss}
          modalAppearanceEventMessage="Confirm Close Redemption"
          data-testid={
            enableImprovedSimplifiedRedeemInRestaurantStaticOffers
              ? CONFIRM_MODAL_TEST_ID
              : undefined
          }
        />
      )}
      <ModalConfirmStyle />
    </Modal>
  );
};

export default RedemptionModal;
